import * as Realm from 'realm-web';
import { createContext, useState } from "react";
import { App, Credentials } from "realm-web";

import envConfig from '../env/env.json';

export const app = new Realm.App({ id: envConfig.MONGODB_APP_ID });
export const gApp = new Realm.App({id: envConfig.MONGODB_GENERAL_APP_ID});

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState();

 
  const emailPasswordLogin = async (email, password) => {
    const credentials = Credentials.emailPassword(email, password);
    const authedUser = await app.logIn(credentials);
    setUser(authedUser);
    return authedUser;
  };


 
  const fetchUser = async () => {
    if (!app.currentUser) return false;
    try {
      await app.currentUser.refreshCustomData();

      setUser(app.currentUser);
      return app.currentUser;
    } catch (error) {
      throw error;
    }
  }

  // Function to logout user from our Realm
  const logOutUser = async () => {
    if (!app.currentUser) return false;
    try {
      await app.currentUser.logOut();
      // Setting the user to null once loggedOut.
      setUser(null);
      return true;
    } catch (error) {
      throw error
    }
  }

  return <UserContext.Provider value={{ user, setUser, fetchUser, emailPasswordLogin,logOutUser }}>
    {children}
  </UserContext.Provider>;
}
