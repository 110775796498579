import React, { useEffect, useState, useForm } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { isEmpty } from "lodash"
import { Draggable } from "react-beautiful-dnd"
import { useFormik } from "formik"
import { deleteCoupons, getCoupons } from "common/Services/dbServices"
import { getBrandName, editVehicleBrand } from "common/Services/dbServices"

import * as Yup from "yup"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Input,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  addNewEvent,
  deleteEvent,
  getCategories,
  getEvents,
  updateEvent,
} from "../../store/actions"
import "@fullcalendar/bootstrap/main.css"
import Loader from "components/Common/Loader"


const Calender = props => {
  const { events, categories } = props
  const [modal, setModal] = useState(false)
  const [popModal, setPopModal] = useState(false)
  const [event, setEvent] = useState({})
  const [selectedDay, setSelectedDay] = useState(0)
  const [isEdit, setIsEdit] = useState(false)
  const navigate = useNavigate()
  const [dragActive, setDragActive] = React.useState(false)
  const [data, setData] = useState()
  const inputRef = React.useRef(null)
  const [deleteItemId, setDeleteItemId] = useState()
  const [id, setId] = useState()
  const [editId, setEditId] = useState()
  const [editedBrand, setEditedBrand] = useState()
  const [editValue, setEditValue] = useState()
  const [editValueOrder, setEditValueOrder] = useState(0)
  const [res, setRes] = useState()
  const [isLoading, setLoading] = useState(false)


  useEffect(() => {
    CouponData()
  }, [deleteItemId])

  const handleDrag = function (e) {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true)
    } else if (e.type === "dragleave") {
      setDragActive(false)
    }
  }

  const dispatch = useDispatch()

  // events validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (event && event.title) || "",
      category: (event && event.category) || "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Your Event Name"),
      category: Yup.string().required("Please Select Your Category"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updatedEvent = {
          id: event.id,
          title: values.title,
          classNames: values.category + " text-white",
          start: event.start,
        }
        // update event
        dispatch(updateEvent(updatedEvent))
        validation.resetForm()
      } else {
        const newEvent = {
          id: Math.floor(Math.random() * 100),
          title: values["title"],
          start: selectedDay ? selectedDay.date : new Date(),
          className: values.category + " text-white",
        }
        // save new event
        dispatch(addNewEvent(newEvent))
        validation.resetForm()
      }
      setSelectedDay(null)
      toggle()
      popToggle()
    },
  })

  useEffect(() => {
    const { onGetCategories, onGetEvents } = props
    onGetCategories()
    onGetEvents()
    new Draggable(document.getElementById("external-events"), {
      itemSelector: ".external-event",
    })
  }, [])

  useEffect(() => {
    if (!modal && !isEmpty(event) && !!isEdit) {
      setTimeout(() => {
        setEvent({})
        setIsEdit(false)
      }, 500)
    }
  }, [modal, event])

  /**
   * Handling the modal state
   */
  const toggle = result => {
    setEditId(result._id)
    setEditValue(result.name.en)
    setEditValueOrder(result.order)
    setRes(result.images)
    setModal(!modal)
  }

  const editBrandData = async event => {
    event.preventDefault()
    try {
      const editBrandList = await editVehicleBrand({
        editId,
        editValue,
        editValueOrder,
        res,
      })
      setEditedBrand(editBrandList)
      console.log("address::", editedBrand)
      setModal(!modal)
    } catch (error) {
      alert("Failed to Update")
    }
  }

  const popToggle = result => {
    setId(result)
    setPopModal(!popModal)
  }

  const deleteItem = async () => {
    const deleteDoc = await deleteCoupons({ id })

    setDeleteItemId(deleteDoc)
    setPopModal(!popModal)
  }

  document.title = "Coupon | Sayartak "

  const CouponData = async () => {
    try {
      setLoading(true)
      const response = await getCoupons()

      if (response) {
        setData(response)
      }
    } catch (e) {
      setLoading(false)
      alert('Sorry,Something went wrong')
    }
    setLoading(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Row>
            <Col sm={6}>
              <Breadcrumbs title="Sayartak" breadcrumbItem="Coupon" />
            </Col>
            <Col sm={6}>
              <div className="float-end d-none d-md-block">
                <Link
                  to="/add-coupon"
                  className="btn btn-secondary"
                  style={{ marginTop: 10 }}
                >
                  Add New
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <div id="external-events" className="mt-12">
                        <h4 className="card-title mb-4">List of Coupon</h4>
                        <div className="table-responsive">
                          <table className="table table-hover table-centered table-nowrap mb-0">
                            <thead className="table-light">
                              <tr>
                                {/* <th scope="col">(#) Id</th> */}

                                <th scope="col">Coupon Name</th>
                                <th scope="col">Description</th>
                                <th scope="col">Type</th>
                                <th scope="col">UseOnlyOnce</th>
                                <th scope="col">isActive</th>
                                <th scope="col" colSpan={3}>
                                  Action
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {isLoading ?
                                <tr>
                                  <td colSpan='8'>
                                    <Loader />
                                  </td>
                                </tr>
                                :
                                data?.map((item, index) => {

                                  return (
                                    <tr>
                                      {item.name ? (
                                        <td
                                          onClick={() =>
                                            navigate(`/coupon-details/${item._id}`, {
                                              state: item,
                                            })
                                          }
                                        >
                                          {item.name}
                                        </td>
                                      ) : null}
                                      {item.description ? (
                                        <td
                                          onClick={() =>
                                            navigate(`/coupon-details/${item._id}`, {
                                              state: item,
                                            })
                                          }
                                        >
                                          {item.description}
                                        </td>
                                      ) : null}
                                      {item.type ? (
                                        <td
                                          onClick={() =>
                                            navigate(`/coupon-details/${item._id}`, {
                                              state: item,
                                            })
                                          }
                                        >
                                          {item.type}
                                        </td>
                                      ) : null}
                                      {item ? (
                                        <td
                                          onClick={() =>
                                            navigate(`/coupon-details/${item._id}`, {
                                              state: item,
                                            })
                                          }
                                        >
                                          {item && item?.toggleSwitch ? item?.toggleSwitch?.toString() : 'false'}
                                        </td>
                                      ) : null}
                                      {item ? (
                                        <td
                                          onClick={() =>
                                            navigate(`/coupon-details/${item._id}`, {
                                              state: item,
                                            })
                                          }
                                        >
                                          {item && item?.isActive ? item.isActive.toString() : 'false'}
                                        </td>
                                      ) : null}
                                      <td>
                                        {" "}
                                        <i
                                          onClick={() =>
                                            navigate(`/coupon-details/${item._id}`, {
                                              state: item,
                                            })
                                          }
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          className="fas fa-eye"
                                        ></i>
                                      </td>
                                      <td>
                                        {" "}
                                        <i
                                          onClick={() =>
                                            navigate(`/add-coupon/${item._id}`, {
                                              state: item,
                                            })
                                          }
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          className="fas fa-edit"
                                        ></i>
                                      </td>
                                      <td>
                                        {" "}
                                        <i
                                          onClick={() => popToggle(item._id)}
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          className="fas fa-trash"
                                        ></i>
                                      </td>
                                    </tr>
                                  )
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          <Col>
            <Modal isOpen={modal} toggle={toggle} centered size="lg">
              <ModalHeader className="mt-0" toggle={() => setModal(!modal)}>
                Edit Brand
              </ModalHeader>
              <ModalBody>
                <Form
                  id="form-file-upload"
                  onDragEnter={handleDrag}
                  // style={{ marginTop: 10 }}
                  className="row mt-10 needs-validation"
                >
                  <Row className="mb-3">
                    <label className="col-sm-2 col-form-label">
                      Brand Name :{" "}
                    </label>
                    <div className="col-md-6">
                      <Input
                        name="name"
                        className="form-control form-control-lg mb-2"
                        type="text"
                        placeholder="Brand Name"
                        value={editValue}
                        onChange={e => setEditValue(e.target.value)}
                      />
                    </div>
                  </Row>

                  <Row className="mb-3">
                    <label className="col-sm-2 col-form-label">
                      Order No :
                    </label>
                    <div className="col-md-6">
                      <Input
                        name="order"
                        className="form-control form-control-lg mb-2"
                        type="text"
                        placeholder="Order No"
                        value={editValueOrder}
                        onChange={e => setEditValueOrder(e.target.value)}
                      />
                    </div>
                  </Row>
                  <div className="d-flex flex-wrap gap-2">
                    <Button
                      type="submit"
                      color="primary"
                      onClick={editBrandData}
                    >
                      Submit
                    </Button>{" "}
                    <Button
                      type="reset"
                      color="secondary"
                      onClick={() => setModal(!modal)}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              </ModalBody>
            </Modal>
          </Col>

          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center"></div>
            <Modal isOpen={popModal} toggle={popToggle} size="sm">
              <ModalHeader className="mt-0" toggle={popToggle}>
                Delete
              </ModalHeader>
              <ModalBody>
                <p>Are you sure to delete this item</p>
                <div className="d-flex flex-wrap gap-2">
                  <Button
                    type="submit"
                    color="secondary"
                    onClick={() => setPopModal(!popModal)}
                  >
                    Cancel
                  </Button>{" "}
                  <Button type="reset" color="danger" onClick={deleteItem}>
                    Delete
                  </Button>
                </div>
              </ModalBody>
            </Modal>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}

Calender.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
}

const mapStateToProps = ({ calendar }) => ({
  events: calendar.events,
  categories: calendar.categories,
})

const mapDispatchToProps = dispatch => ({
  onGetEvents: () => dispatch(getEvents()),
  onGetCategories: () => dispatch(getCategories()),
  onAddNewEvent: event => dispatch(addNewEvent(event)),
  onUpdateEvent: event => dispatch(updateEvent(event)),
  onDeleteEvent: event => dispatch(deleteEvent(event)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Calender)
