import React, { useState, useEffect } from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import { connect } from "react-redux";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getTechnician } from "common/Services/dbServices";

const MapsGoogle = (props) => {
  const [technicianData, setTechnicianData] = useState([]);
  const [selectedTechnicianData, setSelectedTechnicianData] = useState({});
  const [selectedTechnicianMarker, setSelectedTechnicianMarker] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredTechnicians, setFilteredTechnicians] = useState([]);

  useEffect(() => {
    techniciansList();
  }, []);

  useEffect(() => {
    // Filter the technicians based on the search query
    const filtered = technicianData.filter((technician) =>
      technician.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredTechnicians(filtered);
  }, [searchQuery, technicianData]);

  const techniciansList = async () => {
    const result = await getTechnician();
    setTechnicianData(result);
  };
  document.title = "Live Tracking | Sayartak"
  return (
    <React.Fragment>
      <div className="page-content">
      <div className="container-fluid">
          <Breadcrumbs
            maintitle="Sayartak"
            title="Live Tracking"
          />
        <Row>
          <Col>
            <Card>
              <CardBody
                style={{
                  height: "70vh",
                }}
              >
                <CardTitle className="h4">Live Tracking</CardTitle>
                {/* Add a search input field */}
                <input
                  type="text"
                  placeholder="Search Technicians"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  style={{
                    padding: "10px",
                    fontSize: "16px",
                    border: "2px solid #ccc",
                    borderRadius: "4px",
                    width: "100%",
                    maxWidth: "300px",
                    marginBottom: "10px", // Adjust the maximum width as needed
                  }}
                />
                <div
                  id="gmaps-overlay"
                  className="gmaps"
                  style={{ position: "relative" }}
                >
                  <Map
                    google={props.google}
                    zoom={3}
                    style={{ width: "100%", height: "100%" }}
                    initialCenter={{
                      lat: 31.963158,
                      lng: 35.930359,
                    }}
                  >
                    {filteredTechnicians.map((marker, index) => (
                      <Marker
                        key={index}
                        position={{
                          lat: marker.geoLocation?.coordinates[1],
                          lng: marker.geoLocation?.coordinates[0],
                        }}
                        name={marker.name}
                        onClick={(props, sMarker, e) => {
                          setSelectedTechnicianData(props);
                          setSelectedTechnicianMarker(sMarker);
                        }}
                      />
                    ))}
                    <InfoWindow
                      visible={true}
                      marker={selectedTechnicianMarker}
                    >
                      <div>
                        <h1 className="text-capitalize fs-6">
                          {selectedTechnicianData.name}
                        </h1>
                      </div>
                    </InfoWindow>
                  </Map>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(null, {})(GoogleApiWrapper({
  apiKey: "AIzaSyDwbHPFXc5sl3om-YQh7y6-6FVzKLsSiSM",
})(MapsGoogle));
