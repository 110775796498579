import React from "react";
import { IKContext, IKUpload, IKImage } from "imagekitio-react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import {
  urlEndpoint,
  authenticationEndpoint,
  publicKey,
} from "components/Common/imageKit"

const ImageSection = ({ progress, image, isHovered, onHoverEnter, onHoverLeave, onImageRemove, onUploadError, onUploadSuccess, onUploadProgress, onUploadStart }) => {
  return (
    <div className="col-md-6" style={{ position: "relative", display: "inline-block" }}>
      {progress ? (
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : image ? (
        <div
          style={{
            width: 35,
            height: 30,
            display: "flex",
            alignItems: "center",
            marginTop: 10,
          }}
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
        >
          <div style={{ position: "relative" }}>
            <IKImage urlEndpoint={urlEndpoint} path={image} width="35px" height="30px" />
            {isHovered && image && (
              <div
                style={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  zIndex: "1",
                  cursor: "pointer",
                }}
                onClick={onImageRemove}
              >
                <AiOutlineCloseCircle
                  size={16}
                  color="red"
                  style={{
                    position: "absolute",
                    top: -10,
                    right: -10,
                    cursor: "pointer",
                  }}
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <IKContext
          publicKey={publicKey}
          urlEndpoint={urlEndpoint}
          authenticationEndpoint={authenticationEndpoint}
        >
          <IKUpload
            onError={onUploadError}
            onSuccess={onUploadSuccess}
            // onStart={() => setIsLoading(true)}
            // onFinish={() => setIsLoading(false)}
            buttonProps={{ children: "+" }}
            onUploadProgress={onUploadProgress}
            onUploadStart={onUploadStart}
          />
        </IKContext>
      )}
    </div>
  );
};

export default ImageSection;
