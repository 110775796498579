import PropTypes from "prop-types"
import React, { useEffect, useCallback, useRef, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link, useLocation } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { getUserRole } from "common/Services/dbServices"
import { app } from "Context/userContext"

const menuItems = [
  {
    icon: "ti-home",
    name: "Dashboard",
    path: `/dashboard`,
    permission: ["Admin", "Editor"],
    children: [
      {
        icon: "",
        name: "Dashboard",
        path: `/dashboard`,
        permission: ["Admin", "Editor"],
      },
    ],
  },
  {
    icon: "ti-image",
    name: "Banner",
    path: `/banner`,
    permission: ["Admin"],
    children: [
      {
        icon: "",
        name: "List of Banners",
        path: `/banner`,
        permission: ["Admin"],
      },
    ],
  },
  {
    icon: "ti-car",
    name: "Cars",
    path: `/#`,
    permission: ["Admin"],
    children: [
      {
        icon: "",
        name: "Brand",
        path: `/brand`,
        permission: ["Admin"],
        children: [
          {
            icon: "",
            name: "List of Brands",
            path: `/brand`,
            permission: ["Admin"],
          },
          {
            icon: "",
            name: "Add new",
            path: `/add-new-brand`,
            permission: ["Admin"],
          },
        ],
      },
      {
        icon: "ti-car",
        name: "Cars",
        path: `/car`,
        permission: ["Admin"],
        children: [
          {
            icon: "",
            name: "List of Cars",
            path: `/car`,
            permission: ["Admin"],
          },
          {
            icon: "",
            name: "Add new",
            path: `/add-new-car`,
            permission: ["Admin"],
          },
        ],
      },
    ],
  },
  {
    icon: "ti-user",
    name: "Customer",
    path: `/#`,
    permission: ["Admin"],
    children: [
      {
        icon: "",
        name: "List of customers",
        path: `/customer`,
        permission: ["Admin"],
      },
    ],
  },
  {
    icon: "ti-timer",
    name: "Slot",
    path: `/slot`,
    permission: ["Admin", "Editor"],
    children: [
      {
        icon: "",
        name: "List of Slots",
        path: `/slot`,
        permission: ["Admin", "Editor"],
      },
      {
        icon: "",
        name: "Add new",
        path: `/add-new-slot`,
        permission: ["Admin", "Editor"],
      },
      {
        icon: "",
        name: "Block Slot",
        path: `/block-slot`,
        permission: ["Admin", "Editor"],
      },
    ],
  },
  {
    icon: "ti-settings",
    name: "Services",
    path: `/#`,
    permission: ["Admin"],
    children: [
      {
        icon: "",
        name: "Service Categories",
        path: `/service-categories`,
        permission: ["Admin"],
        children: [
          {
            icon: "",
            name: "list of Service Categories",
            path: `/service-categories`,
            permission: ["Admin"],
          },
        ],
      },
      {
        icon: "",
        name: "Services",
        path: `/add-new-service`,
        permission: ["Admin"],
        children: [
          {
            icon: "",
            name: "list of Services",
            path: `/service`,
            permission: ["Admin"],
          },
          {
            icon: "",
            name: "Add New",
            path: `/add-new-service`,
            permission: ["Admin"],
          },
        ],
      },
      {
        icon: "",
        name: "Service Brands",
        path: `/add-new-service`,
        permission: ["Admin"],
        children: [
          {
            icon: "",
            name: "list of Service Brands",
            path: `/service-brands`,
            permission: ["Admin"],
          },
          {
            icon: "",
            name: "Add New",
            path: `/add-new-service-brand`,
            permission: ["Admin"],
          },
        ],
      },
      {
        icon: "",
        name: "Service Types",
        path: `/add-new-service`,
        permission: ["Admin"],
        children: [
          {
            icon: "",
            name: "list of Service Types",
            path: `/service-types`,
            permission: ["Admin"],
          },
          {
            icon: "",
            name: "Add New",
            path: `/add-new-service-type`,
            permission: ["Admin"],
          },
        ],
      },
    ],
  },
  {
    icon: "ti-credit-card",
    name: "Bundle Service",
    path: `/#`,
    permission: ["Admin"],
    children: [
      {
        icon: "ti-user",
        name: "List of Bundles",
        path: `/bundleService`,
        permission: ["Admin"],
      },
      {
        icon: "ti-credit-card",
        name: "Add new",
        path: `/add-new-bundle`,
        permission: ["Admin"],
      },
    ],
  },
  {
    icon: "ti-user",
    name: "Technicians",
    path: `/#`,
    permission: ["Admin"],
    children: [
      {
        icon: "ti-user",
        name: "List of Technicians",
        path: `/technicians`,
        permission: ["Admin"],
      },
      {
        icon: "ti-user",
        name: "Add new",
        path: `/add-new`,
        permission: ["Admin"],
      },
    ],
  },
  // {
  //   icon: "ti-user",
  //   name: "User",
  //   path: `/#`,
  //   permission: ["Admin"],
  //   children: [
  //     {
  //       icon: "",
  //       name: "List of users",
  //       path: `/user`,
  //       permission: ["Admin"],
  //     },
  //     {
  //       icon: "",
  //       name: "Add new user",
  //       path: `/add-new-user`,
  //       permission: ["Admin"],
  //     },
  //   ],
  // },
  {
    icon: "ti-user",
    name: "City",
    path: `/#`,
    permission: ["Admin"],
    children: [
      {
        icon: "",
        name: "List of City",
        path: `/city`,
        permission: ["Admin"],
      },
      {
        icon: "",
        name: "Add new city",
        path: `/add-new-city`,
        permission: ["Admin"],
      },
    ],
  },
  {
    icon: "ti-calendar",
    name: "Orders",
    path: `/orders`,
    permission: ["Admin", "Editor"],
    children: [
      {
        icon: "",
        name: "Orders",
        path: `/orders`,
        permission: ["Editor", "Admin"],
      },
    ],
  },
  {
    icon: "ti-pie-chart",
    name: "Reports",
    path: `/#`,
    permission: ["Admin"],
    children: [
      {
        icon: "",
        name: "Daily Sales Report",
        path: `/daily-report`,
        permission: ["Admin"],
      },
      {
        icon: "",
        name: "Monthly Sales Report",
        path: `/monthly-report`,
        permission: ["Admin"],
      },
      {
        icon: "",
        name: "Annual Sales Report",
        path: `/annual-report`,
        permission: ["Admin"],
      },
      {
        icon: "",
        name: "Date-Wise Sales Report",
        path: `/date-wise-report`,
        permission: ["Admin"],
      },
    ],
  },
  {
    icon: "ti-location-pin",
    name: "Live Tracking",
    path: `/live-tracking`,
    permission: ["Admin", "Editor"],
    children: [
      {
        icon: "",
        name: "Live Tracking",
        path: `/live-tracking`,
        permission: ["Admin", "Editor"],
      },
    ],
  },
  {
    icon: "ti-receipt",
    name: "CMS",
    path: `/cms`,
    permission: ["Admin"],
    children: [
      {
        icon: "",
        name: "CMS",
        path: `/cms`,
        permission: ["Admin"],
      },
    ],
  },
  {
    icon: "ti-pencil-alt",
    name: "Options",
    path: `/options`,
    permission: ["Admin"],
    children: [
      {
        icon: "",
        name: "Options",
        path: `/options`,
        permission: ["Admin"],
      },
    ],
  },
  {
    icon: "ti-tag",
    name: "Coupon",
    path: `/coupon`,
    permission: ["Admin"],
    children: [
      {
        icon: "",
        name: "List of Coupons",
        path: `/coupon`,
        permission: ["Admin"],
      },
      {
        icon: "",
        name: "Add new",
        path: `/add-coupon`,
        permission: ["Admin"],
      },
    ],
  },
  {
    icon: "ti-bell",
    name: "PushNotification",
    path: `/coupon`,
    permission: ["Admin"],
    children: [
      {
        icon: "",
        name: "Messages",
        path: `/Push`,
        permission: ["Admin"],
      },
      {
        icon: "",
        name: "New Push",
        path: `/new-pushNotification`,
        permission: ["Admin"],
      },
    ],
  },
]

const SidebarContent = props => {
  const location = useLocation()
  const ref = useRef()
  const path = location.pathname

  const [role, setRole] = useState()

  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag
        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show")
        }

        parent.classList.remove("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove("mm-show")

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove("mm-active") // li
            parent3.childNodes[0].classList.remove("mm-active")

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove("mm-show") // li
                parent5.childNodes[0].classList.remove("mm-active") // a tag
              }
            }
          }
        }
      }
    }
  }

  const activeMenu = useCallback(() => {
    const pathName = location.pathname
    const fullPath = pathName
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      if (fullPath === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [path, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  }, [])

  useEffect(() => {
    new MetisMenu("#side-menu")
    activeMenu()
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    activeMenu()
  }, [activeMenu])

  useEffect(() => {
    getUser()
  }, [])

  const getUser = async () => {
    if (
      app &&
      app.currentUser &&
      app.currentUser.customData &&
      app.currentUser.customData.entityId
    ) {
      let userId = app.currentUser.customData.entityId
      const result = await getUserRole(userId)
      setRole(result?.role?.value)
    }
    return
  }

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Main")} </li>

            {menuItems.map(menuItem => {
              return (
                <div key={menuItem?.name}>
                  {/* {menuItem.permission.indexOf(role) !== -1 ? ( */}
                  <li>
                    <Link to={menuItem.path} className="has-arrow waves-effect">
                      <i className={menuItem.icon}></i>
                      <span>{menuItem?.name}</span>
                    </Link>
                    {menuItem.children ? (
                      <ul className="sub-menu" aria-expanded="false">
                        {menuItem.children.map(subItem => {
                          return (
                            <div key={subItem?.name}>
                              {/* {subItem.permission.indexOf(role) !== -1 ? ( */}
                              <li>
                                <Link
                                  className="has-arrow waves-effect"
                                  to={subItem.path}
                                >
                                  {subItem.name}
                                </Link>
                                {subItem.children ? (
                                  <ul
                                    className="sub-menu"
                                    aria-expanded="false"
                                  >
                                    {subItem.children.map(subSubItem => {
                                      return (
                                        <div key={subSubItem?.name}>
                                          {/* {subSubItem.permission.indexOf(
                                                role
                                              ) !== -1 ? ( */}
                                          <li >
                                            <Link to={subSubItem.path}>
                                              {subSubItem.name}
                                            </Link>
                                          </li>
                                          {/* ) : null} */}
                                        </div>
                                      )
                                    })}
                                  </ul>
                                ) : null}
                              </li>
                              {/* ) : null} */}
                            </div>
                          )
                        })}
                      </ul>
                    ) : null}
                  </li>
                  {/* ) : null} */}
                </div>
              )
            })}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
