import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  Input,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  getSlot,
  getSlots,
  createBlockedDates,
  getBlockedSlots,
  getDeleteBlockedSlot,
} from "common/Services/dbServices"
import { Link, useNavigate } from "react-router-dom"

const FormElements = () => {
  const navigate = useNavigate()
  const [selectSlot, setSelectedSlot] = useState()
  const [slotData, setSlotData] = useState()
  const [blockedSlotData, setBlockedSlotData] = useState()
  const [modal, setModal] = useState(false)
  const [blockedDate, setBlockedDate] = useState(false)
  const [id, setId] = useState()
  const [popModal, setPopModal] = useState(false)
  const [deleteItemId, setDeleteItemId] = useState()
  const [addBlockedSlotData, setBlockedSlot] = useState()
  // console.log("********", slotData)
  useEffect(() => {
    getSlotData()
    getBlockedSlotData()
  }, [])
  useEffect(()=>{ 
     getSlotData()
    getBlockedSlotData()
  },[addBlockedSlotData,deleteItemId])

  const popToggle = result => {
    // console.log("id==", result)
    setId(result)

    setPopModal(!popModal)
  }
  const deleteItem = async () => {
    const response = await getDeleteBlockedSlot({ id })
    // console.log("res d ==", response)

    setDeleteItemId(response)
    setPopModal(!popModal)
  }

  const onSubmit = async e => {
    e.preventDefault()
    try {
      const response = await createBlockedDates({
        selectSlot,
        blockedDate,
      })
      // console.log("err ==", response)
      setBlockedSlot(response)
      alert("Blocked Successfully")
      navigate("/block-slot")
    } catch (error) {
      alert("Failed to Block Slot")
    }
    setModal(false)
  }
  const getSlotData = async () => {
    const response = await getSlots()
    setSlotData(response)
  }

  const getBlockedSlotData = async () => {
    const response = await getBlockedSlots()
    console.log("getblockeddata ==", response)
    let blockedSlots = []

    for (const item of response) {
      let slotName = await getSlot(item.slotId)

      let obj = {
        _id: item._id,
        date: item.date,
        name: slotName[0].name,
      }

      blockedSlots.push(obj)
      // console.log("pushed ==", blockedSlots)
    }
    setBlockedSlotData(blockedSlots)
  }
  // console.log("blocked slot ==", blockedSlotData)

  document.title = "Block Slot | Sayartak"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="Sayartak"
            title="Block Slot"
            breadcrumbItem="BlockSlot Details"
            url="/Slot"
          />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <div className="d-flex flex-wrap gap-2 mb-3">
                    <Button
                      type="submit"
                      color="primary"
                      onClick={() => setModal(true)}
                    >
                      Block Slot
                    </Button>{" "}
                  </div>
                  <div id="external-events" className="mt-12">
                    <div className="table-responsive">
                      <table className="table table-hover table-centered table-nowrap mb-0">
                        <thead className="table-light">
                          <tr>
                            <th scope="col"> Blocked Dates </th>
                            <th scope="col"> Blocked Slots </th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {blockedSlotData?.map(slot => {
                            // console.log("slotid ==", slot)
                            return (
                              <tr>
                                <td>{slot?.date || "Empty"}</td>
                                <td> {slot?.name || "Empty"}</td>
                                <td>
                                  <i
                                    onClick={() => popToggle(slot._id)}
                                    className="fas fa-trash"
                                  ></i>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Col>
            <Modal isOpen={modal} centered size="md">
              <ModalHeader className="mt-0">Block this slot</ModalHeader>
              <ModalBody>
                <Form
                  id="form-file-upload"
                  className="row mt-10 needs-validation"
                >
                  <Row className="mb-3">
                    <label className="col-md-2 col-form-label">
                      Available Slots :
                    </label>
                    <div className="col-md-6">
                      <select
                        className="form-control form-control-lg"
                        value={selectSlot}
                        onChange={e => setSelectedSlot(e.target.value)}
                      >
                        {slotData?.map(slot => {
                          return <option value={slot?._id}>{slot?.name}</option>
                        })}
                      </select>
                    </div>
                  </Row>

                  <Row className="mb-3">
                    <label className="col-sm-2 col-form-label">Date : </label>
                    <div className="col-md-6">
                      <Input
                        className="form-control form-control-lg"
                        type="date"
                        defaultValue="2019-08-19"
                        id="example-date-input"
                        value={blockedDate}
                        onChange={e => setBlockedDate(e.target.value)}
                      />
                    </div>
                  </Row>

                  <div className="d-flex flex-wrap gap-2">
                    <Button type="submit" color="primary" onClick={onSubmit}>
                      Block
                    </Button>{" "}
                    <Button
                      type="reset"
                      color="secondary"
                      onClick={() => setModal(!modal)}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              </ModalBody>
            </Modal>
          </Col>
          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center"></div>
            <Modal isOpen={popModal} toggle={popToggle} size="sm">
              <ModalHeader className="mt-0" toggle={popToggle}>
                Delete
              </ModalHeader>
              <ModalBody>
                <p>Are you sure to delete this item</p>
                <div className="d-flex flex-wrap gap-2">
                  <Button
                    type="submit"
                    color="secondary"
                    onClick={() => setPopModal(!popModal)}
                  >
                    Cancel
                  </Button>{" "}
                  <Button type="reset" color="danger" onClick={deleteItem}>
                    Delete
                  </Button>
                </div>
              </ModalBody>
            </Modal>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormElements
