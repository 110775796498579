import React from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

const DeleteModal = ({ isOpen, toggle, onConfirmDelete }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="sm">
      <ModalHeader className="mt-0" toggle={toggle}>
        Delete
      </ModalHeader>
      <ModalBody>
        <p>Are you sure to delete this item?</p>
        <div className="d-flex flex-wrap gap-2">
          <Button type="submit" color="secondary" onClick={toggle}>
            Cancel
          </Button>{" "}
          <Button type="reset" color="danger" onClick={onConfirmDelete}>
            Delete
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DeleteModal;
