import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { isEmpty } from "lodash"
import { Draggable } from "react-beautiful-dnd"
import { useFormik } from "formik"
import * as Yup from "yup"
import Loader from "components/Common/Loader"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Input,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  addNewEvent,
  deleteEvent,
  getCategories,
  getEvents,
  updateEvent,
} from "../../store/actions"
import "@fullcalendar/bootstrap/main.css"

import {
  getDeletedCity,
  getCityCollection,
  editCity,
} from "common/Services/dbServices"
const City = props => {
  const { events, categories } = props
  const [modal, setModal] = useState(false)
  const [popModal, setPopModal] = useState(false)
  const [modalcategory, setModalcategory] = useState(false)
  const [event, setEvent] = useState({})
  const [selectedDay, setSelectedDay] = useState(0)
  const [isEdit, setIsEdit] = useState(false)
  const navigate = useNavigate()
  const [dragActive, setDragActive] = React.useState(false)
  const inputRef = React.useRef(null)
  const [data, setData] = useState()
  const [id, setId] = useState()
  const [deletedItem, setDeletedItem] = useState()
  const [res, setRes] = useState()
  const [image, setImage] = useState(null)
  const [editedVehicle, setEditedVehicle] = useState()
  const [editId, setEditId] = useState()
  const [isLoading,setLoading] = useState(false)
  const [addCityEn, setCityEn] = useState()
  const [addCityAr, setCityAr] = useState()

  useEffect(() => {
    CityList()
  }, [deletedItem, editedVehicle])

  const handleDrag = function (e) {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true)
    } else if (e.type === "dragleave") {
      setDragActive(false)
    }
  }
 

  const dispatch = useDispatch()

  // events validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (event && event.title) || "",
      category: (event && event.category) || "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Your Event Name"),
      category: Yup.string().required("Please Select Your Category"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updatedEvent = {
          id: event.id,
          title: values.title,
          classNames: values.category + " text-white",
          start: event.start,
        }
        // update event
        dispatch(updateEvent(updatedEvent))
        validation.resetForm()
      } else {
        const newEvent = {
          id: Math.floor(Math.random() * 100),
          title: values["title"],
          start: selectedDay ? selectedDay.date : new Date(),
          className: values.category + " text-white",
        }
        // save new event
        dispatch(addNewEvent(newEvent))
        validation.resetForm()
      }
      setSelectedDay(null)
      toggle()
      popToggle()
    },
  })

  // category validation
  const categoryValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (event && event.title) || "",
      category: (event && event.category) || "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Your Event Name"),
      category: Yup.string().required("Please Enter Your Billing Name"),
    }),
    onSubmit: values => {
      const newEvent = {
        id: Math.floor(Math.random() * 100),
        title: values["title"],
        start: selectedDay ? selectedDay.date : new Date(),
        className: values.event_category
          ? values.event_category + " text-white"
          : "bg-danger text-white",
      }
      // save new event

      dispatch(addNewEvent(newEvent))
      toggleCategory()
    },
  })

  useEffect(() => {
    const { onGetCategories, onGetEvents } = props
    onGetCategories()
    onGetEvents()
    new Draggable(document.getElementById("external-events"), {
      itemSelector: ".external-event",
    })
  }, [])

  useEffect(() => {
    if (!modal && !isEmpty(event) && !!isEdit) {
      setTimeout(() => {
        setEvent({})
        setIsEdit(false)
      }, 500)
    }
  }, [modal, event])

  const toggle = result => {
    setEditId(result._id)
    setCityEn(result?.name?.en)
    setCityAr(result?.name?.ar)
    setModal(!modal)
  }
  const editCityData = async e => {
    e.preventDefault()
    const editVehicleList = await editCity({
      editId,
      addCityEn,
      addCityAr,
    })
    setEditedVehicle(editVehicleList)
    setModal(!modal)
  }
  const popToggle = res => {
    setId(res)
    setPopModal(!popModal)
  }
  const deleteItemData = async () => {
    const getDeleted = await getDeletedCity({ id })
    setDeletedItem(getDeleted)
    setPopModal(!popModal)
  }
  const toggleCategory = () => {
    setModalcategory(!modalcategory)
  }
  
  document.title = "City | Sayartak "

  const CityList = async () => {
    try{
      setLoading(true)
      const response = await getCityCollection()
      if (response) {
        setData(response)
      }
    }catch (e){
      setLoading(false)
      alert('Sorry,Something went wrong')
    }
    setLoading(false)
  }



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Row>
            <Col sm={6}>
              <Breadcrumbs title="Sayartak" breadcrumbItem="City" />
            </Col>
            <Col sm={6}>
              <div className="float-end d-none d-md-block">
                <Link
                  to="/add-new-city"
                  className="btn btn-secondary"
                  style={{ marginTop: 10 }}
                >
                  Add New
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <div id="external-events" className="mt-12">
                        <h4 className="card-title mb-4">List of City</h4>
                        <div className="table-responsive">
                          <table className="table table-hover table-centered table-nowrap mb-0">
                            <thead className="table-light">
                              <tr>
                                {/* <th scope="col">(#) Id</th> */}

                                <th scope="col">City En</th>
                                <th scope="col">City Ar</th>
                                <th scope="col" colSpan={3}>
                                  Action
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {isLoading ? 
                              <tr>
                                <td colSpan='8'>
                                  <Loader />
                                </td>
                              </tr>
                              :
                               data?.map((item, index) => {
                                return (
                                  <tr>
                                    <td
                                      onClick={() =>
                                        navigate("/city-details", {
                                          state: item,
                                        })
                                      }
                                    >
                                      {item.name.en == null
                                        ? "Empty Value"
                                        : item.name.en}{" "}
                                    </td>
                                
                                    <td
                                      onClick={() =>
                                        navigate("/city-details", {
                                          state: item,
                                        })
                                      }
                                    >
                                      {item.name.en == null
                                        ? "Empty Value"
                                        : item.name.ar}{" "}
                                    </td>
                                    <td>
                                      {" "}
                                      <i
                                        onClick={() =>
                                          navigate("/city-details", {
                                            state: item,
                                          })
                                        }
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        className="fas fa-eye"
                                      ></i>
                                    </td>
                                    <td>
                                      {" "}
                                      <i
                                        onClick={() => toggle(item)}
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        className="fas fa-edit"
                                      ></i>
                                    </td>
                                    <td>
                                      {" "}
                                      <i
                                        onClick={() => popToggle(item._id)}
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        className="fas fa-trash"
                                      ></i>
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          <Col>
            <Modal isOpen={modal} toggle={toggle} centered size="lg">
              <ModalHeader className="mt-0" toggle={() => setModal(!modal)}>
                Edit City
              </ModalHeader>
              <ModalBody>
                <Form
                  id="form-file-upload"
                  onDragEnter={handleDrag}
                  className="row mt-10 needs-validation"
                >
                  <Row className="mb-3">
                    <label className="col-sm-2 col-form-label">
                      City En:{" "}
                    </label>
                    <div className="col-md-6">
                      <Input
                        name="name"
                        className="form-control form-control-lg mb-2"
                        type="text"
                        placeholder="City Name"
                        value={addCityEn}
                        onChange={e => setCityEn(e.target.value)}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-sm-2 col-form-label">
                      City Ar:{" "}
                    </label>
                    <div className="col-md-6">
                      <Input
                        name="name"
                        className="form-control form-control-lg mb-2"
                        type="text"
                        placeholder="City Name"
                        value={addCityAr}
                        onChange={e => setCityAr(e.target.value)}
                      />
                    </div>
                  </Row>
                  <div className="d-flex flex-wrap gap-2">
                    <Button
                      type="submit"
                      color="primary"
                      onClick={editCityData}
                    >
                      Submit
                    </Button>{" "}
                    <Button
                      type="reset"
                      color="secondary"
                      onClick={() => setModal(!modal)}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              </ModalBody>
            </Modal>
          </Col>

          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center"></div>
            <Modal isOpen={popModal} toggle={popToggle} size="sm">
              <ModalHeader className="mt-0" toggle={popToggle}>
                Delete
              </ModalHeader>
              <ModalBody>
                <p>Are you sure to delete this item</p>
                <div className="d-flex flex-wrap gap-2">
                  <Button
                    type="submit"
                    color="secondary"
                    onClick={() => {
                      popToggle()
                    }}
                  >
                    Cancel
                  </Button>{" "}
                  <Button type="reset" color="danger" onClick={deleteItemData}>
                    Delete
                  </Button>
                </div>
              </ModalBody>
            </Modal>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}

City.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
}

const mapStateToProps = ({ calendar }) => ({
  events: calendar.events,
  categories: calendar.categories,
})

const mapDispatchToProps = dispatch => ({
  onGetEvents: () => dispatch(getEvents()),
  onGetCategories: () => dispatch(getCategories()),
  onAddNewEvent: event => dispatch(addNewEvent(event)),
  onUpdateEvent: event => dispatch(updateEvent(event)),
  onDeleteEvent: event => dispatch(deleteEvent(event)),
})

export default connect(mapStateToProps, mapDispatchToProps)(City)
