import React, { useState, useEffect } from "react"
import Select from "react-select"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  Form,
  Input,
} from "reactstrap"
import { ObjectId } from "common/Services/dbServices"
import Switch from "react-switch"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { IKUpload, IKContext } from "imagekitio-react"
import { IKImage } from "imagekitio-react"
import { AiOutlineCloseCircle } from "react-icons/ai"
import {
  urlEndpoint,
  authenticationEndpoint,
  publicKey,
} from "../../components/Common/imageKit"

import { useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import { useParams } from "react-router-dom"
import { useService, useServiceCategories } from "common/Hooks/queryCalls"
import { createBundle, editBundle } from "common/Services/dbServices"
import { useOneBundleService } from "common/Hooks/queryCalls"

const FormElements = () => {
  const [res, setRes] = useState()

  const [image, setImage] = useState(null)
  const [isHovered, setIsHovered] = useState(false)

  const navigate = useNavigate()

  const [progress, setProgress] = useState(false)
  const [isServiceActive, setServiceActive] = useState(false)
  const { data: servicesData } = useService()
  const { data: serviceCategories } = useServiceCategories()
  // const [response, setResponse] = useState(null);
  const urlParams = useParams()

  const { data, isLoading, isSuccess } = useOneBundleService({
    id: urlParams?.id,
  })
  //   console.log("useOneBundleService:", data)
  //   console.log("urlParams:", urlParams)
  useEffect(() => {
    if (data) {
      setInputs()
    }
  }, [isSuccess, data])

  const setInputs = () => {
    const res1 = data.getServiceTypes.map(item => ({
      value: item._id,
      label: item.name.en,
    }))
    if (data) {
      setServiceActive(data.active)
      setImage(data?.images?.filePath)
      setRes(data?.images)
      formik.setValues({
        serviceCategoryId: res1[0],
        services: data.getServices.map(item => ({
          value: item._id,
          label: item.name.en,
        })),
        serviceNameEn: data.name.en,
        serviceNameAr: data.name.ar,
        technicalName: data.technicalName,
        servicePrice: data.price,
        serviceDescriptionEn: data.description.en,
        serviceDescriptionAr: data.description.ar,
        serviceSale: data.salePrice,
        bundleCount: data.bundleCount,
      })
    }
  }

  const onError = err => {
    console.log("Error", err)
  }
  const onSuccess = result => {
    if (result) {
      setProgress(false)
    }
    setRes(result)
    setImage(result.filePath)
    // console.log(result)
  }
  const onUploadProgress = progress => {
    setProgress(progress)
  }

  const initialValues = {
    serviceCategoryId: null,
    services: null,
    serviceNameEn: "",
    serviceNameAr: "",
    technicalName: "",
    servicePrice: 0,
    serviceDescriptionEn: null,
    serviceDescriptionAr: null,
    serviceSale: 0,
    bundleCount: 0,
  }

  const onSubmit = async values => {
    // console.log("values:", values)
    if (urlParams.id === undefined) {
      const params = {
        values,
        res,
        isServiceActive,
      }
      // console.log('param ==',)
      try {
        const response = await createBundle(params)
        // console.log("response ==",response)
        // console.log("this", getServiceList)
        alert("Added Successfully")
        navigate("/bundleService")
      } catch (error) {
        console.log("error", error)
        alert("Failed to Add")
      }
    } else {
      try {
        const editParams = {
          editId: ObjectId(urlParams.id),
          res,
          values,
          isServiceActive,
        }
        const response = await editBundle(editParams)
        // console.log("edit bundle result ==", response)
        alert("Update Successfully")
        navigate("/bundleService")
      } catch (error) {
        console.log("error", error)
        alert("Failed to Update")
      }
    }
  }

  const validate = values => {
    // console.log("validate=", values)
    let errors = {}
    if (!values.serviceCategoryId) {
      errors.serviceCategoryId = "Service categoryId is required"
    }
    if (!values.services) {
      errors.services = "Services is required"
    }
    if (!values.serviceNameEn) {
      errors.serviceNameEn = "Service name en is required"
    }
    if (!values.serviceNameAr) {
      errors.serviceNameAr = "Service name ar is required"
    }
    if (!values.serviceDescriptionEn) {
      errors.serviceDescriptionEn = "Service description is required"
    }
    if (!values.serviceDescriptionAr) {
      errors.serviceDescriptionAr = "Service description is required"
    }
    if (!values.bundleCount) {
      errors.bundleCount = "Count is required"
    }

    return errors
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  })

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        ON
      </div>
    )
  }

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        OFF
      </div>
    )
  }
  document.title =
    urlParams.id === undefined
      ? "Add NewBundle | Sayartak"
      : "Edit Bundle | Sayartak"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="Sayartak"
            title="Services"
            breadcrumbItem={
              urlParams.id === undefined ? "Add New Bundle" : "Edit Bundle"
            }
            url="/service"
          />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Form
                    style={{ marginTop: 10 }}
                    className="row g-3 needs-validation"
                    onSubmit={formik.handleSubmit}
                  >
                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">Active</label>
                      <div className="col-md-10">
                        <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          onColor="#0F52BA"
                          onChange={() => {
                            setServiceActive(!isServiceActive)
                          }}
                          checked={isServiceActive}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">
                        Service Category
                      </label>
                      <div className="col-md-10">
                        <Select
                          placeholder="Select Services Category"
                          options={serviceCategories}
                          name="serviceCategoryId"
                          onBlur={formik.handleBlur}
                          onChange={selectedOption =>
                            formik.setFieldValue(
                              "serviceCategoryId",
                              selectedOption
                            )
                          }
                          value={formik.values.serviceCategoryId}
                        />
                        {formik.touched.serviceCategoryId &&
                        formik.errors.serviceCategoryId ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.serviceCategoryId}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">
                        Services
                      </label>
                      <div className="col-md-10">
                        <Select
                          isMulti
                          placeholder="Select Services"
                          options={servicesData}
                          name="services"
                          onBlur={formik.handleBlur}
                          onChange={selectedOption =>
                            formik.setFieldValue("services", selectedOption)
                          }
                          value={formik.values.services}
                        />
                        {formik.touched.services && formik.errors.services ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.services}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Bundle Count
                      </label>
                      <div className="col-md-10">
                        <Input
                          name="bundleCount"
                          className="form-control form-control-lg"
                          type="number"
                          placeholder="Add Count"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.bundleCount}
                        />
                        {formik.touched.bundleCount &&
                        formik.errors.bundleCount ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.bundleCount}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Service Name En
                      </label>
                      <div className="col-md-10">
                        <Input
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Service Name En"
                          name="serviceNameEn"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.serviceNameEn}
                        />
                        {formik.touched.serviceNameEn &&
                        formik.errors.serviceNameEn ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.serviceNameEn}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Service Name Ar
                      </label>
                      <div className="col-md-10">
                        <Input
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Service Name Ar"
                          name="serviceNameAr"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.serviceNameAr}
                        />
                        {formik.touched.serviceNameAr &&
                        formik.errors.serviceNameAr ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.serviceNameAr}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Technical Name
                      </label>
                      <div className="col-md-10">
                        <Input
                          name="technicalName"
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Technical Name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.technicalName}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">Price</label>
                      <div className="col-md-10">
                        <Input
                          className="form-control form-control-lg"
                          type="number"
                          placeholder="Price"
                          name="servicePrice"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.servicePrice}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Description En
                      </label>
                      <div className="col-md-10">
                        <Input
                          name="serviceDescriptionEn"
                          className="form-control form-control-lg"
                          type="textarea"
                          placeholder="Add Description En"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.serviceDescriptionEn}
                        />
                        {formik.touched.serviceDescriptionEn &&
                        formik.errors.serviceDescriptionEn ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.serviceDescriptionEn}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Description Ar
                      </label>
                      <div className="col-md-10">
                        <Input
                          name="serviceDescriptionAr"
                          className="form-control form-control-lg"
                          type="textarea"
                          placeholder="Add Description Ar"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.serviceDescriptionAr}
                        />
                        {formik.touched.serviceDescriptionAr &&
                        formik.errors.serviceDescriptionAr ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.serviceDescriptionAr}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Service Icon
                      </label>
                      <div
                        className="col-md-10"
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        {progress ? (
                          <div className="spinner-border" role="status">
                            <span className="sr-only"></span>
                          </div>
                        ) : image ? (
                          <div
                            style={{
                              width: 35,
                              height: 30,
                              display: "flex",
                              alignItems: "center",
                              marginTop: 10,
                            }}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                          >
                            <div
                              style={{
                                position: "relative",
                              }}
                            >
                              <IKImage
                                urlEndpoint={urlEndpoint}
                                path={image}
                                width="35px"
                                height="30px"
                              />
                              {isHovered && image && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "5px",
                                    right: "5px",
                                    zIndex: "1",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setImage(null)
                                    setRes(null)
                                  }}
                                >
                                  <AiOutlineCloseCircle
                                    size={16}
                                    color="red"
                                    style={{
                                      position: "absolute",
                                      top: -10,
                                      right: -10,
                                      // color: 'red',
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <IKContext
                            publicKey={publicKey}
                            urlEndpoint={urlEndpoint}
                            authenticationEndpoint={authenticationEndpoint}
                          >
                            <IKUpload
                              //    fileName={data.brandOrder}
                              onError={onError}
                              onSuccess={onSuccess}
                              onUploadProgress={onUploadProgress}
                              buttonProps={{ children: "+" }}
                            />
                          </IKContext>
                        )}
                      </div>
                    </Row>

                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        SalePrice
                      </label>
                      <div className="col-md-10">
                        <Input
                          className="form-control form-control-lg"
                          type="number"
                          placeholder="SalePrice"
                          name="serviceSale"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.serviceSale}
                        />
                      </div>
                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary">
                        Submit
                      </Button>{" "}
                      <Button
                        type="reset"
                        color="secondary"
                        onClick={() => navigate("/bundleService")}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormElements
