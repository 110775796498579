import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Button,
  Form,
  Input,
  Alert,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { editCMS } from "common/Services/dbServices"
import { Link, useNavigate } from "react-router-dom"
import { EditorState ,ContentState} from "draft-js"
import { Editor } from "react-draft-wysiwyg"
import { convertToHTML } from "draft-convert"
import { useLocation, useParams } from "react-router-dom"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"


const FormElements = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const data = location.state
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  )
  const [editorStateAr, setEditorStateAr] = useState(() =>
    EditorState.createEmpty()
  )
  const [convertedContent, setConvertedContent] = useState(null)
  const [convertedContentAr, setConvertedContentAr] = useState(null)
  const [nameEn, setNameEn] = useState(data?.name?.en)
  const [nameAr, setNameAr] = useState(data?.name?.ar)
  const [slugEn, setSlugEn] = useState(data?.slug)
  const { editId } = useParams()

  console.log('data ==',data)
  // const stripHTMLTags = (htmlString) => {
  //   const doc = new DOMParser().parseFromString(htmlString, "text/html");
  //   return doc.body.textContent || "";
  // };

  // Fetching data.description and setting it to editor state
  // useEffect(() => {
  //   if (data?.description?.en) {
      // Remove HTML tags from data.description.en
      // const descriptionText = stripHTMLTags(data.description.en);

      // Set the EditorState for "Description En"
  //     setEditorState(EditorState.createWithContent(ContentState.createFromText(data?.description?.en)));
  //   }
  // }, [data?.description.en]);

  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent())
    setConvertedContent(html)
    let htmlAr = convertToHTML(editorStateAr.getCurrentContent())
    setConvertedContentAr(htmlAr)
  }, [editorState, editorStateAr])

  console.log(convertedContent)

  const onSubmit = async e => {
    e.preventDefault()
    try {
      const editCMSList = await editCMS({
        editId,
        nameEn,
        nameAr,
        slugEn,
        convertedContent,
        convertedContentAr,
      })
      console.log("submit::", editCMSList)
      alert("Item Added Successfully")
      navigate("/CMS")
    } catch (error) {
      alert("Failed to Add")
    }
  }

  document.title = "Edit CMS | Sayartak"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col>
              <Breadcrumbs
                maintitle="Sayartak"
                title="CMS"
                breadcrumbItem="Edit CMS"
                url="/CMS"
              />
            </Col>
            <Col sm={6}>
              <div className="float-end d-none d-md-block">
                <Button
                  color="primary"
                  className="btn btn-secondary"
                  style={{ marginTop: 10 }}
                  onClick={onSubmit}
                >
                  Update
                </Button>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardBody>
                  {/* <CardTitle className="h4">Details</CardTitle> */}
                  <Form
                    style={{ marginTop: 10 }}
                    className="row g-3 needs-validation"
                  >
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">Name En</label>
                      <div className="col-md-6">
                        <Input
                          name="nameen"
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Name En"
                          value={nameEn}
                          onChange={e => setNameEn(e.target.value)}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">Slug En</label>
                      <div className="col-md-6">
                        <Input
                          name="slug"
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Slug"
                          value={slugEn}
                          onChange={e => setSlugEn(e.target.value)}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Description En
                      </label>
                      <div className="col-md-6">
                        <Editor
                          editorState={editorState}
                          onEditorStateChange={setEditorState}
                          wrapperClassName="wrapper-class"
                          editorClassName="editor-class"
                          toolbarClassName="toolbar-class"
                        />
                      </div>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card>
                <CardBody>
                  {/* <CardTitle className="h4">Details</CardTitle> */}
                  <Form
                    style={{ marginTop: 10 }}
                    className="row g-3 needs-validation"
                  >
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">Name Ar</label>
                      <div className="col-md-6">
                        <Input
                          name="namear"
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Name Ar"
                          value={nameAr}
                          onChange={e => setNameAr(e.target.value)}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Description Ar
                      </label>
                      <div className="col-md-6">
                        <Editor
                          editorState={editorStateAr}
                          onEditorStateChange={setEditorStateAr}
                          wrapperClassName="wrapper-class"
                          editorClassName="editor-class"
                          toolbarClassName="toolbar-class"
                        />
                      </div>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormElements
