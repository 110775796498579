import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  addNewEvent,
  deleteEvent,
  getCategories,
  getEvents,
  updateEvent,
} from "../../store/actions"
// import DeleteModal from "./DeleteModal";
//css
import "@fullcalendar/bootstrap/main.css"

import {
  getSalesByModelReport
} from "common/Services/dbServices"

const ModelReport = props => {
  const [model, setModel] = useState()


  useEffect(() => {
    salesByModelReport()
  }, [])

  const salesByModelReport = async () => {

    const result = await getSalesByModelReport()
    console.log("dailySalesReport =", result)
    if (result) {
      setModel(result);
    }
  }

  document.title = "Sales By Model | Sayartak "
  return (
    <React.Fragment>
      {/* <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      /> */}
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Sayartak" breadcrumbItem="Sales By Model" />
          <Row>
            <Col className="col-12">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <div id="external-events" className="mt-12">
                        <h4 className="card-title mb-4">
                        Sales By Model
                        </h4>
                        <div className="table-responsive">
                          <table className="table table-hover table-centered table-nowrap mb-0">
                            <thead className="table-light">
                              <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Model Vehicle</th>
                                <th scope="col">No. Of Services</th>
                                <th scope="col">Total Revenue</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                {/* <th scope="row">#14256</th> */}
                                <td>15/1/2022</td>
                                <td>MG Hector Diesel</td>
                                <td>48</td>
                                <td>JOD 11250</td>
                              </tr>
                              <tr>
                                <td>15/1/2022</td>
                                <td>Toyota Camry Hybrid</td>
                                <td>49</td>
                                <td>JOD 12550</td>
                              </tr>
                              <tr>
                                <td>15/1/2022</td>
                                <td>Toyota Corolla Diesel</td>
                                <td>122</td>
                                <td>JOD 22150</td>
                              </tr>
                              <tr>
                                <td>15/1/2022</td>
                                <td>VW Vento Petrol</td>
                                <td>81</td>
                                <td>JOD 21250</td>
                              </tr>
                              <tr>
                                <td>15/1/2022</td>
                                <td>Honda City Diesel</td>
                                <td>31</td>
                                <td>JOD 10250</td>
                              </tr>
                              <tr>
                                <td>15/1/2022</td>
                                <td>Toyota LandCruiser Diesel</td>
                                <td>114</td>
                                <td>JOD 20500</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ModelReport.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
}

const mapStateToProps = ({ calendar }) => ({
  events: calendar.events,
  categories: calendar.categories,
})

const mapDispatchToProps = dispatch => ({
  onGetEvents: () => dispatch(getEvents()),
  onGetCategories: () => dispatch(getCategories()),
  onAddNewEvent: event => dispatch(addNewEvent(event)),
  onUpdateEvent: event => dispatch(updateEvent(event)),
  onDeleteEvent: event => dispatch(deleteEvent(event)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ModelReport)
