import React, { useEffect, useState, useForm } from "react"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { isEmpty } from "lodash"
import { Draggable } from "react-beautiful-dnd"
import { useFormik } from "formik"
import * as Yup from "yup"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Input,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { getSlots, editSlot, getSlotDeleted } from "common/Services/dbServices"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  addNewEvent,
  deleteEvent,
  getCategories,
  getEvents,
  updateEvent,
} from "../../store/actions"
import "@fullcalendar/bootstrap/main.css"
import Loader from "components/Common/Loader"
import Select from "react-select"
import { useTechnician } from "common/Hooks/queryCalls"

const Calender = props => {
  const { events, categories } = props

  const { data: technicians } = useTechnician()
  const [modal, setModal] = useState(false)
  const [popModal, setPopModal] = useState(false)
  const [event, setEvent] = useState({})
  const [selectedDay, setSelectedDay] = useState(0)
  const [isEdit, setIsEdit] = useState(false)
  const navigate = useNavigate()
  const [dragActive, setDragActive] = React.useState(false)
  const [data, setData] = useState()
  const inputRef = React.useRef(null)
  const [slotName, setSlotName] = useState()
  const [startTime, setStartTime] = useState()
  const [endTime, setEndTime] = useState()
  const [editId, setEditId] = useState()
  const [editedSlot, setEditedSlot] = useState()
  const [deleteId, setDeleteId] = useState()
  const [deletedSlot, setDeletedSlot] = useState()
  const [isLoading, setLoading] = useState(false)
  const [selectedTechnician, setSelectedTechnician] = useState()

  useEffect(() => {
    SlotList()
  }, [editedSlot, deletedSlot])

  const handleDrag = function (e) {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true)
    } else if (e.type === "dragleave") {
      setDragActive(false)
    }
  }
  const handleDrop = function (e) {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // handleFiles(e.dataTransfer.files);
    }
  }

  const dispatch = useDispatch()

  // events validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (event && event.title) || "",
      category: (event && event.category) || "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Your Event Name"),
      category: Yup.string().required("Please Select Your Category"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updatedEvent = {
          id: event.id,
          title: values.title,
          classNames: values.category + " text-white",
          start: event.start,
        }
        // update event
        dispatch(updateEvent(updatedEvent))
        validation.resetForm()
      } else {
        const newEvent = {
          id: Math.floor(Math.random() * 100),
          title: values["title"],
          start: selectedDay ? selectedDay.date : new Date(),
          className: values.category + " text-white",
        }
        // save new event
        dispatch(addNewEvent(newEvent))
        validation.resetForm()
      }
      setSelectedDay(null)
      toggle()
      popToggle()
    },
  })

  useEffect(() => {
    const { onGetCategories, onGetEvents } = props
    onGetCategories()
    onGetEvents()
    new Draggable(document.getElementById("external-events"), {
      itemSelector: ".external-event",
    })
  }, [])

  useEffect(() => {
    if (!modal && !isEmpty(event) && !!isEdit) {
      setTimeout(() => {
        setEvent({})
        setIsEdit(false)
      }, 500)
    }
  }, [modal, event])

  /**
   * Handling the modal state
   */
  const toggle = item => {
    setEditId(item._id)
    setSlotName(item.name)
    setStartTime(item.startTime)
    setEndTime(item.endTime)
    setSelectedTechnician(
      technicians?.filter(items =>
        item?.selectedTechnicians?.find(
          v => v.toString() === items.value.toString()
        )
      )
    )
    setModal(!modal) 
  }

  const editSlotData = async event => {
    event.preventDefault()
    try {
      const editSlotList = await editSlot({
        editId,
        slotName,
        startTime,
        endTime,
        selectedTechnician: selectedTechnician.map(sc => sc.value),
      })
      setEditedSlot(editSlotList)
      setModal(!modal)
    } catch (error) {
      alert("Failed to Update")
    }
  }

  const popToggle = result => {
    setDeleteId(result)

    setPopModal(!popModal)
  }

  const deleteSlot = async () => {
    const deleteDoc = await getSlotDeleted({ deleteId })

    setDeletedSlot(deleteDoc)
    setPopModal(!popModal)
  }

  document.title = "Slot | Sayartak "

  const SlotList = async () => {
    try {
      setLoading(true)
      const response = await getSlots()
      if (response) {
        setData(response)
      }
    } catch (e) {
      setLoading(false)
      alert("Sorry,Something went wrong")
    }
    setLoading(false)
  }

  return (
    <React.Fragment>
      {/* <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      /> */}
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Row>
            <Col sm={6}>
              <Breadcrumbs title="Sayartak" breadcrumbItem="Slot" />
            </Col>
            <Col sm={6}>
              <div className="float-end d-none d-md-block">
                <Link
                  to="/add-new-slot"
                  className="btn btn-secondary"
                  style={{ marginTop: 10 }}
                >
                  Add New
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <div id="external-events" className="mt-12">
                        <h4 className="card-title mb-4">List of Slots</h4>
                        <div className="table-responsive">
                          <table className="table table-hover table-centered table-nowrap mb-0">
                            <thead className="table-light">
                              <tr>
                                {/* <th scope="col">(#) Id</th> */}

                                <th scope="col">Slot Name</th>
                                <th scope="col">Start Time</th>
                                <th scope="col">End Time</th>
                                <th scope="col" colSpan={3}>
                                  Action
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {isLoading ? (
                                <tr>
                                  <td colSpan="8">
                                    <Loader />
                                  </td>
                                </tr>
                              ) : (
                                data?.map((item, index) => {
                                  return (
                                    <tr>
                                      {item.name ? (
                                        <td
                                          onClick={() =>
                                            navigate("/slot-details", {
                                              state: item,
                                            })
                                          }
                                        >
                                          {item.name}
                                        </td>
                                      ) : null}

                                      <td
                                        onClick={() =>
                                          navigate("/slot-details", {
                                            state: item,
                                          })
                                        }
                                      >
                                        {item.startTime}
                                      </td>
                                      <td
                                        onClick={() =>
                                          navigate("/slot-details", {
                                            state: item,
                                          })
                                        }
                                      >
                                        {item.endTime}
                                      </td>

                                      <td>
                                        {" "}
                                        <i
                                          onClick={() =>
                                            navigate(`/slot-details/${item._id}`, {
                                              state: item,
                                            })
                                          }
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          className="fas fa-eye"
                                        ></i>
                                      </td>
                                      <td>
                                        {" "}
                                        <i
                                          onClick={() => toggle(item)}
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          className="fas fa-edit"
                                        ></i>
                                      </td>
                                      <td>
                                        {" "}
                                        <i
                                          onClick={() => popToggle(item._id)}
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          className="fas fa-trash"
                                        ></i>
                                      </td>
                                    </tr>
                                  )
                                })
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          <Col>
            <Modal isOpen={modal} toggle={toggle} centered size="lg">
              <ModalHeader className="mt-0" toggle={() => setModal(!modal)}>
                Edit Slot
              </ModalHeader>
              <ModalBody>
                <Form
                  id="form-file-upload"
                  onDragEnter={handleDrag}
                  // style={{ marginTop: 10 }}
                  className="row mt-10 needs-validation"
                  // onSubmit={e => {
                  //   e.preventDefault()
                  //   validation.handleSubmit()
                  //   setModal(!modal), e.preventDefault()
                  //   return false
                  // }}
                >
                  <Row className="mb-3">
                    <label className="col-sm-2 col-form-label">Slot Name</label>
                    <div className="col-md-6">
                      <Input
                        className="form-control form-control-lg"
                        type="text"
                        placeholder="Slot Name"
                        value={slotName}
                        onChange={e => setSlotName(e.target.value)}
                      />
                    </div>
                  </Row>

                  <Row className="mb-3">
                    <label
                      htmlFor="example-time-input"
                      className="col-md-2 col-form-label"
                    >
                      Start Time
                    </label>
                    <div className="col-md-6">
                      <Input
                        name="startTime"
                        className="form-control form-control-lg"
                        type="time"
                        id="example-time-input"
                        value={startTime}
                        onChange={e => setStartTime(e.target.value)}
                      />
                    </div>
                    {/* <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} /> */}
                    <label
                      id="label-file-upload"
                      htmlFor="input-file-upload"
                      className={dragActive ? "drag-active" : ""}
                    ></label>

                    {dragActive && (
                      <div
                        id="drag-file-element"
                        onDragEnter={handleDrag}
                        onDragLeave={handleDrag}
                        onDragOver={handleDrag}
                        onDrop={handleDrop}
                      ></div>
                    )}
                  </Row>

                  <Row className="mb-3">
                    <label
                      htmlFor="example-time-input"
                      className="col-md-2 col-form-label"
                    >
                      End Time
                    </label>
                    <div className="col-md-6">
                      <Input
                        name="entTime"
                        className="form-control form-control-lg"
                        type="time"
                        id="example-time-input"
                        value={endTime}
                        onChange={e => setEndTime(e.target.value)}
                      />
                    </div>
                  </Row>

                  <Row className="mb-3">
                    <label className="col-md-2 col-form-label">Selected Technician :</label>
                    <div className="col-md-6">
                      <Select
                        isMulti
                        placeholder="Choose Available Technicians"
                        options={technicians}
                        name="selectedTechnician"
                        value={selectedTechnician}
                        onChange={e =>setSelectedTechnician(e)}
                      />
                    </div>
                  </Row>

                  <div className="d-flex flex-wrap gap-2">
                    <Button
                      type="submit"
                      color="primary"
                      onClick={editSlotData}
                    >
                      Submit
                    </Button>{" "}
                    <Button
                      type="reset"
                      color="secondary"
                      onClick={() => setModal(!modal)}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              </ModalBody>
            </Modal>
          </Col>

          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center"></div>
            <Modal isOpen={popModal} toggle={popToggle} size="sm">
              <ModalHeader className="mt-0" toggle={popToggle}>
                Delete
              </ModalHeader>
              <ModalBody>
                <p>Are you sure to delete this item</p>
                <div className="d-flex flex-wrap gap-2">
                  <Button
                    type="submit"
                    color="secondary"
                    onClick={() => setPopModal(!popModal)}
                  >
                    Cancel
                  </Button>{" "}
                  <Button type="reset" color="danger" onClick={deleteSlot}>
                    Delete
                  </Button>
                </div>
              </ModalBody>
            </Modal>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}

Calender.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
}

const mapStateToProps = ({ calendar }) => ({
  events: calendar.events,
  categories: calendar.categories,
})

const mapDispatchToProps = dispatch => ({
  onGetEvents: () => dispatch(getEvents()),
  onGetCategories: () => dispatch(getCategories()),
  onAddNewEvent: event => dispatch(addNewEvent(event)),
  onUpdateEvent: event => dispatch(updateEvent(event)),
  onDeleteEvent: event => dispatch(deleteEvent(event)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Calender)
