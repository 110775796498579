import React, { useState } from "react"
import { Tab, Tabs } from "react-bootstrap"

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Button,
  Form,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useLocation } from "react-router-dom"


const FormElements = () => {

  const [tabKey, initTabKey] = useState("one")
  const location = useLocation()
  const data = location.state

  document.title = "City Details | Sayartak"
  console.log("props", data)
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="Sayartak"
            title="City"
            breadcrumbItem="City Details"
            url="/city"
          />

          <Tabs activeKey={tabKey} onSelect={e => initTabKey(e)}>
            <Tab eventKey="one" title="Details">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                        <div className="row">
                          <div class="col-sm-6">
                            <div class="details-section-item">
                              <label class="details-section-item-label">
                                City Name En
                              </label>
                              <div class="details-section-item-content">
                                <span>{data?.name?.en || "-"}</span>
                              </div>
                            </div>
                          </div>
                      
                          <div class="col-sm-6">
                            <div class="details-section-item">
                              <label class="details-section-item-label">
                               City Name Ar
                              </label>
                              <div class="details-section-item-content">
                               <span>{data?.name?.ar || "-"}</span>
                              </div>
                            </div>
                          </div>
                     
                        </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Tab>
            {/* <Tab eventKey="two" title="Demo">
              <p>Hellooo</p>
            </Tab> */}
          </Tabs>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormElements
