import React, { useEffect, useState } from "react";
import { getCustomers } from "common/Services/dbServices";
import { Card, CardBody, Col, Row } from "reactstrap";
import Loader from "components/Common/Loader";
import ReactPaginate from 'react-paginate';

const Customer = () => {
  const [customerData, setCustomerData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0); // Current page number
  const customersPerPage = 10; // Number of customers to display per page

  useEffect(() => {
    getCustomerData();
  }, []);

  const getCustomerData = async () => {
    try {
      setLoading(true);
      const result = await getCustomers();
      console.log("Customer=", result);
      if (result) {
        setCustomerData(result);
      }
    } catch (e) {
      setLoading(false);
      console.log("Error =", e);
    }
    setLoading(false);
  };

  const pageCount = Math.ceil(customerData?.length / customersPerPage);

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  const displayedCustomers = customerData?.slice(
    pageNumber * customersPerPage,
    (pageNumber + 1) * customersPerPage
  );

  document.title = "Customer | Sayartak";

  return (
    <React.Fragment>
      <div className="page-content">
        <Col className="col-12">
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">List of Customers</h4>
                  <div id="external-events" className="mt-12">
                    <div className="table-responsive">
                      <table className="table table-hover table-nowrap mb-0">
                        <thead className="table-light">
                          <tr>
                            <th scope="col">SL:NO</th>
                            <th scope="col">Name</th>
                            <th scope="col">Mobile Number</th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoading ? (
                            <tr>
                              <td colSpan="3">
                                <Loader />
                              </td>
                            </tr>
                          ) : (
                            displayedCustomers.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{item.name}</td>
                                  <td>{item.mobileNumber}</td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col>
         <div style={{display:"flex",justifyContent:"flex-end"}}>
         <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            pageCount={pageCount}
            onPageChange={handlePageChange}
            containerClassName="pagination"
            activeClassName="active"
          />
         </div>
        
        </Col>     
      
      </div>
    </React.Fragment>
  );
};

export default Customer;
