import React, { useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Button,
  Form,
  Input,
} from "reactstrap"
import { IKUpload, IKContext } from "imagekitio-react"
import { createServiceCategory } from "common/Services/dbServices"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import {
  urlEndpoint,
  authenticationEndpoint,
  publicKey,
} from "../../components/Common/imageKit"

const FormElements = () => {

  const [res, setRes] = useState()
  const [serviceNameEn, setserviceNameEn] = useState()
  const [serviceNameAr, setserviceNameAr] = useState()
  const [serviceOrder, setserviceOrder] = useState(0)
  const navigate = useNavigate()

  const onError = err => {
    console.log("Error", err)
  }
  const onSuccess = result => {
    setRes(result)
    console.log(result)
  }

  const onSubmit = async e => {
    e.preventDefault()
    try{
    const getServiceCategory = await createServiceCategory({
      serviceNameEn,
      serviceNameAr,
      serviceOrder,
      res,
    })
    console.log("submit::", getServiceCategory)
    alert('Added Successfully')
    navigate("/service-categories")
  }
  catch(error){
    alert('Failed to Add')
  }
  }

  document.title = "Add New Service | Sayartak"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="Sayartak"
            title="Services"
            breadcrumbItem="Add New Service Category"
            url="/service-categories"
          />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  {/* <CardTitle className="h4">Details</CardTitle> */}
                  <Form
                    style={{ marginTop: 10 }}
                    className="row g-3 needs-validation"
                    // onSubmit={e => {
                    //   e.preventDefault()
                    //   validation.handleSubmit()
                    //   return false
                    // }}
                  >
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Service Category En
                      </label>
                      <div className="col-md-6">
                        <Input
                          name="nameen"
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Service Name En"
                          value={serviceNameEn}
                          onChange={e => setserviceNameEn(e.target.value)}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Service Category Ar
                      </label>
                      <div className="col-md-6">
                        <Input
                          name="namear"
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Service Name Ar"
                          value={serviceNameAr}
                          onChange={e => setserviceNameAr(e.target.value)}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Service Icon
                      </label>
                      <div className="col-md-6">
                        <IKContext
                          publicKey={publicKey}
                          urlEndpoint={urlEndpoint}
                          authenticationEndpoint={authenticationEndpoint}
                        >
                          <IKUpload
                            //    fileName={data.brandOrder}
                            onError={onError}
                            onSuccess={onSuccess}
                          />
                        </IKContext>
                      </div>
                    </Row>

                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Order No
                      </label>
                      <div className="col-md-6">
                        <Input
                          name="order"
                          className="form-control form-control-lg"
                          type="number"
                          placeholder="Order No"
                          value={serviceOrder}
                          onChange={e => setserviceOrder(e.target.value)}
                        />
                      </div>
                    </Row>

                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary" onClick={onSubmit}>
                        Submit
                      </Button>{" "}
                      <Button type="reset" color="secondary" onClick={() => navigate("/service-categories")}>
                        Cancel
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormElements
