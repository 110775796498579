import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Button,
  Form,
  Input,
  Alert,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useNavigate } from "react-router-dom"
import {
  getServiceCategory,
  getCustomers,
  createCouponCode,
  ObjectId,
  updateCoupon,
  getCouponCode,
  getServiceTypesData,
} from "common/Services/dbServices"
import Select from "react-select"
import Switch from "react-switch"
import { useParams } from "react-router-dom"
import { useFormik } from 'formik';
import moment from "moment"


const FormElements = () => {
  const navigate = useNavigate()
  const [serviceType, setServiceType] = useState("")
  const [customers, setCustomers] = useState([])
  const [toggleSwitch, setToggleSwitch] = useState(false)
  const [isActive, setisActive] = useState(false)
  const [isServiceChargeActive,setIsServiceChargeActive] = useState(false)
  const [isServiceChargeDiscount,setIsServiceChargeDiscount] = useState(false)
  const [type, setType] = useState("")
  const [response, setResponse] = useState(null)
  const urlParams = useParams()


  const initialValues = {
    couponName: '',
    couponDescription: "",
    couponCode: "",
    includedServiceTypes: null,
    excludedServiceTypes: null,
    includedCustomers: null,
    excludedCustomers: null,
    type: null,
    amount: null,
    validFrom: null,
    validTill: null,
  };

  useEffect(() => {
    if (response && serviceType) {
      setInputs()
    }
  }, [response, serviceType])



  useEffect(() => {
    getCouponData()
    getCustomerData()
    serviceTypeData()
  }, [])


  const onSubmit = async (values) => {
    if (urlParams.id === undefined) {
      try {
        const response = await createCouponCode({
          couponName: values.couponName,
          couponDescription: values.couponDescription,
          couponCode: values.couponCode,
          includedServiceTypes: values.includedServiceTypes?.map(s => ObjectId(s.value)),
          excludedServiceTypes: values.excludedServiceTypes?.map(s => ObjectId(s.value)),
          excludedCustomers: values.excludedCustomers?.map(s => ObjectId(s.value)),
          includedCustomers: values.includedCustomers?.map(s => ObjectId(s.value)),
          type,
          amount: values.amount,
          validTill: values.validTill,
          validFrom: values.validFrom,
          toggleSwitch,
          isActive,
          isServiceChargeActive,
          isServiceChargeDiscount
        })

        alert("Item Added Successfully")
        navigate("/coupon")
      } catch (error) {
        alert("Failed to Add")
      }
    }
    else{
      try {
        const res = await updateCoupon({
          editId: ObjectId(urlParams.id),
          couponName: values.couponName,
          couponDescription: values.couponDescription,
          couponCode: values.couponCode,
          includedServiceTypes: values.includedServiceTypes?.map(s => ObjectId(s.value)),
          excludedServiceTypes: values.excludedServiceTypes?.map(s => ObjectId(s.value)),
          excludedCustomers: values.excludedCustomers?.map(s => ObjectId(s.value)),
          includedCustomers: values.includedCustomers?.map(s => ObjectId(s.value)),
          type,
          amount: values.amount,
          validTill: values.validTill,
          validFrom: values.validFrom,
          toggleSwitch,
          isActive,
          isServiceChargeActive,
          isServiceChargeDiscount
        })
        alert("Item Updated Successfully")
        navigate("/coupon")
      } catch (e) {
        console.log('e=', e)
        alert("Failed to Add")
      }
    }

  }

  const validate = values => {
      console.log('validate=',values)
      console.log('type=',type)
    let errors = {}
    if (!values.couponName) {
      errors.couponName = 'Coupon name required'
    }
    if (!values.couponDescription) {
      errors.couponDescription = 'Coupon description required'
    }
    if (!values.couponCode) {
      errors.couponCode = 'Coupon code value required'
    }
    if (!values.amount) {
      errors.amount = 'Amount required'
    }
    if (type === undefined || type === 'Select Type') {
      errors.type = 'Coupon type required'
    }
    // if (!values.includedServiceTypes) {
    //   errors.includedServiceTypes = 'Included service types required'
    // }
    // if (!values.excludedServiceTypes) {
    //   errors.excludedServiceTypes = 'Excluded service types required'
    // }
    // if (!values.includedCustomers) {
    //   errors.includedCustomers = 'Included customers is required'
    // }
    // if (!values.excludedCustomers) {
    //   errors.excludedCustomers = 'Excluded customers is required'
    // }
    if (!values.validFrom) {
      errors.validFrom = 'validFrom is required'
    }
    if (!values.validTill) {
      errors.validTill = 'validTill is required'
    }
    return errors

  }

  const formik =
    useFormik({
      initialValues,
      onSubmit,
      validate
    })



  const serviceTypeData = async () => {
    try {
      const result = await getServiceCategory()
      if (result) {
        let Services = []
        result.map((item, index) => {
          let obj1 = {
            value: item?._id.toString(),
            label: item?.name.en,
          }

          Services.push(obj1)
        })
        setServiceType(Services)
        // console.log("services", Services)
      }
    } catch (e) {
      // console.log("Error = ",e)
    }
  }

  const getCustomerData = async () => {
    try {
      const result = await getCustomers()
      if (result) {
        let Cus = []
        result.map((item, index) => {
          let obj2 = {
            value: item?._id.toString(),
            label: item?.name,
          }

          Cus.push(obj2)
        })
        setCustomers(Cus)
      }
    } catch (e) {
      // console.log("Error = ",e)
    }
  }
  const getCouponData = async () => {
    try {
      const res = await getCouponCode({ id: urlParams.id })
      // console.log('v ==',response[0].getExcludedServiceTypes.map((v)=>v.name.en).toString())
      setResponse(res)
      setType(res[0]?.type)
      setToggleSwitch(res[0]?.toggleSwitch)
      setisActive(res[0]?.isActive)
      setIsServiceChargeActive(res[0]?.isServiceChargeActive)
      setIsServiceChargeDiscount(res[0]?.isServiceChargeDiscountActive)
    } catch (error) {
      console.error(error)
    }
  }

  const setInputs = () => {
    if (response[0]) {
      formik.setValues({
        couponName: response[0].name,
        couponDescription: response[0].description,
        couponCode: response[0].couponCode,
        includedServiceTypes: serviceType ? serviceType.filter(item => response[0].getIncludedServiceTypes.some(v => v.name.en === item.label)) : null,
        excludedServiceTypes: serviceType ? serviceType.filter(item => response[0].getExcludedServiceTypes.some(v => v.name.en === item.label)) : null,
        includedCustomers: customers ? customers.filter(item => response[0].getIncludedCustomers.some(v => v.name === item.label)) : null,
        excludedCustomers: customers ? customers.filter(item => response[0].getExcludedCustomers.some(v => v.name === item.label)) : null,
        amount: response[0].amount,
        validFrom: moment(response[0].validFrom, 'MM/DD/YYYY hh:mm A').format('YYYY-MM-DD HH:mm'),
        validTill: moment(response[0].validTill, 'MM/DD/YYYY hh:mm A').format('YYYY-MM-DD HH:mm'),
      });
    }
  }

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        ON
      </div>
    )
  }

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        OFF
      </div>
    )
  }

  const types = [
    { value: 'flat', label: "flat" },
    { value: 'percentage', label: "percentage" },
  ]

  document.title = "Add Coupon | Sayartak"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="Sayartak"
            title="Coupon"
            breadcrumbItem={
              response && response[0] ? "Edit Coupon" : "Add Coupon"
            }
            url="/coupon"
          />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  {/* <CardTitle className="h4">Details</CardTitle> */}
                  <Form
                    style={{ marginTop: 10 }}
                    className="row g-3 needs-validation"
                    onSubmit={formik.handleSubmit}
                  >
                     <Row className="mb-3">
                      <label className="col-md-2 col-form-label">
                        Coupon For ServiceCharge Discount 
                      </label>
                      <div className="col-md-6">
                        <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          onColor="#0F52BA"
                          onChange={() => {
                            setIsServiceChargeDiscount(!isServiceChargeDiscount)
                          }}
                          checked={isServiceChargeDiscount}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">Name</label>
                      <div className="col-md-6">
                        <Input
                          name="couponName"
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.couponName}
                        />
                        {formik.touched.couponName && formik.errors.couponName ? (
                          <div style={{ color: 'red' }}>
                            {formik.errors.couponName}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Description
                      </label>
                      <div className="col-md-6">
                        <Input
                          name="couponDescription"
                          className="form-control form-control-lg"
                          type="textarea"
                          placeholder="Add Description"
                      
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.couponDescription}
                        />
                        {formik.touched.couponDescription && formik.errors.couponDescription ? (
                          <div style={{ color: 'red' }}>
                            {formik.errors.couponDescription}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Coupon Code
                      </label>
                      <div className="col-md-6">
                        <Input
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Add Coupon Code"
                          name='couponCode'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.couponCode}
                        />
                        {formik.touched.couponCode && formik.errors.couponCode ? (
                          <div style={{ color: 'red' }}>
                            {formik.errors.couponCode}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">
                        Included ServiceTypes
                      </label>
                      <div className="col-md-6">
                        <Select
                          isMulti
                          placeholder="Select ServiceType"
                          options={serviceType}
                          styles={{
                            option: (base) => ({
                              ...base,
                              backgroundColor:'white',
                              height: '100%',
                            }),
                          }}
                          name="includedServiceTypes"
                          onBlur={formik.handleBlur}
                          onChange={selectedOptions => formik.setFieldValue('includedServiceTypes', selectedOptions)}
                          value={formik.values.includedServiceTypes}
                        />
                        {/* {formik.touched.includedServiceTypes && formik.errors.includedServiceTypes ? (
                          <div style={{ color: 'red' }}>
                            {formik.errors.includedServiceTypes}
                          </div>
                        ) : null} */}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">
                        Excluded ServiceTypes
                      </label>
                      <div className="col-md-6">
                        <Select
                          isMulti
                          placeholder="Select ServiceType"
                          options={serviceType}
                          styles={{
                            option: (base) => ({
                              ...base,
                              backgroundColor:'white',
                              height: '100%',
                            }),
                          }}
                          name="excludedServiceTypes"
                          onBlur={formik.handleBlur}
                          onChange={selectedOptions => formik.setFieldValue('excludedServiceTypes', selectedOptions)}
                          value={formik.values.excludedServiceTypes}
                        />
                        {/* {formik.touched.excludedServiceTypes && formik.errors.excludedServiceTypes ? (
                          <div style={{ color: 'red' }}>
                            {formik.errors.excludedServiceTypes}
                          </div>
                        ) : null} */}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">
                        Included Customers
                      </label>
                      <div className="col-md-6">
                        <Select
                          isMulti
                          placeholder="Select Customer"
                          options={customers}
                          styles={{
                            option: (base) => ({
                              ...base,
                              backgroundColor:'white',
                              height: '100%',
                            }),
                          }}
                          name="includedCustomers"
                          onBlur={formik.handleBlur}
                          onChange={selectedOptions => formik.setFieldValue('includedCustomers', selectedOptions)}
                          value={formik.values.includedCustomers}
                        />
                        {/* {formik.touched.includedCustomers && formik.errors.includedCustomers ? (
                          <div style={{ color: 'red' }}>
                            {formik.errors.includedCustomers}
                          </div>
                        ) : null} */}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">
                        Excluded Customers
                      </label>
                      <div className="col-md-6">
                        <Select
                          isMulti
                          placeholder="Select Customer"
                          options={customers}
                          styles={{
                            option: (base) => ({
                              ...base,
                              backgroundColor:'white',
                              height: '100%',
                            }),
                          }}
                          name="excludedCustomers"
                          onBlur={formik.handleBlur}
                          onChange={selectedOptions => formik.setFieldValue('excludedCustomers', selectedOptions)}
                          value={formik.values.excludedCustomers}
                        />
                        {/* {formik.touched.excludedCustomers && formik.errors.excludedCustomers ? (
                          <div style={{ color: 'red' }}>
                            {formik.errors.excludedCustomers}
                          </div>
                        ) : null} */}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">Type</label>
                      <div className="col-md-6">
                        <select
                          className="form-control form-control-lg"
                          value={type}
                          onChange={e => setType(e.target.value)}
                        >
                          <option>{"Select Type"}</option>
                          <option>{"flat"}</option>
                          <option>{"percentage"}</option>
                        </select>
                        {formik.touched.type &&
                        formik.errors.type ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.type}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">Amount</label>
                      <div className="col-md-6">
                        <Input
                          name="amount"
                          className="form-control form-control-lg"
                          type="number"
                          placeholder=""
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.amount}
                        />
                        {formik.touched.amount && formik.errors.amount ? (
                          <div style={{ color: 'red' }}>
                            {formik.errors.amount}
                          </div>
                        ) : null}
                      </div>
                    </Row>


                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        ValidFrom{""}
                      </label>
                      <div className="col-md-6">
                        <Input
                          className="form-control form-control-lg"
                          type="datetime-local"
                          defaultValue="2019-08-19T13:45:00"
                          id="example-datetime-local-input"
                          name='validFrom'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.validFrom}
                        />
                        {formik.touched.validFrom && formik.errors.validFrom ? (
                          <div style={{ color: 'red' }}>
                            {formik.errors.validFrom}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        ValidTill{""}
                      </label>
                      <div className="col-md-6">
                        <Input
                          className="form-control form-control-lg"
                          type="datetime-local"
                          defaultValue="2019-08-19T13:45:00"
                          id="example-datetime-local-input"
                          name='validTill'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.validTill}
                        />
                        {formik.touched.validTill && formik.errors.validTill ? (
                          <div style={{ color: 'red' }}>
                            {formik.errors.validTill}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">
                        UseOnly Once
                      </label>
                      <div className="col-md-6">
                        <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          onColor="#0F52BA"
                          onChange={() => {
                            setToggleSwitch(!toggleSwitch)
                          }}
                          checked={toggleSwitch}
                        />
                      </div>
                    </Row>
                 
                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">
                        isActive
                      </label>
                      <div className="col-md-6">
                        <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          onColor="#0F52BA"
                          onChange={() => {
                            setisActive(!isActive)
                          }}
                          checked={isActive}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">
                        ServiceCharge Enable
                      </label>
                      <div className="col-md-6">
                        <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          onColor="#0F52BA"
                          onChange={() => {
                            setIsServiceChargeActive(!isServiceChargeActive)
                          }}
                          checked={isServiceChargeActive}
                        />
                      </div>
                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                        <Button
                          type="submit"
                          color="primary"
                        >
                        {response && response[0] ? 'Update': 'Submit'}
                        </Button>{" "}
                        <Button
                          type="reset"
                          color="secondary"
                          onClick={() => navigate("/Coupon")}
                        >
                          Cancel
                        </Button>
                      </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormElements
