import React, { useState, useEffect, useRef } from "react"
import Select from 'react-select';
import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Button,
  Form,
  Input
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getCustomer, getBrandName, getChatList, getDBInstance, ObjectId, updateNewChat} from "common/Services/dbServices";
import { createNewChat, createNewMessage } from "common/Services/dbServices";
import { useParams, useLocation } from "react-router-dom";


import './Style.css'
import { app } from "Context/userContext";
const ChatElements = () => {
  const chatContainerRef = useRef(null);

  const [data, setData] = useState({});
  const [message, setMessage] = useState('');
  const [updateMessage, setUpdateMessage] = useState('');
  const [getMessages, setGetMessages] = useState('');

  const [breakAsyncIterator, setBreakAsyncIterator] = useState(false);
  // console.log("breakAsyncIterator === == == == ", breakAsyncIterator)

  const location = useLocation()
  const orderData = location.state
  // console.log('orderData', orderData)

  const OrderId = useParams()
  const owner_id = ObjectId(orderData.owner_id)



  ///subscription/////
  useEffect(() => {
    subscribe()
  }, []);
  ///subscription/////

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 400)
  }, []);



  useEffect(() => {
      CustomerList();
      getChat();
  }, [OrderId,breakAsyncIterator, updateMessage])



  const subscribe = async () => {
    const collectionChat = await getDBInstance(app).collection('Chat');

    for await (const change of collectionChat.watch()) {
      // let breakAsyncIterator = false; // Later used to exit async iterator
      switch (change.operationType) {
        case "insert": {
          const { documentKey, fullDocument } = change;
           console.log(`new document chat: ${documentKey}`, fullDocument);
          // breakAsyncIterator = true;
          getChat();
          setBreakAsyncIterator(!breakAsyncIterator)
          break;
        }
        case "update": {
          const { documentKey, fullDocument } = change;
          console.log(`updated document chat: ${documentKey}`, fullDocument);
          getChat();
          setBreakAsyncIterator(!breakAsyncIterator)
          break;
        }
        case "replace": {
          const { documentKey, fullDocument } = change;
          console.log(`replaced document chat: ${documentKey}`, fullDocument);
          // breakAsyncIterator = true;
          getChat();
          setBreakAsyncIterator(!breakAsyncIterator)
          break;
        }
        case "delete": {
          const { documentKey } = change;
          console.log(`deleted document chat: ${documentKey}`);
          // breakAsyncIterator = true;
          getChat();
          setBreakAsyncIterator(!breakAsyncIterator)
          break;
        }
      }
      if (breakAsyncIterator) break; // Exit async iterator
    }

  }


  const scrollToBottom = () => {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  };

  const CustomerList = async () => {
    const getCustomerDetils = await getCustomer({ id: OrderId.id })
    // console.log("getCustomerDetils",getCustomerDetils[0])
    if (getCustomerDetils) {
      setData(getCustomerDetils[0])
      saveChat()
    }
  }

  const getChat = async () => {
    const ChatList = await getChatList({ id: OrderId.id })
  //  console.log('ChatList22231=', ChatList)
    if (ChatList) {
      setGetMessages(ChatList[0]?.getMessage)
      updateChat(ChatList[0]._id)
    }
    // scrollToBottom();  
  }




  const saveChat = async () => {
    try {

      // console.log('order_id : ',typeof OrderId.id,"*******")
      // console.log('owner_id : ',typeof owner_id,"*******")
      const response = await createNewChat({ orderId:ObjectId(OrderId.id) , owner_id });
      // console.log("submit::", response) 
    }
    catch (error) {
      // console.log("Error ==",error)
      alert("Failed to Add")
    }
  }

  const updateChat = async (chatId) => {
    try {
      console.log("chatId=",chatId)
      const createCarRes = await updateNewChat({ chatId });
      console.log("submit::", createCarRes)
      //  alert("Item updated Successfully")  

    }
    catch (error) {
      alert("Failed to update")
    }
  }

  const saveMessage = async () => {
    // console.log('message=', message.length)
    try {
      if (message?.length > 0) {
        const createCarRes = await createNewMessage({ 
          message,
           orderId:OrderId.id, 
           owner_id,
         });
        if (createCarRes) {
          scrollToBottom();
          setMessage("")
          setUpdateMessage(createCarRes)
        }
      }
      // alert("Item Added Successfully")  
    } catch (error) {
      // console.log("Error ==",error)
      alert("Failed to Add message")
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      saveMessage(); // Call the function that sends the chat message
    }
  };

  document.title = "Sayartak"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="Sayartak"
            title="Orders"
            breadcrumbItem="Chat"
            url="/Orders"
          />

          <div className="container">
            <div className="row no-gutters">

              <div className="col-md-12">
                <div className="settings-tray">
                  <div className="friend-drawer no-gutters friend-drawer--grey">
                    {/* <img className="profile-image" src="https://www.clarity-enhanced.net/wp-content/uploads/2020/06/robocop.jpg" alt="" /> */}

                    <div className="text">
                      <h6>
                        {
                          data && data.getCustomer && data.getCustomer.name  ?
                            (data.getCustomer.name) :
                            (null)
                        }
                      </h6>
                      {/* <p className="text-muted">  {data.email}</p> */}
                    </div>
                    <span className="settings-tray--right">
                      {/* <i className="material-icons">cached</i>
                      <i className="material-icons">message</i>
                      <i className="material-icons">menu</i> */}
                      {/* <i className="ion ion-md-refresh"></i>
                      <i className="ion ion-ios-chatbubbles"></i>
                      <i className="ion ion-md-menu"></i> */}
                    </span>
                  </div>
                </div>
                <div className="chat-panel">
                  <div ref={chatContainerRef} className="chat-feed">
                    {getMessages?.length !== 0 ?
                      (
                        getMessages?.map((item, index) => {
                          return (
                            <>
                            { console.log('item.senderEntity=',item.senderEntity )}
                              {item.senderEntity === "Admin" ?
                                (
                                  <div className="row no-gutters">
                                    <div className="col-md-3 offset-md-9">
                                      <div className="chat-bubble chat-bubble--right ">
                                        {item.text}
                                      </div> 
                                    </div>
                                  </div>)
                                :
                                (
                                  <div className="row no-gutters">
                                    <div className="col-md-3">
                                      <div className="chat-bubble chat-bubble--left ">
                                      <p style={{ wordWrap: 'break-word' }}>{item.text}</p>
                                      </div>
                                    </div>
                                  </div>)
                              }
                            </>
                          )
                        })
                      )
                      :
                      (null)
                    }
                  </div>


                  <div className="row">
                    <div className="col-12">
                      <div className="chat-box-tray">
                        <input
                          type="text"
                          placeholder=" Type your message here..."
                          value={message}
                          onChange={e => setMessage(e.target.value)}
                          onKeyDown={handleKeyDown}
                        />
                        {/* <i className="ion ion-md-mic"></i> */}
                        <i className="ion ion-md-send" onClick={() => saveMessage()}></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



        </Container>
      </div>
    </React.Fragment>
  )
}



export default ChatElements
