import React, { useEffect, useState } from "react"
import {
  Container,
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Form,
  Input,
  Alert,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  createNewBrand,
  getVehicleBrand,
  editVehicleBrand,
  ObjectId,
} from "common/Services/dbServices"
import { useNavigate } from "react-router-dom"
import ImageSection from "components/Common/ImageSection";
import { useParams } from "react-router-dom"
import { useFormik } from "formik"

const FormElements = () => {
  const navigate = useNavigate()
  const [isHovered, setIsHovered] = useState(false)
  const [image, setImage] = useState(null)
  const [progress, setProgress] = useState(false)
  const [res, setRes] = useState({})
  const [response, setResponse] = useState(null)
  const urlParams = useParams()
  useEffect(() => {
    if (urlParams.id) {
      setBrandData()
    }
  }, [urlParams])

  useEffect(() => {
    if (response) {
      setInputs()
    }
  }, [response])
  const setBrandData = async () => {
    try {
      const response = await getVehicleBrand({ id: urlParams.id })
      setResponse(response)
    } catch (e) {
      alert("Sorry,Something went wrong")
    }
  }
  const onError = err => {
    console.log("Error", err)
  }
  const onSuccess = result => {
    if (result) {
      setProgress(false)
    }
    setRes(result)
    setImage(result.filePath)
  }

  const onUploadProgress = progress => {
    setProgress(progress)
  }
  const onUploadStart = progress => {
    setProgress(progress)
  }

  const initialValues = {
    name: "",
    order: 0,
  }

  const validate = values => {
    let errors = {}
    if (!values.name) {
      errors.name = "Brand Name Is Required"
    }
    return errors
  }


  const handleCreateBrand = async (values) => {
    try {

      const response = await createNewBrand({ values, res })

      alert("Item Added Successfully")
      navigate("/brand")
    } catch (error) {
      alert("Failed to Add")
      navigate("/brand")
    }
  }

  const handleUpdateBrand = async (values) => {
    const id = urlParams?.id
    try {
      const response = await editVehicleBrand({
        id, values, res
      })

      if (response && response.modifiedCount !== 0) {
        alert("Item Updated Successfully")
        navigate("/brand")
      } else {
        alert("Failed to update")
      }


    }
    catch (e) {
      alert("Failed to update")
      navigate("/brand")
    }
  }

  const onSubmit = async values => {

    if (urlParams.id === undefined) {
      handleCreateBrand(values);
    } else {
      handleUpdateBrand(values);

    }
  }
  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  })

  const setInputs = () => {
    if (response) {
      formik.setValues({
        name: response?.name?.en,
        order: response?.order,
      })
    }
    setRes(response?.images)
    setImage(response?.images.filePath)
  }
  document.title = "Add New Brand | Sayartak"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="Sayartak"
            title="Brand"
            breadcrumbItem={response ? "Edit Brand" : "Add New Brand"}
            url="/Brand"
          />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  {/* <CardTitle className="h4">Details</CardTitle> */}
                  <Form
                    style={{ marginTop: 10 }}
                    className="row g-3 needs-validation"
                    onSubmit={formik.handleSubmit}
                  >
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Brand Name
                      </label>
                      <div className="col-md-6">
                        <Input
                          name="name"
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Brand Name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name}
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.name}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Brand Icon
                      </label>
                      <ImageSection
                          progress={progress}
                          image={image}
                          isHovered={isHovered}
                          onHoverEnter={() => setIsHovered(true)}
                          onHoverLeave={() => setIsHovered(false)}
                          onImageRemove={() => {
                            setImage(null);
                            setRes(null);
                          }}
                          onUploadError={onError}
                          onUploadSuccess={onSuccess}
                          onUploadProgress={onUploadProgress}
                          onUploadStart={onUploadStart}
                        />
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Order No
                      </label>
                      <div className="col-md-6">
                        <Input
                          name="order"
                          className="form-control form-control-lg"
                          type="number"
                          placeholder="Order No"
                          onChange={formik.handleChange}
                          value={formik.values.order}
                        />
                      </div>
                    </Row>

                    <div className="d-flex flex-wrap gap-2">
                      <Button
                        type="submit"
                        color="primary"
                      >
                        {response ? 'Update' : 'Submit'}
                      </Button>{" "}
                      <Button
                        type="reset"
                        color="secondary"
                        onClick={() => navigate("/brand")}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormElements
