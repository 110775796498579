import React, { useState } from "react"
import Select from "react-select"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  Form,
  Input,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  createNewPush,
} from "common/Services/dbServices"
import { useAudience, useTechnicianAudience } from "common/Hooks/queryCalls"
import { IKUpload, IKContext } from "imagekitio-react"
import { IKImage } from "imagekitio-react"
import { AiOutlineCloseCircle } from "react-icons/ai"
import {
  urlEndpoint,
  authenticationEndpoint,
  publicKey,
} from "../../components/Common/imageKit"
import { useNavigate } from "react-router-dom"
import { useFormik } from "formik"
const FormElements = () => {
  const [res, setRes] = useState({})
  const navigate = useNavigate()
  const [image, setImage] = useState(null)
  const [isHovered, setIsHovered] = useState(false)
  const [progress, setProgress] = useState(false)
  const { data: audienceData } = useAudience();
  const { data: technicianData } = useTechnicianAudience();
  const combinedData = audienceData && technicianData ? audienceData.concat(technicianData) : [];


  const audience = [
    { value: audienceData, label: "All Customers" },
    { value: technicianData, label: "All Technicians" },
    { value: combinedData, label: "Choose Everyone" },
  ]


  const onError = err => {
    console.log("Error", err)
  }

  const onSuccess = result => {
    if (result) {
      setProgress(false)
    }
    setRes(result)
    setImage(result.filePath)
  }
  const onUploadProgress = progress => {
    setProgress(progress)
  }


  const initialValues = {
    addTitle: null,
    selectAudience: null,
    addDescription: null,
  }

  const onSubmit = async (values) => {
    try {
      const response = await createNewPush({
        values,
        res,
      })
      alert("Message Delivered")
      navigate("/Push")
    } catch (error) {
      console.log("error ==", error)
      alert("Failed to Deliver")
    }
  }
  const validate = values => {
    let errors = {}
    if (!values.addTitle) {
      errors.addTitle = "Title required"
    }
    if (!values.selectAudience) {
      errors.selectAudience = "Audience required"
    }
    if (!values.addDescription) {
      errors.addDescription = "Description required"
    }
    return errors
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  })

  document.title = "Create New Push Notification | Sayartak"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="Sayartak"
            title="Push Notification"
            breadcrumbItem="Create New Push Notification"
            url="/Push Notification"
          />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  {/* <CardTitle className="h4">Details</CardTitle> */}
                  <Form
                    style={{ marginTop: 10 }}
                    className="row g-3 needs-validation"
                    onSubmit={formik.handleSubmit}
                  >
                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">
                        Audience
                      </label>
                      <div className="col-md-6">
                        <Select
                          placeholder="Choose Audience"
                          options={audience}
                          name="selectAudience"
                          onBlur={formik.handleBlur}
                          onChange={selectedOption =>
                            formik.setFieldValue(
                              "selectAudience",
                               selectedOption
                            )
                          }
                          value={audience?.find(
                            option =>
                              option.value === formik.values.audience
                          )}
                        />
                        {formik.touched.selectAudience &&
                          formik.errors.selectAudience ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.selectAudience}
                          </div>
                        ) : null}
                      </div>
                    </Row>

                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Image
                      </label>
                      <div
                        className="col-md-6"
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        {progress ? (
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : image ? (
                          <div
                            style={{
                              width: 35,
                              height: 30,
                              display: "flex",
                              alignItems: "center",
                              marginTop: 10,
                            }}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                          >
                            <div
                              style={{
                                position: "relative",
                              }}
                            >
                              <IKImage
                                urlEndpoint={urlEndpoint}
                                path={image}
                                transformation={[{ height: 256, width: 512 }]}
                                width="35px"
                                height="30px"
                              />
                              {isHovered && image && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "5px",
                                    right: "5px",
                                    zIndex: "1",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setImage(null)
                                    setRes(null)
                                  }}
                                >
                                  <AiOutlineCloseCircle
                                    size={16}
                                    color="red"
                                    style={{
                                      position: "absolute",
                                      top: -10,
                                      right: -10,
                                      // color: 'red',
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <IKContext
                            publicKey={publicKey}
                            urlEndpoint={urlEndpoint}
                            authenticationEndpoint={authenticationEndpoint}
                          >
                            <IKUpload
                              //    fileName={data.brandOrder}
                              onError={onError}
                              onSuccess={onSuccess}
                              onUploadProgress={onUploadProgress}
                              buttonProps={{ children: "+" }}
                            />
                          </IKContext>
                        )}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Title
                      </label>
                      <div className="col-md-6">
                        <Input
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Title"
                          name="addTitle"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.addTitle}
                        />
                        {formik.touched.addTitle &&
                          formik.errors.addTitle ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.addTitle}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Description
                      </label>
                      <div className="col-md-6">
                        <Input
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Description"
                          name="addDescription"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.addDescription}
                        />
                        {formik.touched.addDescription &&
                          formik.errors.addDescription ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.addDescription}
                          </div>
                        ) : null}
                      </div>
                    </Row>

                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary">
                        Send
                      </Button>{" "}
                      <Button
                        type="reset"
                        color="secondary"
                        onClick={() => navigate("/Dashboard")}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormElements
