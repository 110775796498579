import React, { useState } from "react"
import { Tab, Tabs } from 'react-bootstrap'
import { useLocation } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Button,
  Form,
  
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"



const FormElements = () => {
  const [customchk, setcustomchk] = useState(true)
  const [customchkPrimary, setcustomchkPrimary] = useState(true)
  const [customchkSuccess, setcustomchkSuccess] = useState(true)
  const [customchkInfo, setcustomchkInfo] = useState(true)
  const [customchkWarning, setcustomchkWarning] = useState(true)
  const [customchkDanger, setcustomchkDanger] = useState(true)
  const [customOutlinePrimary, setcustomOutlinePrimary] = useState(true)
  const [customOutlineSuccess, setcustomOutlineSuccess] = useState(true)
  const [customOutlineInfo, setcustomOutlineInfo] = useState(true)
  const [customOutlineWarning, setcustomOutlineWarning] = useState(true)
  const [customOutlineDanger, setcustomOutlineDanger] = useState(true)
  const [toggleSwitch, settoggleSwitch] = useState(true)
  const [toggleSwitchSize, settoggleSwitchSize] = useState(true)
  const [tabKey, initTabKey] = useState('one')
  const location = useLocation();
  const data = location.state



  document.title = "Service Type Details | Sayartak"
  return (
    
    <React.Fragment>
   
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="Sayartak"
            title="Services"
            breadcrumbItem="Service Type Details"
            url="/service-types"
          />

                <Tabs activeKey={tabKey} onSelect={(e) => initTabKey(e)}>
        <Tab eventKey="one" title="Details">
          <Row>
            <Col>
              <Card>
                <CardBody>
                <div className="row">
                <div class="col-md-6">
                            <div class="details-section-item">
                              <label class="details-section-item-label">
                              Service En 
                              </label>
                              <div class="details-section-item-content">
                                <span>{data?.name?.en || "-"}</span>
                              </div>
                            </div>
                          </div>

                      <div class="col-md-6">
                            <div class="details-section-item">
                              <label class="details-section-item-label">
                              Service Ar 
                              </label>
                              <div class="details-section-item-content">
                                <span>{data?.name?.ar || "-"}</span>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                          <div class="col-12">
                            <hr style={{width: "100%"}} />
                          </div>
                        </div>
                  </div>
                  
                  <div className="row">
                  <div class="col-md-6">
                            <div class="details-section-item">
                              <label class="details-section-item-label">
                               Description En
                              </label>
                              <div class="details-section-item-content">
                                <span>{data?.description?.en || "-"}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="details-section-item">
                              <label class="details-section-item-label">
                                Order No
                              </label>
                              <div class="details-section-item-content">
                                <span>{data?.order || "-"}</span>
                              </div>
                            </div>
                          </div>
                    </div>
               
                    {/* </Tab> */}
                   
                    {/* </Tabs> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
          </Tab>
          {/* <Tab eventKey="two" title="Demo"><p>Hellooo</p></Tab> */}
          </Tabs>
        </Container>
      </div>

    </React.Fragment>

  )
}

export default FormElements
