import React, { useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Button,
  Form,
  Input,
  Alert,
} from "reactstrap"
import Loader from "components/Common/Loader"
import Select from "react-select"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { createNewSlot, getTechnician } from "common/Services/dbServices"
import { Link, useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import { useTechnician } from "common/Hooks/queryCalls"

const FormElements = () => {
  const navigate = useNavigate()
  const { data: technicians, isLoading: isTechnicianLoading } = useTechnician()

  const initialValues = {
    slotName: "",
    startTime: "3:00 PM",
    endTime: "4:00 PM",
    selectedTechnician: "",
  }

  const onSubmit = async values => {
    // console.log('values=',values)
    try {
      const response = await createNewSlot({ values })
      alert("Item Added Successfully")
      navigate("/slot")
    } catch (error) {
      alert("Failed to Add")
      navigate("/slot")
    }
  }

  const validate = values => {
    let errors = {}
    if (!values.slotName) {
      errors.slotName = "Slot name Required"
    }
    return errors
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  })

  document.title = "Add New Slot | Sayartak"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="Sayartak"
            title="Slot"
            breadcrumbItem="Add New Slot"
            url="/Slot"
          />
          {isTechnicianLoading ? (
            <div>
              {" "}
              <Loader />
            </div>
          ) : (
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    {/* <CardTitle className="h4">Details</CardTitle> */}
                    <Form
                      style={{ marginTop: 10 }}
                      className="row g-3 needs-validation"
                      onSubmit={formik.handleSubmit}

                      // onSubmit={e => {
                      //   e.preventDefault()
                      //   validation.handleSubmit()
                      //   return false
                      // }}
                    >
                      <Row className="mb-3">
                        <label className="col-sm-2 col-form-label">
                          Slot Name
                        </label>
                        <div className="col-md-6">
                          <Input
                            name="slotName"
                            className="form-control form-control-lg"
                            type="text"
                            placeholder="Slot Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.slotName}
                          />
                          {formik.touched.slotName && formik.errors.slotName ? (
                            <div style={{ color: "red" }}>
                              {formik.errors.slotName}
                            </div>
                          ) : null}
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-time-input"
                          className="col-md-2 col-form-label"
                        >
                          Start Time
                        </label>
                        <div className="col-md-6">
                          <Input
                            name="startTime"
                            className="form-control form-control-lg"
                            type="time"
                            defaultValue="03:45:00"
                            id="example-time-input"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.startTime}
                          />
                        </div>
                      </Row>

                      <Row className="mb-3">
                        <label
                          htmlFor="example-time-input"
                          className="col-md-2 col-form-label"
                        >
                          End Time
                        </label>
                        <div className="col-md-6">
                          <Input
                            name="endTime"
                            className="form-control form-control-lg"
                            type="time"
                            defaultValue="13:45:00"
                            id="example-time-input"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.endTime}
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-time-input"
                          className="col-md-2 col-form-label"
                        >
                          Select Technicians
                        </label>
                        <div className="col-md-6">
                          <Select
                            isMulti
                            placeholder="Choose Available Technicians"
                            options={technicians}
                            onBlur={formik.handleBlur}
                            value={formik.values.selectedTechnician}
                            onChange={selectedOptions =>
                              formik.setFieldValue(
                                "selectedTechnician",
                                selectedOptions
                              )
                            }
                          />
                        </div>
                      </Row>

                      <div className="d-flex flex-wrap gap-2">
                        <Button type="submit" color="primary">
                          Submit
                        </Button>{" "}
                        <Button
                          type="reset"
                          color="secondary"
                          onClick={() => navigate("/slot")}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormElements
