import React, { useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import logosmImg from "../../assets/images/logo.png"
import { IKImage } from "imagekitio-react"
import Loader from "components/Common/Loader"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap"
import {
  urlEndpoint,
} from "../../components/Common/imageKit"
import { Link, useNavigate } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  addNewEvent,
  deleteEvent,
  getCategories,
  getEvents,
  updateEvent,
} from "../../store/actions"
import { useBanner } from "common/Hooks/queryCalls"
import DeleteModal from "components/Common/DeleteModal";
//css
import "@fullcalendar/bootstrap/main.css"
import { getDeleteBanner } from "common/Services/dbServices"
const Calender = props => {

  const [popModal, setPopModal] = useState(false)
  const navigate = useNavigate()
  const [id, setDeleteItemId] = useState()
  const [deletedItem, setDeletedItem] = useState()
  const { data: banner, isLoading, refetch } = useBanner();




  const popToggle = id => {
    setDeleteItemId(id)
    setPopModal(!popModal)
  }
  const deleteItem = async () => {
    const deleteDoc = await getDeleteBanner({ id })
    refetch();
    setDeletedItem(deleteDoc)
    setPopModal(!popModal)
  }

  document.title = "Banner | Sayartak "

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Row>
            <Col sm={6}>
              <Breadcrumbs title="Sayartak" breadcrumbItem="Banner" />
            </Col>
            <Col sm={6}>
              <div className="float-end d-none d-md-block">
                <Link
                  to="/add-new-banner"
                  className="btn btn-secondary"
                  style={{ marginTop: 10 }}
                >
                  Add New
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <div id="external-events" className="mt-12">
                        <h4 className="card-title mb-4">List of Banners</h4>
                        <div className="table-responsive">
                          <table className="table table-hover table-centered table-nowrap mb-0">
                            <thead className="table-light">
                              <tr>
                                {/* <th scope="col">(#) Id</th> */}

                                <th scope="col">Banner</th>
                                <th scope="col">Order No</th>
                                <th scope="col" colSpan={2}>
                                  Action
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {isLoading ? (
                                <tr key="loading">
                                  <td colSpan="8">
                                    <Loader />
                                  </td>
                                </tr>
                              ) : (
                                banner?.map(item => {
                                  return (
                                    <tr key={item._id}>
                                      <td>
                                        <div>
                                          {item?.images &&
                                            item?.images?.filePath ? (
                                            <IKImage
                                              urlEndpoint={urlEndpoint}
                                              path={item.images.filePath}
                                              width="35px"
                                              height="30px"
                                            />
                                          ) : (
                                            <img
                                              src={logosmImg}
                                              alt=""
                                              className="img-thumbnail-sm"
                                              width="35px"
                                              height="35px"
                                            />
                                          )}
                                        </div>
                                      </td>
                                      <td>{item?.order}</td>
                                      <td>
                                        {" "}
                                        <i
                                          onClick={() => navigate(`/add-new-banner/${item?._id}`)}
                                          className="fas fa-edit"
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        ></i>
                                      </td>
                                      <td>
                                        {" "}
                                        <i
                                          onClick={() => popToggle(item._id)}
                                          className="fas fa-trash"
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        ></i>
                                      </td>
                                    </tr>
                                  )
                                })
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center"></div>
            <DeleteModal
              isOpen={popModal}
              toggle={() => setPopModal(!popModal)}
              onConfirmDelete={deleteItem}
            />
          </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}

Calender.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
}

const mapStateToProps = ({ calendar }) => ({
  events: calendar.events,
  categories: calendar.categories,
})

const mapDispatchToProps = dispatch => ({
  onGetEvents: () => dispatch(getEvents()),
  onGetCategories: () => dispatch(getCategories()),
  onAddNewEvent: event => dispatch(addNewEvent(event)),
  onUpdateEvent: event => dispatch(updateEvent(event)),
  onDeleteEvent: event => dispatch(deleteEvent(event)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Calender)
