import React, { useState,useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Button,
  Form,
  Input,
  Alert,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {createOption} from "common/Services/dbServices"
import { Link, useNavigate } from "react-router-dom"



const FormElements = () => {

  const navigate = useNavigate()
  const [code , setCode] = useState()
  const [label , setLabel] = useState()
  const [values , setValues] = useState()


  const onSubmit = async e => {
    e.preventDefault()
    try {
      const result = await createOption({code,label,values})
      console.log("submit::", result)
      alert("Item Added Successfully")
    //   navigate("/add-option")
    } catch (error) {
      alert("Failed to Add")
    //   navigate("/brand")
    }
  }


  document.title = "Add Options | Sayartak"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="Sayartak"
            title="Options"
            breadcrumbItem="Add Options"
            url="/Option"
          />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  {/* <CardTitle className="h4">Details</CardTitle> */}
                  <Form
                    style={{ marginTop: 10 }}
                    className="row g-3 needs-validation"

                  >
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Option Name
                      </label>
                      <div className="col-md-6">
                        <Input
                          name="name"
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Code"
                          value={code}
                          onChange={e=>setCode(e.target.value)}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                      Label
                      </label>
                      <div className="col-md-6">
                        <Input
                          name="slug"
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Label"
                          value={label}
                          onChange={e=>setLabel(e.target.value)}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                       Value
                      </label>
                      <div className="col-md-6">
                        <Input
                          name="value"
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Values"
                          value={values}
                          onChange={e=>setValues(e.target.value)}
                        />
                      </div>
                    </Row>
                   
                  </Form>
                </CardBody>
              </Card>
              <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary" onClick={onSubmit}>
                        Submit
                      </Button>{" "}
                      <Button type="reset" color="secondary" onClick={() => navigate("/Option")}>
                        Cancel
                      </Button>
                    </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormElements
