import React from "react";

const Loader = () =>{
    return (
        <div style={{display: 'flex', justifyContent:'center' , alignItems:'center' , height:'100%'}}>
        <div className="spinner-border" role="status"></div>
        <span className="sr-only">Loading ...
        </span>
        </div>

    )
}

export default Loader;