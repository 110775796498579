import React, { useState } from "react"
import { Tab, Tabs } from "react-bootstrap"

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Button,
  Form,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useLocation } from "react-router-dom"
import { IKImage } from "imagekitio-react"
import {
  urlEndpoint,
  authenticationEndpoint,
  publicKey,
} from "../../components/Common/imageKit"

const FormElements = () => {
  const [customchk, setcustomchk] = useState(true)
  const [customchkPrimary, setcustomchkPrimary] = useState(true)
  const [customchkSuccess, setcustomchkSuccess] = useState(true)
  const [customchkInfo, setcustomchkInfo] = useState(true)
  const [customchkWarning, setcustomchkWarning] = useState(true)
  const [customchkDanger, setcustomchkDanger] = useState(true)
  const [customOutlinePrimary, setcustomOutlinePrimary] = useState(true)
  const [customOutlineSuccess, setcustomOutlineSuccess] = useState(true)
  const [customOutlineInfo, setcustomOutlineInfo] = useState(true)
  const [customOutlineWarning, setcustomOutlineWarning] = useState(true)
  const [customOutlineDanger, setcustomOutlineDanger] = useState(true)
  const [toggleSwitch, settoggleSwitch] = useState(true)
  const [toggleSwitchSize, settoggleSwitchSize] = useState(true)
  const [tabKey, initTabKey] = useState("one")
  const location = useLocation()
  const data = location.state

  document.title = "Car Details | Sayartak"
  console.log("props", data)
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="Sayartak"
            title="Car"
            breadcrumbItem="Car Details"
            url="/car"
          />

          <Tabs activeKey={tabKey} onSelect={e => initTabKey(e)}>
            <Tab eventKey="one" title="Details">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                    <div className="row">
                    <div class="col-md-6">
                            <div class="details-section-item">
                              <label class="details-section-item-label">
                                Car
                              </label>
                              <div class="details-section-item-content">
                                <span>{data?.name?.en || "-"}</span>
                              </div>
                            </div>
                          </div>
                
                          <div class="col-md-6">
                            <div class="details-section-item">
                              <label class="details-section-item-label">
                                Brand Icon
                              </label>
                              <div class="details-section-item-content">
                                <span>
                                  {data && data?.images && data?.images?.url ? (
                                    <img
                                      src={data?.images?.url}
                                      height="30px"
                                      width="35px"
                                    ></img>
                                  ) : null}
                                </span>
                              </div>
                            </div>
                          </div>
                          </div>
                          <div className="row">
                          <div class="col-12">
                            <hr style={{width: "100%"}} />
                          </div>
                        </div>
                     
                        <div className="row">
                          <div class="col-sm-6">
                            <div class="details-section-item">
                              <label class="details-section-item-label">
                                Order No
                              </label>
                              <div class="details-section-item-content">
                                <span>{data?.order || "-"}</span>
                              </div>
                            </div>
                          </div>
                      
                   

                          <div class="col-sm-6">
                            <div class="details-section-item">
                              <label class="details-section-item-label">
                              Transmission Type :
                              </label>
                              <div class="details-section-item-content">
                              {data.availableTransmissionTypes.map(
                            (types, index) => {
                              return (
                                <span>{types?.label || "-"}</span>
                                )
                              }
                            )}
                              </div>
                            </div>
                          </div>
                     
                        </div>
                        <div className="row">
                          <div class="col-12">
                            <hr style={{width: "100%"}} />
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-sm-6">
                            <div class="details-section-item">
                              <label class="details-section-item-label">
                              Fuel Type :
                              </label>
                              <div class="details-section-item-content">
                              {data.availableFuelTypes.map(
                            (types, index) => {
                              return (
                                <span>{types?.label || "-"}</span>
                                )
                              }
                            )}
                              </div>
                            </div>
                          </div>
                        </div>
                    
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Tab>
            {/* <Tab eventKey="two" title="Demo">
              <p>Hellooo</p>
            </Tab> */}
          </Tabs>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormElements
