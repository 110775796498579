import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Button,
  Form,
  Input,
  Alert,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { editOptions, getOptions } from "common/Services/dbServices"
import { Link, useNavigate } from "react-router-dom"
import { useLocation, useParams } from "react-router-dom"
import { preventDefault } from "@fullcalendar/react"

const FormElements = () => {
  const { editId } = useParams()
  const location = useLocation()
  const data = location.state
  const navigate = useNavigate()
  // const [code , setCode] = useState()
  // const [label , setLabel] = useState(data.label)

  const [valuesData, setData] = useState()
  const [addOnValue, setAddOnValue] = useState()
  const [whatsAppValue, setWhatsAppValue] = useState()
  const [slotValue, setSlotValue] = useState()
  const [serviceCharge, setServiceCharge] = useState()
  const [batteryServiceCharge, setBatteryServiceCharge] = useState()

  useEffect(() => {
    getOptionsData()
  }, [])

  const getOptionsData = async () => {
    const response = await getOptions()
    response.map(v => {
      if (v.code === "addon") {
        setAddOnValue(v.value)
      }
      if (v.code === "WhatsApp") {
        setWhatsAppValue(v.value)
      }
      if (v.code === "slot") {
        setSlotValue(v.value)
      }
      if (v.code === "service-charge") {
        setServiceCharge(v.value)
      }
      if (v.code === "battery-service-charge") {
        setBatteryServiceCharge(v.value)
      }
    })
    setData(response)
    console.log("values ==", response)
  }

  document.title = "Update Options | Sayartak"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="Sayartak"
            title="Options"
            breadcrumbItem="Update Options"
            url="/Option"
          />
          <Row>
            <Col sm={6}>
              <Breadcrumbs title="Sayartak" breadcrumbItem="CMS" />
            </Col>
            <Col sm={6}>
              <div className="float-end d-none d-md-block">
                <Link
                  to="/add-options"
                  className="btn btn-secondary"
                  style={{ marginTop: 10 }}
                >
                  Add New
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  {/* <CardTitle className="h4">Details</CardTitle> */}
                  <Form
                    style={{ marginTop: 10 }}
                    className="row g-3 needs-validation"
                  >
                    <Row className="mb-3">
                      <Col>
                        <Row>
                          <label className="col-sm-2 col-form-label">
                            add On :
                          </label>
                          <div className="col-md-6">
                            <Input
                              name="value"
                              className="form-control form-control-lg"
                              type="text"
                              placeholder="Value"
                              value={addOnValue}
                              onChange={e => setAddOnValue(e.target.value)}
                            />
                          </div>
                          <div className="col-md-2">
                            <Button
                              type="submit"
                              color="primary"
                              onClick={async e => {
                                try {
                                  e.preventDefault()
                                  await editOptions({
                                    code: "addon",
                                    values: addOnValue,
                                  })
                                  alert("Option Updated Successfully")
                                } catch (e) {
                                  alert("Failed to Add")
                                }
                              }}
                            >
                              Update
                            </Button>{" "}
                          </div>
                        </Row>
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col>
                        <Row>
                          <label className="col-sm-2 col-form-label">
                            WhatsApp :
                          </label>
                          <div className="col-md-6">
                            <Input
                              name="value"
                              className="form-control form-control-lg"
                              type="text"
                              placeholder="Value"
                              value={whatsAppValue}
                              onChange={e => setWhatsAppValue(e.target.value)}
                            />
                          </div>
                          <div className="col-md-2">
                            <Button
                              type="submit"
                              color="primary"
                              onClick={async e => {
                                try {
                                  e.preventDefault()
                                  await editOptions({
                                    code: "WhatsApp",
                                    values: whatsAppValue,
                                  })
                                  alert("Option Updated Successfully")
                                } catch (e) {
                                  alert("Failed to Add")
                                }
                              }}
                            >
                              Update
                            </Button>{" "}
                          </div>
                        </Row>
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col>
                        <Row>
                          <label className="col-sm-2 col-form-label">
                            slot :
                          </label>
                          <div className="col-md-6">
                            <Input
                              name="value"
                              className="form-control form-control-lg"
                              type="text"
                              placeholder="Value"
                              value={slotValue}
                              onChange={e => setSlotValue(e.target.value)}
                            />
                          </div>
                          <div className="col-md-2">
                            <Button
                              type="submit"
                              color="primary"
                              onClick={async e => {
                                try {
                                  e.preventDefault()
                                  await editOptions({
                                    code: "slot",
                                    values: slotValue,
                                  })
                                  alert("Option Updated Successfully")
                                } catch (e) {
                                  alert("Failed to Add")
                                }
                              }}
                            >
                              Update
                            </Button>{" "}
                          </div>
                        </Row>
                      </Col>
                      {/* <Col>
               
                      </Col> */}
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <Row>
                          <label className="col-sm-2 col-form-label">
                            Service Charge :
                          </label>
                          <div className="col-md-6">
                            <Input
                              name="value"
                              className="form-control form-control-lg"
                              type="text"
                              placeholder="Value"
                              value={serviceCharge}
                              onChange={e => setServiceCharge(e.target.value)}
                            />
                          </div>
                          <div className="col-md-2">
                            <Button
                              type="submit"
                              color="primary"
                              onClick={async e => {
                                try {
                                  e.preventDefault()
                                  await editOptions({
                                    code: "service-charge",
                                    values: serviceCharge,
                                  })
                                  alert("Option Updated Successfully")
                                } catch (e) {
                                  alert("Failed to Add")
                                }
                              }}
                            >
                              Update
                            </Button>{" "}
                          </div>
                        </Row>
                      </Col>
                      {/* <Col>
               
                      </Col> */}
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <Row>
                          <label className="col-sm-2 col-form-label">
                            Battery Service Charge :
                          </label>
                          <div className="col-md-6">
                            <Input
                              name="value"
                              className="form-control form-control-lg"
                              type="text"
                              placeholder="Value"
                              value={batteryServiceCharge}
                              onChange={e =>
                                setBatteryServiceCharge(e.target.value)
                              }
                            />
                          </div>
                          <div className="col-md-2">
                            <Button
                              type="submit"
                              color="primary"
                              onClick={async e => {
                                try {
                                  e.preventDefault()
                                  await editOptions({
                                    code: "battery-service-charge",
                                    values: batteryServiceCharge,
                                  })
                                  alert("Option Updated Successfully")
                                } catch (e) {
                                  alert("Failed to Add")
                                }
                              }}
                            >
                              Update
                            </Button>{" "}
                          </div>
                        </Row>
                      </Col>
                      {/* <Col>
               
                      </Col> */}
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormElements
