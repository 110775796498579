import PropTypes from 'prop-types';
import React, { useEffect } from "react";
import { Routes, Route } from 'react-router-dom';
import { connect } from "react-redux";
import { UserProvider } from 'Context/userContext';

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import * as Realm from "realm-web"
import { app } from "Context/userContext"


const App = () => {

  useEffect(async() => {
    if(app?.currentUser?.customData == null){
      const credentials = Realm.Credentials.anonymous();
      const userLogin = await app.logIn(credentials);
      console.log('userLogin=',userLogin)
    }
  },[])


  return (
    <React.Fragment>
     <UserProvider>
      <Routes>
      
        <Route>
        
          {authRoutes.map((route, idx) => (
            
            <Route
              path={route.path}
              element={
                <NonAuthLayout>
                  {route.component}
                </NonAuthLayout>
              }
              key={idx}
              exact={true}
            />
          ))}
     
        </Route>

       
        <Route>
          {userRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <Authmiddleware>
                  {route.component}
                </Authmiddleware>}
              key={idx}
              exact={true}
            />
          ))}
        </Route>
       
      </Routes>

      </UserProvider>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
