import React from "react"
// Pages Calendar
import Calendar from "../pages/Calendar/index"

//Banner
import Banner from "../pages/Banner/index"
import AddNewBanner from "../pages/Banner/add-new-banner"

//Brands
import Brand from "../pages/Brand/Index"
import AddNewBrand from "../pages/Brand/add-new-brand"
import ItemDetails from "../pages/Brand/item-details"

// Slots
import Slot from "../pages/Slots/Index"
import AddNewSlot from "../pages/Slots/add-new-slot"
import SlotDetails from "../pages/Slots/item-details"
import BlockSlot from "../pages/Slots/block-slot"

//cars
import Car from "../pages/Cars/Index"
import AddNewCar from "../pages/Cars/add-new-car"
import CarDetails from "../pages/Cars/item-details"

//city
import City from "../pages/City/Index"
import AddNewCity from "../pages/City/add-new-city"
import CityDetails from "../pages/City/item-details"

//service
import ServiceCategories from "../pages/ServiceCategories/Index"
import AddNewServiceCat from "../pages/ServiceCategories/add-new-serviceCat"
import ServiceCatDetails from "../pages/ServiceCategories/serviceCat-details"

import Services from "../pages/Services/Index"
import AddNewService from "../pages/Services/add-new-service"
import ServiceDetails from "../pages/Services/service-details"

import ServiceBrands from "../pages/ServiceBrands/Index"
import AddNewServiceBrand from "../pages/ServiceBrands/add-new-service-brand"
import ServiceBrandDetails from "../pages/ServiceBrands/service-brand-details"

import ServiceTypes from "../pages/ServiceTypes/Index"
import AddNewServiceType from "../pages/ServiceTypes/add-new-service-type"
import ServiceTypeDetails from "../pages/ServiceTypes/service-type-details"

//Technicians
import Technicians from "../pages/Technicians/index"
import AddNewTec from "../pages/Technicians/add-new"

//Bundle Service
import BundleService from "../pages/BundleService/index"
import AddNewBundleService from "../pages/BundleService/add-new-bundle"
import BundleServiceDetails from "../pages/BundleService/bundle-service-details"
//User
import User from "../pages/User/index"
import AddNewUser from "../pages/User/add-new"

//customer
import Customer from "../pages/Customer"
//CMS
import CMS from "../pages/CMS/Index"
import AddNewCMS from "../pages/CMS/add-new-cms"
import ViewCMS from "../pages/CMS/view-cms"
import EditCMS from "../pages/CMS/edit-cms"

//Orders
import Orders from "../pages/Orders/index"
import EditOrder from "../pages/Orders/edit-order"
import Chat from "../pages/Orders/chat"

//Options
import Options from "../pages/Options/Index"
import AddOptions from "../pages/Options/add-options"

//Coupon Code
import Coupon from "../pages/CouponCode/index"
import AddCoupon from "../pages/CouponCode/add-coupon"
import CouponDetails from "../pages/CouponCode/coupon-details"

//Report
import DailyReport from "../pages/Report/dailySalesReport"
import MonthlyReport from "../pages/Report/monthlySalesReport"
import AnnualReport from "../pages/Report/annualSalesReport"
import ByModelReport from "../pages/Report/SalesByModelReport"
import ByTechnicianReport from "../pages/Report/SalesByTechnicianReport"
import DateWiseReport from "../pages/Report/dateWiseSalesReport"

//Live-tracking
import LiveTracking from "../pages/LiveTracking/index"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ResetPassword from "../pages/Authentication/ResetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle"
import MapsVector from "../pages/Maps/MapsVector"
import MapsLeaflet from "../pages/Maps/MapsLeaflet"

//Push Notification
import Push from "../pages/PushNotification/index"
import PushNotification from "../pages/PushNotification/new-pushNotification"
import { components } from "react-select"

const userRoutes = [
  { path: "/dashboard", component: <Dashboard /> },

  // //calendar
  { path: "/calendar", component: <Calendar /> },

  //banner
  { path: "/banner", component: <Banner /> },
  { path: "/add-new-banner", component: <AddNewBanner /> },
  { path: "/add-new-banner/:id", component: <AddNewBanner /> },

  // //brand
  { path: "/brand", component: <Brand /> },
  { path: "/add-new-brand", component: <AddNewBrand /> },
  { path: "/add-new-brand/:id", component: <AddNewBrand /> },
  { path: "/item-details", component: <ItemDetails /> },

  // slot
  { path: "/slot", component: <Slot /> },
  { path: "/add-new-slot", component: <AddNewSlot /> },
  { path: "/slot-details", component: <SlotDetails /> },
  { path: "/slot-details/:id", component: <SlotDetails /> },
  { path: "/block-slot", component: <BlockSlot /> },

  // //car
  { path: "/car", component: <Car /> },
  { path: "/add-new-car", component: <AddNewCar /> },
  { path: "/add-new-car/:id", component: <AddNewCar /> },
  { path: "/car-details", component: <CarDetails /> },

  // //city
  { path: "/city", component: <City /> },
  { path: "/add-new-city", component: <AddNewCity /> },
  { path: "/city-details", component: <CityDetails /> },

  // //service
  { path: "/service-categories", component: <ServiceCategories /> },
  { path: "/add-new-service-cat", component: <AddNewServiceCat /> },
  { path: "/service-cat-details", component: <ServiceCatDetails /> },

  { path: "/service", component: <Services /> },
  { path: "/add-new-service", component: <AddNewService /> },
  { path: "/add-new-service/:id", component: <AddNewService /> },
  { path: "/service-details", component: <ServiceDetails /> },

  { path: "/service-brands", component: <ServiceBrands /> },
  { path: "/add-new-service-brand", component: <AddNewServiceBrand /> },
  { path: "/service-brand-details", component: <ServiceBrandDetails /> },

  { path: "/service-types", component: <ServiceTypes /> },
  { path: "/add-new-service-type", component: <AddNewServiceType /> },
  { path: "/service-type-details", component: <ServiceTypeDetails /> },

  // //Technicians
  { path: "/technicians", component: <Technicians /> },
  { path: "/add-new", component: <AddNewTec /> },

  // //Bundle Service
  { path: "/bundleService", component: <BundleService /> },
  { path: "/add-new-bundle", component: <AddNewBundleService /> },
  { path: "/edit-new-bundle/:id", component: <AddNewBundleService /> },
  { path: "/bundle-service-details", component: <BundleServiceDetails /> },

  // //User
  { path: "/user", component: <User /> },
  { path: "/add-new-user", component: <AddNewUser /> },

  // //customer
  { path: "/customer", component: <Customer /> },

  // //CMSEditOrder
  { path: "/cms", component: <CMS /> },
  { path: "/add-new-cms", component: <AddNewCMS /> },
  { path: "/view-cms/:editId", component: <ViewCMS /> },
  { path: "/edit-cms/:editId", component: <EditCMS /> },

  // //Orders
  { path: "/orders", component: <Orders /> },
  { path: "/edit-order/:editId", component: <EditOrder /> },
  { path: "/chat/:id", component: <Chat /> },

  // //Options
  { path: "/options", component: <Options /> },
  { path: "/add-options", component: <AddOptions /> },

  //CouponCode
  { path: "/coupon", component: <Coupon /> },
  { path: "/add-coupon", component: <AddCoupon /> },
  { path: "/add-coupon/:id", component: <AddCoupon /> },
  { path: "/coupon-details/:id", component: <CouponDetails /> },

  // //Reports
  { path: "/daily-report", component: <DailyReport /> },
  { path: "/monthly-report", component: <MonthlyReport /> },
  { path: "/annual-report", component: <AnnualReport /> },
  { path: "/model-report", component: <ByModelReport /> },
  { path: "/technician-report", component: <ByTechnicianReport /> },
  { path: "/date-wise-report", component: <DateWiseReport /> },

  // //live-tracking
  { path: "/live-tracking", component: <LiveTracking /> },

  // Maps
  { path: "/maps-google", component: <MapsGoogle /> },
  { path: "/maps-vector", component: <MapsVector /> },
  { path: "/maps-leaflet", component: <MapsLeaflet /> },

  //Push Notification
  { path: "/Push", component: <Push /> },
  { path: "/new-pushNotification", component: <PushNotification /> },
  // this route should be at the end of all other routes
  { path: "/", component: <Dashboard /> },
]

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/reset-password/:token", component: <ResetPassword /> },
  { path: "/register", component: <Register /> },
]

export { userRoutes, authRoutes }
