import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  addNewEvent,
  deleteEvent,
  getCategories,
  getEvents,
  updateEvent,
} from "../../store/actions"
// import DeleteModal from "./DeleteModal";
//css
import "@fullcalendar/bootstrap/main.css"
import {
  getAnnualSales,
  rapidApiCall,
  getAnnualSalesReport,
} from "common/Services/dbServices"
import axios from "axios"
import moment from "moment"
import Loader from "components/Common/Loader"
import { useAnnualSalesReport } from "common/Hooks/queryCalls"
const Calender = props => {
  // const [AnnualSales, setAnnualSales] = useState()
  // const [currentYear, setCurrentYear] = useState(moment().format("yyyy"))
  const [year, setYear] = useState(moment().format("yyyy"))
  const { data, isLoading, refetch } = useAnnualSalesReport({ year })
  const [pdfLoading, setPdfLoading] = useState(false)
  // console.log("annualSales =", data)
  // const [itemTotal, setItemTotal] = useState(0)
  // const [totalNumberOfServices, setTotalNumberOfServices] = useState(0)
  // const [totalNumberOfVehiclesInAnnual, setTotalNumberOfVehiclesInAnnual] =
  //   useState(0)

  // useEffect(() => {
  //   annualSalesReport()
  // }, [year])

  // const annualSalesReport = async () => {
  //   const startOfYear = moment(year).startOf("year").toDate()
  //   const endOfYear = moment(year).endOf("year").toDate()
  //   const result = await getAnnualSales(startOfYear, endOfYear)
  //   // console.log("AnnualSalesReport =", result)

  //   if (result) {
  //     setAnnualSales(result)
  //     let total = 0
  //     let totalServices = 0
  //     let totalvehicles = result.length

  //     result.map(item => {
  //       const totalPayment = item.payment.reduce((total, item) => {
  //         return total + item.amountPayable
  //       }, 0)
  //       total = totalPayment + total
  //       totalServices = totalServices + item?.service.length
  //       if (item?.additionalService) {
  //         totalServices = totalServices + item?.additionalService?.length
  //       }
  //       //console.log("welcome=",item?.refNumber, item?.additionalService?.length)
  //     })

  //     setItemTotal(total)
  //     setTotalNumberOfServices(totalServices)
  //     setTotalNumberOfVehiclesInAnnual(totalvehicles)
  //   }
  // }

  const handleDownloadAWb = async () => {
    setPdfLoading(true)
    refetch()
      .then(async () => {
        const options = {
          method: "POST",
          url: "https://yakpdf.p.rapidapi.com/pdf",
          headers: {
            "content-type": "application/json",
            "X-RapidAPI-Key":
              "4e9d11b819msh21aebbab3801f53p12727ajsn88049d70267a",
            "X-RapidAPI-Host": "yakpdf.p.rapidapi.com",
          },
          responseType: "arraybuffer",
          data: {
            source: {
              html: data?.rapidApiData,
            },
            pdf: {
              format: "A4",
              scale: 1,
              printBackground: true,
            },
          },
        }

        try {
          const response = await axios.request(options)
          console.log("api response ==", response)
          const blob = new Blob([response.data], { type: "application/pdf" })
          var file = window.URL.createObjectURL(blob)
          var a = document.createElement("a")
          a.href = file
          a.download = "AnnualSalesReport"
          document.body.appendChild(a)
          a.click()
          window.onfocus = function () {
            // document.body.removeChild(a)
            a.remove()
          }
          setPdfLoading(false)
        } catch (error) {
          console.error(error)
          setPdfLoading(false)
        }
      })
      .catch(error => {
        console.log("error", error)
        setPdfLoading(false)
      })
  }

  document.title = "Annual Sales Report | Sayartak "
  return (
    <React.Fragment>
      {/* <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      /> */}
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <Row>
                <Col>
                  <Breadcrumbs
                    title="Sayartak"
                    breadcrumbItem="Annual Sales Report"
                  />
                </Col>
                <Col sm={6}>
                  <div className="float-end d-none d-md-block pt-5 ">
                    {pdfLoading ? (
                      <Loader />
                    ) : (
                      <Button onClick={() => handleDownloadAWb()}>
                        Download PDF
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="col-12">
                  <Row>
                    <Col>
                      <Row>
                        <Col xl={4}>
                          <Card>
                            <CardBody>
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0 me-3">
                                  <div className="avatar-xs">
                                    <div className="avatar-title rounded-circle bg-primary bg-gradient fs-4">
                                      <i className="mdi mdi-chart-pie"></i>
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <p className="text-muted mb-1">
                                    Annual Total Revenue
                                  </p>
                                  <h5 className="mb-0">
                                    JOD {data?.itemTotal}
                                  </h5>
                                </div>

                                <div className="flex-shrink-0 align-self-end ms-2">
                                  <div className="badge rounded-pill font-size-13 badge-soft-success">
                                    {/* + 2.65% */}
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col xl={4}>
                          <Card>
                            <CardBody>
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0 me-3">
                                  <div className="avatar-xs">
                                    <div className="avatar-title rounded-circle bg-primary bg-gradient fs-4">
                                      <i className="mdi mdi-chart-pie"></i>
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <p className="text-muted mb-1">
                                    Total no. of Services
                                  </p>
                                  <h5 className="mb-0">
                                    {data?.totalNumberOfServices}
                                  </h5>
                                </div>

                                <div className="flex-shrink-0 align-self-end ms-2">
                                  <div className="badge rounded-pill font-size-13 badge-soft-success">
                                    {/* + 2.65% */}
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col xl={4}>
                          <Card>
                            <CardBody>
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0 me-3">
                                  <div className="avatar-xs">
                                    <div className="avatar-title rounded-circle bg-primary bg-gradient fs-4">
                                      <i className="mdi mdi-chart-pie"></i>
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <p className="text-muted mb-1">
                                    Total no. of Vehicles serviced in a month
                                  </p>
                                  <h5 className="mb-0">
                                    {data?.totalNumberOfVehiclesInAnnual}
                                  </h5>
                                </div>

                                <div className="flex-shrink-0 align-self-end ms-2">
                                  <div className="badge rounded-pill font-size-13 badge-soft-success">
                                    {/* + 2.65% */}
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      <Card>
                        <CardBody>
                          <div id="external-events" className="mt-12">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <h4 className="card-title mb-4">
                                Annual Sales Report
                              </h4>
                              <Button>
                                <Input
                                  name="month"
                                  type="number"
                                  min="1900"
                                  max={year}
                                  value={year}
                                  onChange={e => {
                                    setYear(e.target.value)
                                    setTimeout(() => {
                                      refetch()
                                    }, 150)
                                  }}
                                />
                              </Button>
                            </div>
                            <div className="table-responsive">
                              <table className="table table-hover table-centered table-nowrap mb-0">
                                <thead className="table-light">
                                  <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Order No</th>
                                    <th scope="col">Customer Name</th>
                                    <th scope="col">Technician ID</th>
                                    <th scope="col">No. Of Services</th>
                                    <th scope="col">Total Revenue</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {data?.AnnualSales?.length > 0 ? (
                                    data?.AnnualSales?.map((item, index) => {
                                      return (
                                        <tr>
                                          <td>
                                            {moment(item.orderPlacedOn).format(
                                              "Do MMMM YYYY"
                                            )}
                                          </td>
                                          <td>{item?.refNumber}</td>
                                          <td>{item?.getCustomer?.name}</td>
                                          <td>
                                            {item?.getTechnician?.employeeId}
                                          </td>
                                          {item?.additionalService ? (
                                            <td>
                                              {item?.service.length +
                                                item?.additionalService?.length}
                                            </td>
                                          ) : (
                                            <td>{item?.service.length}</td>
                                          )}
                                          <td>
                                            {item?.payment.reduce(
                                              (total, item) => {
                                                return (
                                                  total + item?.amountPayable
                                                )
                                              },
                                              0
                                            )}
                                          </td>
                                        </tr>
                                      )
                                    })
                                  ) : (
                                    <td>No records</td>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

Calender.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
}

const mapStateToProps = ({ calendar }) => ({
  events: calendar.events,
  categories: calendar.categories,
})

const mapDispatchToProps = dispatch => ({
  onGetEvents: () => dispatch(getEvents()),
  onGetCategories: () => dispatch(getCategories()),
  onAddNewEvent: event => dispatch(addNewEvent(event)),
  onUpdateEvent: event => dispatch(updateEvent(event)),
  onDeleteEvent: event => dispatch(deleteEvent(event)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Calender)
