import PropTypes from 'prop-types';
import React, { useState, useEffect,useContext } from "react";

import { Row, Col, CardBody, Card, Container, Label, Form, FormFeedback, Input } from "reactstrap";

// Redux
import { connect, useSelector, useDispatch } from "react-redux";
import { Link ,useNavigate} from 'react-router-dom';
import withRouter from 'components/Common/withRouter';

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { loginUser, apiError } from "../../store/actions";

// import images
import logoSm from "../../assets/images/logo-blue.png";
import { UserContext ,app} from 'Context/userContext';
import { getUser } from "common/Services/dbServices";
import bcrypt from 'bcryptjs';
import * as Realm from "realm-web"

const Login = props => {

 const navigate =useNavigate()
  const {fetchUser,emailPasswordLogin,user } = useContext(UserContext);

  const [form, setForm] = useState({
    email: "",
    password: ""
  });

  const onFormInputChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };
  const redirectNow = () => {
    console.log('welcome')
    const redirectTo = location.search.replace("?redirectTo=", "");
    navigate(redirectTo ? redirectTo : "/");
  }



  const loadUser = async () => {
    if (!user) {
      const fetchedUser = await fetchUser();
      console.log(fetchedUser)
      if (fetchedUser) {
        redirectNow();
      }
    }
  }

  const onSubmit = async (event) => {
    try {
    const email = form.email;
    const password = form.password;
    console.log('email=',email)
    console.log('password',password)

    const result = await getUser(email);

    if (result) {
      const storedHash = result.password; // Replace with the stored hash

      bcrypt.compare(password, storedHash, async function (err, results) {
        if (results) {
           console.log('Password is correct!');
          try {
            const credentials = Realm.Credentials.function({
              loginFunction: 'adminLogin',
              email: email,
            });
            console.log('credentials=', credentials);
            await app.logIn(credentials);
            if (app.currentUser) {
              localStorage.setItem("authUser",JSON.stringify(app.currentUser));
              redirectNow();
            }
            // console.log("customData=",app.currentUser.customData)
            // redirectNow();
          } catch (err) {
            console.log('error', err);  
          }
        } else {
          alert('Password is incorrect!')
          console.log('Password is incorrect!');
        }
      });
    }
    else {
      // Toast.showWithGravity(
      //   'email is incorrect!',
      //   Toast.LONG,
      //   Toast.BOTTOM,
      // );
      alert('email is incorrect!')
      console.log('email is incorrect!');
    }

    } catch (error) {
      alert(error)
    }
  };

 
  // const onSubmit = async (event) => {
  //   try {
     
  //     const user = await emailPasswordLogin(form.email, form.password);
  //     if (app.currentUser) {
  //       localStorage.setItem("authUser",JSON.stringify(app.currentUser));
  //       redirectNow();
  //     }
  //   } catch (error) {
  //     alert(error)
  //   }
  // };

  useEffect(() => {
    loadUser();
  }, []);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: form.email,
      password: form.password,
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your User Name"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
  });

  document.title = "Login | Sayartak";
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">
                      Welcome !
                    </h5>
                    <p className="text-white-50">
                      Sign in to continue to Sayartak.
                    </p>
                    <Link to="/" className="logo logo-admin">
                      <img src={logoSm} height="24" alt="logo" />
                    </Link>
                  </div>
                </div>

                <CardBody className="p-4">
                  <div className="p-3">
                    <Form className="mt-4"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                      action="#">

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="username">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter Username"
                          type="email"
                          id="username"
                          onChange={onFormInputChange}
                          onBlur={validation.handleBlur}
                          value={form.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="userpassword">Password</Label>
                        <Input
                          name="password"
                          value={form.password || ""}
                          type="password"
                          className="form-control"
                          placeholder="Enter Password"
                          onChange={onFormInputChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3 row">
                        <div className="col-sm-6">
                          <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="customControlInline" />
                            <label className="form-check-label" htmlFor="customControlInline">Remember me</label>
                          </div>
                        </div>
                        <div className="col-sm-6 text-end">
                          <button className="btn btn-primary w-md waves-effect waves-light" type="submit"   onClick={onSubmit}>Log In</button>
                        </div>
                      </div>

                      <div className="mt-2 mb-0 row">
                        <div className="col-12 mt-4">
                          <Link to="/forgot-password"><i className="mdi mdi-lock"></i> Forgot your password?</Link>
                        </div>
                      </div>

                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                {/* <p>
                  Don&#39;t have an account ?{" "}
                  <Link
                    to="/register"
                    className="fw-medium text-primary"
                  >
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p> */}
                <p>
                  © {new Date().getFullYear()} Sayartak.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Login)
);

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
};