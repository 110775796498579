import React, { useEffect, useState } from "react"
// import Select from "react-select"
import { BSON } from "realm-web"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { isEmpty } from "lodash"
import { Draggable } from "react-beautiful-dnd"
import { useFormik } from "formik"
import * as Yup from "yup"
// import { IKUpload, IKContext } from "imagekitio-react"
// import Switch from "react-switch"
import {
  getServicesList,
  getDeletedServices,
  editServices,
  getServiceCategory,
  getServiceTypeData,
  getServiceBrandData,
  getVehicleCollection,
  getCarTypeData,
  getDeletedBundleService,
  ObjectId,
} from "common/Services/dbServices"
import { IKImage } from "imagekitio-react"
import logosmImg from "../../assets/images/logo.png"
// import { AiOutlineCloseCircle } from "react-icons/ai"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Input,
} from "reactstrap"

import { Link, useNavigate } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  addNewEvent,
  deleteEvent,
  getCategories,
  getEvents,
  updateEvent,
} from "../../store/actions"
// import DeleteModal from "./DeleteModal";
//css
import {
  urlEndpoint,
  authenticationEndpoint,
  publicKey,
} from "../../components/Common/imageKit"
import "@fullcalendar/bootstrap/main.css"
import Loader from "components/Common/Loader"
import ReactPaginate from "react-paginate"
import { useBundleServices } from "common/Hooks/queryCalls"
const fuelData = [
  { value: "Petrol", label: "Petrol" },
  { value: "Diesel", label: "Diesel" },
  { value: "Hybrid", label: "Hybrid" },
  { value: "Electric", label: "Electric" },
]
const Calender = props => {
  const { events, categories } = props
  const [modal, setModal] = useState(false)
  const [popModal, setPopModal] = useState(false)
  const [modalcategory, setModalcategory] = useState(false)
  const [event, setEvent] = useState({})
  const [selectedDay, setSelectedDay] = useState(0)
  const [isEdit, setIsEdit] = useState(false)
  const navigate = useNavigate()
  const inputRef = React.useRef(null)
  const [serviceData, setServiceData] = useState()
  const [relatedServiceData, setRelatedServiceData] = useState()
  const [serviceCategoryData, setServiceCategoryData] = useState()
  const [serviceBrandData, setServiceBrandData] = useState()
  const [serviceTypeData, setServiceTypeData] = useState()
  const [id, setId] = useState()
  const [deletedItem, setDeletedItem] = useState()
  const [editServiceCategoryId, setEditServiceCategoryId] = useState()
  const [editServiceNameEn, setEditServiceNameEn] = useState()
  const [editisServiceActive, setEditServiceActive] = useState(false)
  const [editServiceNameAr, setEditServiceNameAr] = useState()
  const [technicalName, setTechnicalName] = useState()
  const [res, setRes] = useState()
  const [editServicePrice, setEditServicePrice] = useState()
  const [serviceDescriptionEn, setServiceDescriptionEn] = useState()
  const [serviceDescriptionAr, setServiceDescriptionAr] = useState()
  const [editServiceSale, setEditServiceSale] = useState()
  const [editId, setEditId] = useState()
  const [editedServices, setEditedServices] = useState()
  const [editServiceBrandType, setEditServiceBrandType] = useState()
  // const [serviceBrandData, setServiceBrandData] = useState()
  const [selectRelatedServices, setRelatedServices] = useState()
  const [selectFuelType, setFuelType] = useState()
  const [selectCarServices, setCarServices] = useState()
  const [carList, setCarList] = useState()
  const [isLoading, setLoading] = useState(false)
  const [carType, setCarType] = useState()
  const [isMultiple, setIsMultiple] = useState(false)

  const [pageNumber, setPageNumber] = useState(0) // Current page number
  const servicesPerPage = 10 // Number of customers to display per page
  const {
    data: bundleServiceData,
    isLoading: bundleServiceIsloading,
    refetch: bundleServiceRefetch,
  } = useBundleServices()

  useEffect(() => {
    getServiceDataList()
    getCars()
  }, [deletedItem, editedServices])

  useEffect(() => {
    serviceCategoryList()
    serviceTypeList()
    serviceBrandList()
    fetchCarType()
  }, [])

  const dispatch = useDispatch()

  // events validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (event && event.title) || "",
      category: (event && event.category) || "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Your Event Name"),
      category: Yup.string().required("Please Select Your Category"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updatedEvent = {
          id: event.id,
          title: values.title,
          classNames: values.category + " text-white",
          start: event.start,
        }
        // update event
        dispatch(updateEvent(updatedEvent))
        validation.resetForm()
      } else {
        const newEvent = {
          id: Math.floor(Math.random() * 100),
          title: values["title"],
          start: selectedDay ? selectedDay.date : new Date(),
          className: values.category + " text-white",
        }
        // save new event
        dispatch(addNewEvent(newEvent))
        validation.resetForm()
      }
      setSelectedDay(null)
      toggle()
      popToggle()
    },
  })

  useEffect(() => {
    const { onGetCategories, onGetEvents } = props
    onGetCategories()
    onGetEvents()
    new Draggable(document.getElementById("external-events"), {
      itemSelector: ".external-event",
    })
  }, [])

  useEffect(() => {
    if (!modal && !isEmpty(event) && !!isEdit) {
      setTimeout(() => {
        setEvent({})
        setIsEdit(false)
      }, 500)
    }
  }, [modal, event])



  /**
   * Handling the modal state
   */
  const toggle = result => {
    // console.log("results ===  === ", result)
    setRes(result?.images)
    setEditId(result?._id)
    setEditServiceCategoryId(result?.serviceTypeId)
    setEditServiceNameEn(result?.name?.en)
    setEditServiceNameAr(result?.name?.ar)
    setTechnicalName(result?.technicalName)
    setEditServicePrice(result?.price)
    setServiceDescriptionEn(result?.description?.en)
    setServiceDescriptionAr(result?.description?.ar)
    setEditServiceSale(result?.salePrice)
    setEditServiceBrandType(result?.serviceBrandType)
    setEditServiceActive(result?.active)
    setIsMultiple(result?.isMutiple)

    // setServiceBrandTypePrices(editServiceBrandType?.map((item) => item?.serviceBrandTypePrice));

    setModal(!modal)
    setRelatedServices(
      relatedServiceData?.filter(item =>
        result?.relatedServices?.find(
          v => v.toString() === item.value.toString()
        )
      )
    )
    setFuelType(
      fuelData?.filter(item =>
        result?.serviceFuelTypes?.find(
          v => v.toString() === item.value.toString()
        )
      )
    )
    setCarServices(
      carList?.filter(item =>
        result?.selectedVehicles?.find(
          v => v.toString() === item.value.toString()
        )
      )
    )
  }

  const fetchCarType = async () => {
    try {
      const response = await getCarTypeData()
      if (response) {
        let CarType = []
        response.map((item, index) => {
          let obj = {
            value: item._id.toString(),
            label: item.name.en,
          }
          CarType.push(obj)
        })
        setCarType(CarType)
      }
    } catch (e) {
      // console.log("Error,", e)
    }
  }
  const popToggle = res => {
    setId(res)
    setPopModal(!popModal)
  }

  const deleteItemData = async () => {
    const getDeleted = await getDeletedBundleService({ id })
    setDeletedItem(getDeleted)
    bundleServiceRefetch()
    setPopModal(!popModal)
  }
  // const navi = () => {
  //   navigate("/service-details")
  // }
  const toggleCategory = () => {
    setModalcategory(!modalcategory)
  }

  const getCars = async () => {
    try {
      const response = await getVehicleCollection()
      // console.log("response ==",response)
      if (response) {
        let Cars = []
        response.map((item, index) => {
          let obj1 = {
            value: item._id.toString(),
            label: item.name.en,
            // carType: JSON.stringify(item.carType),
            carType: `${item.carType}`,
          }

          Cars.push(obj1)
        })
        setCarList(Cars)
      }
    } catch (e) {
      // console.log("Error=", e)
    }
  }

  const getServiceDataList = async () => {
    try {
      setLoading(true)
      const createServicesList = await getServicesList()
      setServiceData(createServicesList)
      let relatedServices = []
      createServicesList.map((item, index) => {
        let obj = {
          value: item._id,
          label: item.name.en,
        }
        relatedServices.push(obj)
      })

      setRelatedServiceData(relatedServices)
    } catch (e) {
      setLoading(false)
      alert("Sorry,Something went wrong")
    }
    setLoading(false)
  }

  const serviceCategoryList = async () => {
    const result = await getServiceCategory()

    if (result) {
      setServiceCategoryData(result)
    }
  }

  const serviceTypeList = async () => {
    const result = await getServiceTypeData()

    if (result) {
      setServiceTypeData(result)
    }
    // console.log(result)
  }

  const serviceBrandList = async () => {
    const result = await getServiceBrandData()

    if (result) {
      setServiceBrandData(result)
    }
    // console.log(result)
  }

  const pageCount = Math.ceil(serviceData?.length / servicesPerPage)

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected)
  }

  const displayedServices = serviceData?.slice(
    pageNumber * servicesPerPage,
    (pageNumber + 1) * servicesPerPage
  )

  const displayedBundleServices = bundleServiceData?.slice(
    pageNumber * servicesPerPage,
    (pageNumber + 1) * servicesPerPage
  )

  document.title = "Services | Sayartak "
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Row>
            <Col sm={6}>
              <Breadcrumbs title="Sayartak" breadcrumbItem="Services" />
            </Col>
            <Col sm={6}>
              <div className="float-end d-none d-md-block">
                <Link
                  to="/add-new-bundle"
                  className="btn btn-secondary"
                  style={{ marginTop: 10 }}
                >
                  Add New
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <div id="external-events" className="mt-12">
                        <h4 className="card-title mb-4">List of Services</h4>
                        <div className="table-responsive">
                          <table className="table table-hover table-centered table-nowrap mb-0">
                            <thead className="table-light">
                              <tr>
                                {/* <th scope="col">(#) Id</th> */}

                                <th scope="col">Name En</th>
                                <th scope="col">Name Ar</th>
                                <th scope="col">Technical Name</th>
                                <th scope="col">Price</th>
                                <th scope="col">Description</th>
                                <th scope="col">Image</th>
                                <th scope="col">SalePrice</th>
                                <th scope="col" colSpan={3}>
                                  Action
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {bundleServiceIsloading ? (
                                <tr>
                                  <td colSpan="8">
                                    <Loader />
                                  </td>
                                </tr>
                              ) : (
                                displayedBundleServices?.map((item, index) => {
                                  // console.log('test data ==',item.description)
                                  return (
                                    <tr>
                                      <td
                                        onClick={() =>
                                          navigate("/bundle-service-details", {
                                            state: item,
                                          })
                                        }
                                      >
                                        {item.name.en == null
                                          ? "Empty Value"
                                          : item.name.en}
                                      </td>
                                      <td
                                        onClick={() =>
                                          navigate("/bundle-service-details", {
                                            state: item,
                                          })
                                        }
                                      >
                                        {item.name.ar == null
                                          ? "Empty Value"
                                          : item.name.ar}
                                      </td>
                                      <td
                                        onClick={() =>
                                          navigate("/bundle-service-details", {
                                            state: item,
                                          })
                                        }
                                      >
                                        {item?.technicalName == null
                                          ? "Empty Value"
                                          : item?.technicalName}
                                      </td>
                                      <td
                                        onClick={() =>
                                          navigate("/bundle-service-details", {
                                            state: item,
                                          })
                                        }
                                      >
                                        {item.price == null
                                          ? "Empty Value"
                                          : item.price}
                                      </td>
                                      <td
                                        onClick={() =>
                                          navigate("/bundle-service-details", {
                                            state: item,
                                          })
                                        }
                                      >
                                        {item &&
                                          item?.description &&
                                          item.description?.en
                                          ? item.description?.en.substring(
                                            0,
                                            15
                                          ) || "-"
                                          : null}
                                      </td>

                                      <td
                                        onClick={() =>
                                          navigate("/bundle-service-details", {
                                            state: item,
                                          })
                                        }
                                      >
                                        <div>
                                          {item && item.images ? (
                                            <IKImage
                                              urlEndpoint={urlEndpoint}
                                              path={
                                                item.images.filePath == null
                                                  ? "No Image"
                                                  : item.images.filePath
                                              }
                                              width="35px"
                                              height="30px"
                                            />
                                          ) : (
                                            <img
                                              src={logosmImg}
                                              alt=""
                                              className="img-thumbnail-sm"
                                              width="35px"
                                              height="35px"
                                            />
                                          )}
                                        </div>
                                      </td>
                                      <td
                                        onClick={() =>
                                          navigate("/bundle-service-details", {
                                            state: item,
                                          })
                                        }
                                      >
                                        {item.salePrice == null
                                          ? "Empty Price"
                                          : item.salePrice}
                                      </td>
                                      <td>
                                        {" "}
                                        <i
                                          onClick={() =>
                                            navigate(
                                              "/bundle-service-details",
                                              {
                                                state: item,
                                              }
                                            )
                                          }
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          className="fas fa-eye"
                                        ></i>
                                      </td>
                                      <td>
                                        {" "}
                                        <i
                                          onClick={() =>
                                            navigate(
                                              `/edit-new-bundle/${item?._id}`
                                            )
                                          }
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          className="fas fa-edit"
                                        ></i>
                                      </td>
                                      <td>
                                        {" "}
                                        <i
                                          onClick={() => popToggle(item._id)}
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          className="fas fa-trash"
                                        ></i>
                                      </td>
                                    </tr>
                                  )
                                })
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <ReactPaginate
                  previousLabel="Previous"
                  nextLabel="Next"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  pageCount={pageCount}
                  onPageChange={handlePageChange}
                  containerClassName="pagination"
                  activeClassName="active"
                />
              </div>
            </Col>
          </Row>

          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center"></div>
            <Modal isOpen={popModal} toggle={popToggle} size="sm">
              <ModalHeader className="mt-0" toggle={popToggle}>
                Delete
              </ModalHeader>
              <ModalBody>
                <p>Are you sure to delete this item</p>
                <div className="d-flex flex-wrap gap-2">
                  <Button
                    type="submit"
                    color="secondary"
                    onClick={() => setPopModal(!popModal)}
                  >
                    Cancel
                  </Button>{" "}
                  <Button type="reset" color="danger" onClick={deleteItemData}>
                    Delete
                  </Button>
                </div>
              </ModalBody>
            </Modal>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}

Calender.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
}

const mapStateToProps = ({ calendar }) => ({
  events: calendar.events,
  categories: calendar.categories,
})

const mapDispatchToProps = dispatch => ({
  onGetEvents: () => dispatch(getEvents()),
  onGetCategories: () => dispatch(getCategories()),
  onAddNewEvent: event => dispatch(addNewEvent(event)),
  onUpdateEvent: event => dispatch(updateEvent(event)),
  onDeleteEvent: event => dispatch(deleteEvent(event)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Calender)
