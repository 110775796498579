import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { Tab, Tabs } from "react-bootstrap"
import {
  ObjectId,
  getCouponCode,
} from "common/Services/dbServices"
import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Button,
  Form,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import moment from "moment"

const FormElements = () => {
  const [tabKey, initTabKey] = useState("one")
  const [response, setResponse] = useState(null)
  const urlParams = useParams()

 

  useEffect(() => {
    getCouponData()
  }, [])
  
  const getCouponData = async () => {
    try {
      const res = await getCouponCode({ id: ObjectId(urlParams.id) })
      setResponse(res)

    } catch (error) {
      console.error(error)
    }
  }

console.log('response=',response)
  

  document.title = "Coupon Details | Sayartak"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="Sayartak"
            title="Coupon"
            breadcrumbItem="Coupon Details"
            url="/Coupon"
          />

          <Tabs activeKey={tabKey} onSelect={e => initTabKey(e)}>
            <Tab eventKey="one" title="Details">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <div class="details-section">
                        <div className="row">
                          <div class="col-md-6">
                            <div class="details-section-item">
                              <label class="details-section-item-label">
                                Coupon Name
                              </label>
                              <div class="details-section-item-content">
                                <span>
                                  {response
                                    ? response[0]?.name
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="details-section-item">
                              <label class="details-section-item-label">
                                Description
                              </label>
                              <div class="details-section-item-content">
                                <span>
                                  {response && response.length > 0
                                    ? response[0]?.description
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-12">
                            <hr style={{ width: "100%" }} />
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-md-6">
                            <div class="details-section-item">
                              <label class="details-section-item-label">
                                Type
                              </label>
                              <div class="details-section-item-content">
                                <span>
                                  {response && response.length > 0
                                    ? response[0]?.type
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="details-section-item">
                              <label class="details-section-item-label">
                                Amount
                              </label>
                              <div class="details-section-item-content">
                                <span>
                                  {response && response.length > 0
                                    ? response[0]?.amount
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div class="col-12">
                              <hr style={{ width: "100%" }} />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-md-6">
                            <div class="details-section-item">
                              <label class="details-section-item-label">
                                Validity From
                              </label>
                              <div class="details-section-item-content">
                                <span>
                                  {response && response.length > 0
                                    ? moment(response[0].validFrom).format("Do MMMM YYYY") 
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="details-section-item">
                              <label class="details-section-item-label">
                              Validity Till
                              </label>
                              <div class="details-section-item-content">
                                <span>
                                  {response && response.length > 0
                                    ? moment(response[0].validTill).format("Do MMMM YYYY") 
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-12">
                            <hr style={{ width: "100%" }} />
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-md-6">
                            <div class="details-section-item">
                              <label class="details-section-item-label">
                                code
                              </label>
                              <div class="details-section-item-content">
                                <span>
                                  {response && response.length > 0
                                    ? response[0]?.couponCode
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="details-section-item">
                              <label class="details-section-item-label">
                                isActive
                              </label>
                              <div class="details-section-item-content">
                                <span>
                                  {response && response.length > 0
                                    ? response[0]?.isActive?.toString()
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-12">
                            <hr style={{ width: "100%" }} />
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-md-6">
                            <div class="details-section-item">
                              <label class="details-section-item-label">
                               Included ServiceTypes
                              </label>
                              <div class="details-section-item-content">
                                {
                                 response && response.length > 0? response[0]?.getIncludedServiceTypes.map((v)=>{
                                    return (
                               <span>
                                  {
                                v.name.en.toString()
                                   || "-"}
                                ,</span>
                                    )
                                  }) : null
                                }
                            
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="details-section-item">
                              <label class="details-section-item-label">
                              Excluded ServiceTypes
                              </label>
                              <div class="details-section-item-content">
                              {
                                 response && response.length > 0? response[0]?.getExcludedServiceTypes.map((v)=>{
                                    return (
                               <span>
                                  {
                                v.name.en.toString()
                                   || "-"}
                                ,</span>
                                    )
                                  }) : null
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-12">
                            <hr style={{ width: "100%" }} />
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-md-6">
                            <div class="details-section-item">
                              <label class="details-section-item-label">
                               Included Customers
                              </label>
                              <div class="details-section-item-content">
                              {
                                 response && response.length > 0? response[0]?.getIncludedCustomers.map((v)=>{
                                    return (
                               <span>
                                  {
                                v.name
                                   || "-"}
                                ,</span>
                                    )
                                  }) : null
                                }
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="details-section-item">
                              <label class="details-section-item-label">
                              Excluded Customers
                              </label>
                              <div class="details-section-item-content">
                              {
                                 response && response.length > 0? response[0]?.getExcludedCustomers.map((v)=>{
                                    return (
                               <span>
                                  {
                                v.name
                                   || "-"}
                                ,</span>
                                    )
                                  }) : null
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-12">
                            <hr style={{ width: "100%" }} />
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-md-6">
                            <div class="details-section-item">
                              <label class="details-section-item-label">
                                useOnly Once
                              </label>
                              <div class="details-section-item-content">
                                <span>
                                  {response && response.length > 0
                                    ? response[0]?.toggleSwitch?.toString()
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* </Tab> */}
                      {/* </Tabs> */}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Tab>
            {/* <Tab eventKey="two" title="Demo">
              <p>Hellooo</p>
            </Tab> */}
          </Tabs>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormElements
