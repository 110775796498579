import React, { useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Button,
  Form,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useFormik } from 'formik';
import { createNewUser } from "common/Services/dbServices"
import { Link, useNavigate } from "react-router-dom"
import * as Realm from "realm-web";
import { useCallback } from 'react'
import Select from "react-select"
import { app } from "Context/userContext"

const data = [
  {value:'Admin',label:'Admin'},
  {value:'Editor',label:'Editor'}
]


const FormElements = () => {


  const navigate = useNavigate()
  const [selectRole, setSelectRole] = useState()


  document.title = "Add New | Sayartak"

  const initialValues = {
    name: '',
    email: '',
    mobileNumber: '',
    password: ''
  };


  const onSubmit = async (values) => {
    try {
      const addUser = await createNewUser({ values,selectRole })
      console.log("submit::", addUser)
      if(addUser !== undefined){
        // const email = values.email;
        // const password = values.password;
        // await app.emailPasswordAuth.registerUser({ email, password });
        alert('Technicians Added Successfully')
        navigate("/user")
      }
      else{
        alert('email already exist')
      }

    }
    catch (error) {
      alert('Failed to add Technicians')
    }


  }


  const validate = values => {
    let errors = {}

    if (!values.email) {
      errors.email = 'Email is required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email format'
    }
    if (!values.mobileNumber) {
      errors.mobileNumber = 'Mobile number is required'
    }else if(values.mobileNumber.length<6){
      errors.mobileNumber = 'Minimum 6 letter required'
    }
    if (!values.name) {
      errors.name = 'Name required'
    }
    if (!values.password) {
      errors.password = 'Password is Required'  
    }else if(values.password.length <6){
      errors.password = 'minimum 6 characters'
    }
    return errors

  }


  const formik =
    useFormik({
      initialValues,
      onSubmit,
      validate
    })
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="Sayartak"
            title="User"
            breadcrumbItem="Add New"
            url="/user"
          />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  {/* <CardTitle className="h4">Details</CardTitle> */}
                  <Form
                    style={{ marginTop: 10 }}
                    className="row g-3 needs-validation"
                    onSubmit={formik.handleSubmit}
                  >
                    
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Name
                      </label>
                      <div className="col-md-6">
                        <input
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Name"
                          name="name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name}
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <div style={{ color: 'red' }}>
                            {formik.errors.name}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                 
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Email
                      </label>
                      <div className="col-md-6">
                        <input
                          className="form-control form-control-lg"
                          type="email"
                          placeholder="Email"
                          name='email'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                        {/* {formik.touched.email && formik.errors.email ? (
                          <div style={{ color: 'red' }}>
                            {formik.errors.email}
                          </div>
                        ) : null} */}
                      </div>
                    </Row>

                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Mobile Number
                      </label>
                      <div className="col-md-6">
                        <input
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Mobile Number"
                          name='mobileNumber'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.mobileNumber}
                        />
                        {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
                          <div style={{ color: 'red' }}>
                            {formik.errors.mobileNumber}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Role
                      </label>
                      <div className="col-md-6">
                        <Select
                          // className="form-control form-control-lg"
                          placeholder="Role"
                          value={selectRole}
                          options={data}
                          onChange={e => setSelectRole(e)}
                        />
                      </div>
                    </Row>

                    <Row className="mb-3">
                      <label
                        htmlFor="example-password-input"
                        className="col-md-2 col-form-label"
                      >
                        Password
                      </label>
                      <div className="col-md-6">
                        <input
                          className="form-control form-control-lg"
                          type="password"
                          placeholder="Password"
                          name="password"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.password}
                        />
                        {formik.touched.password && formik.errors.password ? (
                          <div style={{ color: 'red' }}>
                            {formik.errors.password}
                          </div>
                        ) : null}
                      </div>
                    </Row>

                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary">
                        Submit
                      </Button>{" "}
                      <Button type="reset" color="secondary"  onClick={() => navigate("/user")}>
                        Cancel
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormElements
