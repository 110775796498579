import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  Form,
  Input,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ObjectId, performCRUDOperation } from "common/Services/dbServices"
import { useNavigate, useParams } from "react-router-dom"
import Loader from "components/Common/Loader"
import ImageSection from "components/Common/ImageSection";
import Switch from "react-switch"
import { getBanner } from "common/Services/dbServices"
import { preventDefault } from "@fullcalendar/react"

const FormElements = () => {
  const [bannerOrder, setBannerOder] = useState(0)
  const [res, setRes] = useState()
  const navigate = useNavigate()
  const [image, setImage] = useState(null)
  const [isHovered, setIsHovered] = useState(false)
  const [isEnglish, setIsEnglish] = useState(false)
  const [isArabic, setIsArabic] = useState(false)
  const [progress, setProgress] = useState(false)
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState(null)
  const urlParams = useParams();
  useEffect(() => {
    if (urlParams.id) {
      setBanner();
    }
  }, [urlParams])

  useEffect(() => {
    if (response) {
      setInputs()
    }
  }, [response])
  const setBanner = async () => {
    try {
      const id = urlParams?.id
      const response = await getBanner({id});
      setResponse(response)
    } catch (e) {
      alert("Sorry,Something went wrong")
    }
  }

  const setInputs = () => {
    if (response) {

      setImage(response?.images.filePath);
      setRes(response?.images);
      setBannerOder(response?.order);
      setIsArabic(response?.isArabic);
      setIsEnglish(response?.isEnglish);

    }
  }
  const onError = err => {
    console.log("Error", err)
  }
  const onSuccess = result => {
    if (result) {
      setProgress(false)
    }
    setRes(result)
    setImage(result.filePath)
  }

  const onUploadProgress = progress => {
    setProgress(progress)
  }
  const onUploadStart = progress => {
    setProgress(progress)
  }

  const handleCreateBanner = async () => {
    try {

      const response = await performCRUDOperation('Banner', 'create', {
        isEnglish: isEnglish,
        isArabic: isArabic,
        images: res,
        order: Number(bannerOrder),
      })

      if (response) {
        alert("Banner Added Successfully")
        navigate("/banner")
      }
    } catch (error) {
      console.error("Error ::", error)
      setLoading(false)
      alert("Failed to add Banner")
    }

  }

  const handleUpdateBanner = async () => {
 
    const id = urlParams?.id
    try {
      const result = await performCRUDOperation('Banner', 'update', {
        _id:id,
        updatedFields: {
          images: res,
          order: Number(bannerOrder),
          isEnglish: isEnglish,
          isArabic: isArabic
        }
      });
      console.log('edit ==',result)
      if (result && result.modifiedCount !== 0) {
        alert("Banner Updated Successfully")
        navigate("/banner")
      }else {
        alert("Failed to Update")
      }
    } catch (error) {
      alert("Failed to Update")
    }
  }

  const onSubmit = async (e) => {
    preventDefault(e);
    if (urlParams.id === undefined) {
      handleCreateBanner();
    } else {

      handleUpdateBanner();
    }
  }

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        ON
      </div>
    )
  }

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        OFF
      </div>
    )
  }
  document.title = "Add New Banner | Sayartak"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="Sayartak"
            title="Banner"
            breadcrumbItem={urlParams.id ? "Edit Banner" : "Add New Banner"}
            url="/banner"
          />

          <Row>
            <Col>
              <Card>
                {loading ? (
                  <Loader />
                ) : (
                  <CardBody>
                    <Form
                      style={{ marginTop: 10 }}
                      className="row g-3 needs-validation"
                    >
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          For English
                        </label>
                        <div className="col-md-6">
                          <Switch
                            uncheckedIcon={<Offsymbol />}
                            checkedIcon={<OnSymbol />}
                            onColor="#0F52BA"
                            onChange={() => {
                              setIsEnglish(!isEnglish)
                            }}
                            checked={isEnglish}
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          For Arabic
                        </label>
                        <div className="col-md-6">
                          <Switch
                            uncheckedIcon={<Offsymbol />}
                            checkedIcon={<OnSymbol />}
                            onColor="#0F52BA"
                            onChange={() => {
                              setIsArabic(!isArabic)
                            }}
                            checked={isArabic}
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label className="col-sm-2 col-form-label">
                          Banner
                        </label>
                        <ImageSection
                          progress={progress}
                          image={image}
                          isHovered={isHovered}
                          onHoverEnter={() => setIsHovered(true)}
                          onHoverLeave={() => setIsHovered(false)}
                          onImageRemove={() => {
                            setImage(null);
                            setRes(null);
                          }}
                          onUploadError={onError}
                          onUploadSuccess={onSuccess}
                          onUploadProgress={onUploadProgress}
                          onUploadStart={onUploadStart}
                        />
                      </Row>
                      <Row className="mb-3">
                        <label className="col-sm-2 col-form-label">
                          Order No
                        </label>
                        <div className="col-md-6">
                          <Input
                            name="order"
                            className="form-control form-control-lg"
                            type="number"
                            placeholder="Order No"
                            value={bannerOrder}
                            onChange={e => setBannerOder(e.target.value)}
                          />
                        </div>
                      </Row>

                      <div className="d-flex flex-wrap gap-2">
                        <Button
                          type="submit"
                          color="primary"
                          onClick={onSubmit}
                        >
                          {urlParams?.id ? 'Update' : 'Submit'}
                        </Button>{" "}
                        <Button
                          type="reset"
                          color="secondary"
                          onClick={() => navigate("/Banner")}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormElements
