import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { isEmpty } from "lodash"
import {
  getOrderList,
  getOrderCount,
} from "common/Services/dbServices"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Draggable } from "react-beautiful-dnd"
import Loader from "components/Common/Loader"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import { Button } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  addNewEvent,
  deleteEvent,
  getCategories,
  getEvents,
  updateEvent,
} from "../../store/actions"
// import DeleteModal from "./DeleteModal";
//css
import "@fullcalendar/bootstrap/main.css"
import { getOrderStatus } from "common/Services/dbServices"
import ReactPaginate from "react-paginate"

const Calender = props => {
  const { events, categories } = props
  const [modal, setModal] = useState(false)
  const [modalcategory, setModalcategory] = useState(false)
  const [event, setEvent] = useState({})
  const [selectedDay, setSelectedDay] = useState(0)
  const [isEdit, setIsEdit] = useState(false)
  const [data, setData] = useState()
  // const [pageOffset, setPageOffset] = useState(0)
  // const [pageCount, setPageCount] = useState(0)
  const [response, setResponse] = useState()
  const [isLoading, setLoading] = useState(false)

  const [pageNumber, setPageNumber] = useState(0)
  const ordersPerPage = 10 

  const navigate = useNavigate()

  useEffect(() => {
    getOrderStatusData()
    getOrderDetails()
  }, [])

  const dispatch = useDispatch()
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: (event && event.title) || "",
      category: (event && event.category) || "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Your Event Name"),
      category: Yup.string().required("Please Select Your Category"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updatedEvent = {
          id: event.id,
          title: values.title,
          classNames: values.category + " text-white",
          start: event.start,
        }
        // update event
        dispatch(updateEvent(updatedEvent))
        validation.resetForm()
      } else {
        const newEvent = {
          id: Math.floor(Math.random() * 100),
          title: values["title"],
          start: selectedDay ? selectedDay.date : new Date(),
          className: values.category + " text-white",
        }
        // save new event
        dispatch(addNewEvent(newEvent))
        validation.resetForm()
      }
      setSelectedDay(null)
      toggle()
    },
  })



  useEffect(() => {
    const { onGetCategories, onGetEvents } = props
    onGetCategories()
    onGetEvents()
    new Draggable(document.getElementById("external-events"), {
      itemSelector: ".external-event",
    })
  }, [])

  useEffect(() => {
    if (!modal && !isEmpty(event) && !!isEdit) {
      setTimeout(() => {
        setEvent({})
        setIsEdit(false)
      }, 500)
    }
  }, [modal, event])

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }
  const toggle = () => {
    setModal(!modal)
    removeBodyCss()
  }

  const toggleCategory = () => {
    setModalcategory(!modalcategory)
  }

  const getOrderDetails = async () => {
    // Testing Purpose
    try {
      setLoading(true)
      const result = await getOrderList()
      // console.log("result", result)
      if (result) {
        setData(result)
      }
    } catch (e) {
      setLoading(false)
      alert("Sorry,Something went wrong")
    }
    setLoading(false)
  }

  const getOrderStatusData = async () => {
    const res = await getOrderStatus()
    setResponse(res)
  }

  const pageCount = Math.ceil(data?.length / ordersPerPage)

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected)
  }

  const displayedOrder = data?.slice(
    pageNumber * ordersPerPage,
    (pageNumber + 1) * ordersPerPage
  )

  document.title = "Orders | Sayartak "

  return (
    <React.Fragment>
      {/* <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      /> */}
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Row>
            <Col sm={6}>
              <Breadcrumbs title="Sayartak" breadcrumbItem="Orders" />
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <div id="external-events" className="mt-12">
                        <h4 className="card-title mb-4">Order List</h4>
                        <div className="table-responsive">
                          <table className="table table-hover table-centered table-nowrap mb-0">
                            <thead className="table-light">
                              <tr>
                                {/* <th scope="col">(#) Id</th> */}
                                <th scope="col">Order Ref</th>
                                <th scope="col">Name</th>
                                <th scope="col">Payment Status</th>
                                <th scope="col">Order Status</th>
                                <th scope="col">Order PlacedOn</th>
                                <th scope="col">Slot Date</th>
                                {/* <th scope="col"></th> */}
                                <th scope="col" colSpan={2}>
                                  Action
                                </th>
                                {/* <th scope="col">Assined Tecnition</th> */}
                              </tr>
                            </thead>

                            <tbody>
                              {isLoading ? (
                                <tr>
                                  <td colSpan="8">
                                    <Loader />
                                  </td>
                                </tr>
                              ) : (
                                displayedOrder?.map((item, index) => {
                                  return (
                                    <tr>
                                      <td
                                        onClick={() =>
                                          navigate(`/edit-order/${item._id}`, {
                                            state: item,
                                          })
                                        }
                                      >
                                        {item ? (
                                          <td>{item?.refNumber}</td>
                                        ) : null}
                                      </td>
                                      <td
                                        onClick={() =>
                                          navigate(`/edit-order/${item._id}`, {
                                            state: item,
                                          })
                                        }
                                      >
                                        {item && item?.getCustomer ? (
                                          <td>{item.getCustomer.name}</td>
                                        ) : null}
                                      </td>
                                      <td
                                        onClick={() =>
                                          navigate(`/edit-order/${item._id}`, {
                                            state: item,
                                          })
                                        }
                                      >
                                        {item && item?.paymentStatus ? (
                                          <td>{item?.paymentStatus} </td>
                                        ) : null}
                                      </td>
                                      <td
                                        onClick={() =>
                                          navigate(`/edit-order/${item._id}`, {
                                            state: item,
                                          })
                                        }
                                      >
                                        {response ? (
                                          <td>
                                            {response[item?.orderStatus].en}{" "}
                                          </td>
                                        ) : null}
                                      </td>
                                      <td
                                        onClick={() =>
                                          navigate(`/edit-order/${item._id}`, {
                                            state: item,
                                          })
                                        }
                                      >
                                        {moment(item?.orderPlacedOn).format(
                                          "Do MMMM YYYY"
                                        )}
                                      </td>
                                      <td
                                        onClick={() =>
                                          navigate(`/edit-order/${item._id}`, {
                                            state: item,
                                          })
                                        }
                                      >
                                        {moment(item?.slot?.date).format(
                                          "Do MMMM YYYY"
                                        )}
                                      </td>
                                      <td>
                                        {" "}
                                        <i
                                          onClick={() =>
                                            navigate(
                                              `/edit-order/${item._id}`,
                                              {
                                                state: item,
                                              }
                                            )
                                          }
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          className="fas fa-eye"
                                        ></i>
                                      </td>
                                      <td>
                                        {" "}
                                        <i
                                          onClick={() =>
                                            navigate(`/chat/${item._id}`, {
                                              state: item,
                                            })
                                          }
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          className="ion ion-ios-chatbubbles"
                                        ></i>
                                      </td>
                                    </tr>
                                  )
                                })
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <ReactPaginate
                  previousLabel="Previous"
                  nextLabel="Next"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  pageCount={pageCount}
                  onPageChange={handlePageChange}
                  containerClassName="pagination"
                  activeClassName="active"
                />
              </div>
            </Col>
          </Row>

          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center"></div>
            {/* <Modal isOpen={popModal} toggle={popToggle} size="sm">
              <ModalHeader className="mt-0" toggle={popToggle}>
                Delete
              </ModalHeader>
              <ModalBody>
                <p>Are you sure to delete this item</p>
                <div className="d-flex flex-wrap gap-2">
                  <Button
                    type="submit"
                    color="primary"
                    // onClick={() => setPopModal(!popModal)}
                  >
                    Cancel
                  </Button>{" "}
                  <Button type="reset" color="secondary" >
                    Delete
                  </Button>
                </div>
              </ModalBody>
            </Modal> */}
          </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}

Calender.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
}

const mapStateToProps = ({ calendar }) => ({
  events: calendar.events,
  categories: calendar.categories,
})

const mapDispatchToProps = dispatch => ({
  onGetEvents: () => dispatch(getEvents()),
  onGetCategories: () => dispatch(getCategories()),
  onAddNewEvent: event => dispatch(addNewEvent(event)),
  onUpdateEvent: event => dispatch(updateEvent(event)),
  onDeleteEvent: event => dispatch(deleteEvent(event)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Calender)
