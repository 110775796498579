import React, { useEffect, useState } from "react"
import Select from "react-select"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  Form,
  Input,
} from "reactstrap"
import { BSON } from "realm-web"
import {
  createServices,
  getServiceCategory,
  getServiceTypeData,
  getServiceBrandData,
  getServicesList,
  getVehicleCollection,
  ObjectId,
  getCarTypeData,
  getService,
  editServices,
} from "common/Services/dbServices"
import Switch from "react-switch"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { IKUpload, IKContext } from "imagekitio-react"
import { IKImage } from "imagekitio-react"
import { AiOutlineCloseCircle } from "react-icons/ai"
import {
  urlEndpoint,
  authenticationEndpoint,
  publicKey,
} from "../../components/Common/imageKit"

import { useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import { useParams } from "react-router-dom"
import { width } from "@mui/system"

const fuelData = [
  { value: "Petrol", label: "Petrol" },
  { value: "Diesel", label: "Diesel" },
  { value: "Hybrid", label: "Hybrid" },
  { value: "Electric", label: "Electric" },
]

const FormElements = () => {
  const [res, setRes] = useState()
  const [serviceCategoryData, setServiceCategoryData] = useState()
  const [serviceTypeData, setServiceTypeData] = useState()
  const [serviceSubTypeId, setServiceSubTypeId] = useState()
  const [serviceBrandData, setServiceBrandData] = useState()
  const [serviceBrandId, setServiceBrandId] = useState()
  const [serviceBrandTypePrice, setServiceBrandTypePrice] = useState(0)
  const [serviceBrandType, setServiceBrandType] = useState()
  const [selectFuelType, setFuelType] = useState()
  const [refresh, setRefresh] = useState(false)
  const [image, setImage] = useState(null)
  const [progress, setProgress] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const [serviceData, setServiceData] = useState()
  const navigate = useNavigate()
  const [selectCarServices, setCarServices] = useState()
  const [carList, setCarList] = useState()
  const [carType, setCarType] = useState()
  const [selectedCarType, setSelectedCarType] = useState()
  const [isServiceActive, setServiceActive] = useState(false)
  const [isMultiple, setIsMultiple] = useState(false)
  const [editServiceBrandType, setEditServiceBrandType] = useState()
  const [response, setResponse] = useState(null)
  const urlParams = useParams()

  useEffect(() => {
    getServiceData()
    serviceCategoryList()
    serviceTypeList()
    serviceBrandList()
    getServiceDataList()
    getCars()
    fetchCarType()
  }, [])

  useEffect(() => {
    if (response) {
      setInputs()
    }
  }, [response, serviceData, carList])

  const getServiceDataList = async () => {
    const response = await getServicesList()
    // console.log("services", response)
    let relatedServices = []
    response.map((item, index) => {
      let obj = {
        value: item._id,
        label: item.name.en,
      }
      relatedServices.push(obj)
    })

    setServiceData(relatedServices)
  }
  // console.log('test ==',serviceData)
  const getCars = async () => {
    try {
      const response = await getVehicleCollection()
      // console.log("response ==",response)
      if (response) {
        let Cars = []
        response.map((item, index) => {
          let obj1 = {
            value: item._id.toString(),
            label: item.name.en,
            // carType: JSON.stringify(item.carType),
            carType: `${item.carType}`,
          }

          Cars.push(obj1)
        })
        setCarList(Cars)
      }
    } catch (e) {
      // console.log("Error=", e)
    }
  }

  const getServiceData = async () => {
    try {
      const res = await getService({ id: urlParams.id })
      // console.log("res=", res)
      setResponse(res)
    } catch (error) {
      console.log("error", error)
    }
  }

  const setInputs = () => {
    if (response[0]) {
      setServiceActive(response[0].active)
      setIsMultiple(response[0].isMultiple)
      setImage(response[0]?.images?.filePath)
      setRes(response[0]?.images)
      formik.setValues({
        serviceCategoryId: serviceCategoryData?.filter(
          item => item.value.toString() == response[0].serviceTypeId
        ),
        serviceNameEn: response[0].name.en,
        serviceNameAr: response[0].name.ar,
        technicalName: response[0].technicalName,
        servicePrice: response[0].price,
        serviceDescriptionEn: response[0].description.en,
        serviceDescriptionAr: response[0].description.ar,
        serviceSale: response[0].salePrice,
        selectFuelType: fuelData?.filter(item =>
          response[0]?.serviceFuelTypes?.find(
            v => v.toString() === item.value.toString()
          )
        ),
        selectRelatedServices: serviceData?.filter(item =>
          response[0]?.relatedServices?.find(
            v => v.toString() === item.value.toString()
          )
        ),
      })
      setCarServices(
        carList?.filter(item =>
          response[0]?.selectedVehicles?.find(
            v => v.toString() === item.value.toString()
          )
        )
      )
      setServiceBrandType(response[0].serviceBrandType)
    }
  }

  const onError = err => {
    console.log("Error", err)
  }
  const onSuccess = result => {
    if (result) {
      setProgress(false)
    }
    setRes(result)
    setImage(result.filePath)
    // console.log(result)
  }
  const onUploadProgress = progress => {
    setProgress(progress)
  }

  const initialValues = {
    serviceCategoryId: null,
    serviceNameEn: "",
    serviceNameAr: "",
    technicalName: "",
    servicePrice: 0,
    serviceDescriptionEn: null,
    serviceDescriptionAr: null,
    serviceSale: 0,
    selectFuelType: null,
    selectRelatedServices: null,
  }

  const onSubmit = async values => {
    let serviceFuelTypes = selectFuelType
    if (
      !serviceFuelTypes ||
      (serviceFuelTypes && serviceFuelTypes.length === 0)
    ) {
      serviceFuelTypes = fuelData
    }
    if (urlParams.id === undefined) {
      const params = {
        serviceNameEn: values.serviceNameEn,
        serviceNameAr: values.serviceNameAr,
        technicalName: values.technicalName,
        servicePrice: values.servicePrice,
        serviceDescriptionEn: values.serviceDescriptionEn,
        serviceDescriptionAr: values.serviceDescriptionAr,
        res,
        serviceSale: values.serviceSale,
        serviceCategoryId:
          values.serviceCategoryId[0] === undefined
            ? values.serviceCategoryId?.value
            : values.serviceCategoryId[0].value,
        serviceBrandType,
        serviceFuelTypes: values.selectFuelType.map(sc => sc.value),
        selectRelatedServices: values.selectRelatedServices?.map(s =>
          ObjectId(s.value)
        ),
        selectCarServices: selectCarServices?.map(sc => ObjectId(sc.value)),
        isServiceActive,
        isMultiple,
      }
      try {
        const getServiceList = await createServices(params)
        // console.log("this", getServiceList)
        alert("Added Successfully")
        navigate("/service")
      } catch (error) {
        console.log("error", error)
        alert("Failed to Add")
      }
    } else {
      try {
        const editParams = {
          editId: ObjectId(urlParams.id),
          editServiceNameEn: values.serviceNameEn,
          editServiceNameAr: values.serviceNameAr,
          technicalName: values.technicalName,
          editServicePrice: values.servicePrice,
          serviceDescriptionEn: values.serviceDescriptionEn,
          serviceDescriptionAr: values.serviceDescriptionAr,
          res,
          editServiceSale: values.serviceSale,
          editServiceCategoryId:
            values.serviceCategoryId[0] === undefined
              ? values.serviceCategoryId?.value
              : values.serviceCategoryId[0].value,
          serviceBrandType,
          serviceFuelTypes: values.selectFuelType.map(sc => sc.value),
          selectRelatedServices: values.selectRelatedServices?.map(s =>
            ObjectId(s.value)
          ),
          selectCarServices: selectCarServices?.map(sc => ObjectId(sc.value)),
          isServiceActive,
          isMultiple,
        }
        const getServiceList = await editServices(editParams)
        alert("Update Successfully")
        navigate("/service")
      } catch (error) {
        console.log("error", error)
        alert("Failed to Update")
      }
    }
  }

  const validate = values => {
    // console.log("validate=", values)
    let errors = {}
    if (!values.serviceCategoryId) {
      errors.serviceCategoryId = "Service categoryId is required"
    }
    if (!values.serviceNameEn) {
      errors.serviceNameEn = "Service name en is required"
    }
    if (!values.serviceNameAr) {
      errors.serviceNameAr = "Service name ar is required"
    }
    if (!values.serviceDescriptionEn) {
      errors.serviceDescriptionEn = "Service description is required"
    }
    if (!values.serviceDescriptionAr) {
      errors.serviceDescriptionAr = "Service description is required"
    }
    if (!values.selectFuelType) {
      errors.selectFuelType = "Service fuel types is required"
    }
    return errors
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  })

  const onAdd = () => {
    setRefresh(true)
    let data = []
    let serviceBrandName = ""
    let serviceSubTypeName = ""
    if (serviceBrandType && serviceBrandType.length !== 0) {
      //  data = [...JSON.parse(JSON.stringify(serviceBrandType))];
      data = serviceBrandType
    }
    // console.log("serciceBrand id", serviceBrandId)
    if (serviceBrandId) {
      const obj = [...JSON.parse(JSON.stringify(serviceBrandData))].find(
        item => item._id === serviceBrandId
      )
      serviceBrandName = obj.name.en
    }
    if (serviceSubTypeId) {
      const obj1 = [...JSON.parse(JSON.stringify(serviceTypeData))].find(
        item => item._id === serviceSubTypeId
      )
      serviceSubTypeName = obj1.name.en
    }

    const newValues = {
      serviceSubTypeId: new BSON.ObjectId(serviceSubTypeId),
      serviceSubTypeName,
      serviceBrandId: new BSON.ObjectId(serviceBrandId),
      serviceBrandName,
      serviceBrandTypePrice: parseFloat(serviceBrandTypePrice),
    }
    data.push(newValues)
    setServiceBrandType(data)

    // console.log("serviceBrandType", serviceBrandType)
    setTimeout(() => {
      setRefresh(false)
    }, 100)
  }

  const onDelete = index => {
    const newArr = [
      ...serviceBrandType.slice(0, index),
      ...serviceBrandType.slice(index + 1),
    ]
    // console.log("service", serviceBrandType)
    // console.log("newArr", newArr)
    setServiceBrandType(newArr)
  }

  const serviceCategoryList = async () => {
    const result = await getServiceCategory()
    if (result) {
      let ServiceCatId = []
      result.map(v => {
        let obj = {
          value: v?._id,
          label: v?.name.en,
        }
        ServiceCatId.push(obj)
      })
      setServiceCategoryData(ServiceCatId)
    }
  }
  const fetchCarType = async () => {
    try {
      const response = await getCarTypeData()
      if (response) {
        let CarType = []
        response.map((item, index) => {
          let obj = {
            value: item._id.toString(),
            label: item.name.en,
          }
          CarType.push(obj)
        })
        setCarType(CarType)
      }
    } catch (e) {
      // console.log("Error,", e)
    }
  }

  const serviceTypeList = async () => {
    const result = await getServiceTypeData()

    if (result) {
      setServiceTypeData(result)
    }
    // console.log(result)
  }

  const serviceBrandList = async () => {
    const result = await getServiceBrandData()

    if (result) {
      setServiceBrandData(result)
    }
    // console.log(result)
  }

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        ON
      </div>
    )
  }

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        OFF
      </div>
    )
  }
  document.title =
    urlParams.id === undefined
      ? "Add New Service | Sayartak"
      : "Edit Service | Sayartak"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="Sayartak"
            title="Services"
            breadcrumbItem={
              urlParams.id === undefined ? "Add New Service" : "Edit Service"
            }
            url="/service"
          />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  {/* <CardTitle className="h4">Details</CardTitle> */}
                  <Form
                    style={{ marginTop: 10 }}
                    className="row g-3 needs-validation"
                    onSubmit={formik.handleSubmit}
                    // onSubmit={e => {
                    //   e.preventDefault()
                    //   validation.handleSubmit()
                    //   return false
                    // }}
                  >
                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">Active</label>
                      <div className="col-md-10">
                        <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          onColor="#0F52BA"
                          onChange={() => {
                            setServiceActive(!isServiceActive)
                          }}
                          checked={isServiceActive}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">
                        isMutiple
                      </label>
                      <div className="col-md-10">
                        <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          onColor="#0F52BA"
                          onChange={() => {
                            setIsMultiple(!isMultiple)
                          }}
                          checked={isMultiple}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">
                        Service Category
                      </label>
                      <div className="col-md-10">
                        <Select
                          placeholder="Select Services Category"
                          options={serviceCategoryData}
                          name="serviceCategoryId"
                          onBlur={formik.handleBlur}
                          onChange={selectedOption =>
                            formik.setFieldValue(
                              "serviceCategoryId",
                              selectedOption
                            )
                          }
                          value={formik.values.serviceCategoryId}
                        />
                        {formik.touched.serviceCategoryId &&
                        formik.errors.serviceCategoryId ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.serviceCategoryId}
                          </div>
                        ) : null}
                      </div>
                    </Row>

                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Service Name En
                      </label>
                      <div className="col-md-10">
                        <Input
                          name="nameen"
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Service Name En"
                          name="serviceNameEn"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.serviceNameEn}
                        />
                        {formik.touched.serviceNameEn &&
                        formik.errors.serviceNameEn ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.serviceNameEn}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Service Name Ar
                      </label>
                      <div className="col-md-10">
                        <Input
                          name="namear"
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Service Name Ar"
                          name="serviceNameAr"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.serviceNameAr}
                        />
                        {formik.touched.serviceNameAr &&
                        formik.errors.serviceNameAr ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.serviceNameAr}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Technical Name
                      </label>
                      <div className="col-md-10">
                        <Input
                          name="technicalName"
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Technical Name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.technicalName}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">Price</label>
                      <div className="col-md-10">
                        <Input
                          name="price"
                          className="form-control form-control-lg"
                          type="number"
                          placeholder="Price"
                          name="servicePrice"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.servicePrice}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Description En
                      </label>
                      <div className="col-md-10">
                        <Input
                          name="serviceDescriptionEn"
                          className="form-control form-control-lg"
                          type="textarea"
                          placeholder="Add Description En"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.serviceDescriptionEn}
                        />
                        {formik.touched.serviceDescriptionEn &&
                        formik.errors.serviceDescriptionEn ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.serviceDescriptionEn}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Description Ar
                      </label>
                      <div className="col-md-10">
                        <Input
                          name="serviceDescriptionAr"
                          className="form-control form-control-lg"
                          type="textarea"
                          placeholder="Add Description Ar"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.serviceDescriptionAr}
                        />
                        {formik.touched.serviceDescriptionAr &&
                        formik.errors.serviceDescriptionAr ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.serviceDescriptionAr}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Service Icon
                      </label>
                      <div
                        className="col-md-10"
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        {progress ? (
                          <div className="spinner-border" role="status">
                            <span className="sr-only"></span>
                          </div>
                        ) : image ? (
                          <div
                            style={{
                              width: 35,
                              height: 30,
                              display: "flex",
                              alignItems: "center",
                              marginTop: 10,
                            }}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                          >
                            <div
                              style={{
                                position: "relative",
                              }}
                            >
                              <IKImage
                                urlEndpoint={urlEndpoint}
                                path={image}
                                width="35px"
                                height="30px"
                              />
                              {isHovered && image && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "5px",
                                    right: "5px",
                                    zIndex: "1",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setImage(null)
                                    setRes(null)
                                  }}
                                >
                                  <AiOutlineCloseCircle
                                    size={16}
                                    color="red"
                                    style={{
                                      position: "absolute",
                                      top: -10,
                                      right: -10,
                                      // color: 'red',
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <IKContext
                            publicKey={publicKey}
                            urlEndpoint={urlEndpoint}
                            authenticationEndpoint={authenticationEndpoint}
                          >
                            <IKUpload
                              //    fileName={data.brandOrder}
                              onError={onError}
                              onSuccess={onSuccess}
                              onUploadProgress={onUploadProgress}
                              buttonProps={{ children: "+" }}
                            />
                          </IKContext>
                        )}
                      </div>
                    </Row>

                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        SalePrice
                      </label>
                      <div className="col-md-10">
                        <Input
                          name="salePrice"
                          className="form-control form-control-lg"
                          type="number"
                          placeholder="SalePrice"
                          name="serviceSale"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.serviceSale}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">
                        Fuel Type
                      </label>
                      <div className="col-md-10">
                        <Select
                          isMulti
                          placeholder="Select FuelType"
                          options={fuelData}
                          styles={{
                            option: base => ({
                              ...base,
                              backgroundColor: "white",
                              height: "100%",
                            }),
                          }}
                          onBlur={formik.handleBlur}
                          onChange={selectedOptions =>
                            formik.setFieldValue(
                              "selectFuelType",
                              selectedOptions
                            )
                          }
                          value={formik.values.selectFuelType}
                        />
                        {formik.touched.selectFuelType &&
                        formik.errors.selectFuelType ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.selectFuelType}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">
                        Related Services
                      </label>
                      <div className="col-md-10">
                        <Select
                          isMulti
                          placeholder="Select Related Services"
                          options={serviceData}
                          styles={{
                            option: base => ({
                              ...base,
                              backgroundColor: "white",
                              height: "100%",
                            }),
                          }}
                          onBlur={formik.handleBlur}
                          onChange={selectedOptions =>
                            formik.setFieldValue(
                              "selectRelatedServices",
                              selectedOptions
                            )
                          }
                          value={formik.values.selectRelatedServices}
                        />
                      </div>
                    </Row>

                    {/* <Row className="mb-3">
                      <label className="col-md-2 col-form-label">
                        Select Vehicles
                      </label>
                      <div className="col-md-10">
                        <Select
                          isMulti
                          placeholder="Select Vehicles"
                          value={selectCarServices}
                          options={carList}
                          onChange={e => setCarServices(e)}
                        />
                      </div>
                    </Row> */}
                    {/* <Row className="mb-3">
                      <label className="col-md-2 col-form-label">
                        Select Vehicles
                      </label>
                      <div className="col-md-10">
                        <Select
                          isMulti
                          placeholder="Select Vehicles"
                            isSearchable={false} 
                          value={selectCarServices}
                          options={carList}
                          onChange={e => setCarServices(e)}
                          styles={{
                            control: provided => ({
                              ...provided,
                              backgroundColor: "white", // Set the desired background color
                            }),
                            menu: provided => ({
                              ...provided,
                              // maxHeight: "250px", // Adjust the height as needed
                              overflowY: "auto",
                              transition: 'none', // Add vertical scroll if necessary
                            }),
                            
                            // Add any additional styles you need
                          }}
                        />
                      </div>
                    </Row> */}
                    {/* <Row className="mb-3">
                      <label className="col-md-2 col-form-label">
                        Select Vehicles
                      </label>
                      <div className="col-md-10">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Select
                            isMulti
                            placeholder="Select Vehicles"
                            isSearchable={false}
                            options={carList}
                            styles={{
                              option: (base) => ({
                                ...base,
                                backgroundColor:'white',
                                height: '100%',
                              }),
                            }}
                            onChange={e => setCarServices(e)}
                            value={selectCarServices}
                         
                          />
                          <button
                            style={{ marginLeft: "10px" }}
                            onClick={e => {
                              e.preventDefault() // Prevent form submission
                              setCarServices(carList)
                            }}
                          >
                            Select All
                          </button>
                        </div>
                      </div>
                    </Row> */}
                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">
                        Select Vehicles
                      </label>
                      <div className="col-md-10">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div
                            className="col-md-1"
                            style={{ margin: "10px", width: "20%" }}
                          >
                            <Select
                              placeholder="Filter"
                              options={carType}
                              name="selectCarType"
                              // onBlur={formik.handleBlur}
                              onChange={selectedOption =>
                                setSelectedCarType(selectedOption || null)
                              }
                              value={selectedCarType}
                            />
                          </div>
                          <div style={{ margin: "10px" }}>
                            <button
                              style={{
                                padding: "10px 20px", // Adjust padding as needed
                                backgroundColor: "#e6e6e6", // Change the background color to your preferred color
                                color: "black", // Text color
                                border: "none", // Remove the border
                                borderRadius: "5px", // Add rounded corners
                                cursor: "pointer", // Show a pointer cursor on hover
                              }}
                              onClick={e => {
                                e.preventDefault()
                                setCarServices(carList)
                              }}
                            >
                              Select All
                            </button>
                          </div>
                        </div>
                        <div>
                          <div>
                            <Select
                              isMulti
                              placeholder="Select Vehicles"
                              isSearchable={false}
                              options={carList?.filter(
                                car => car?.carType === selectedCarType?.value
                              )}
                              styles={{
                                option: base => ({
                                  ...base,
                                  backgroundColor: "white",
                                  height: "100%",
                                }),
                              }}
                              onChange={e => setCarServices(e)}
                              value={selectCarServices}
                            />
                          </div>
                        </div>
                      </div>
                    </Row>

                    <Row>
                      <Col>
                        <Card>
                          <CardBody>
                            {serviceBrandType && serviceBrandType.length > 0 ? (
                              <table className="table table-hover table-centered table-nowrap mb-0">
                                <thead className="table-light">
                                  <tr>
                                    <th scope="col"> Sub Type</th>
                                    <th scope="col">Brand</th>
                                    <th scope="col">Price</th>
                                    <th scope="col"></th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {serviceBrandType?.map((item, index) => {
                                    // console.log("item", item.serviceBrandId)
                                    return (
                                      <tr>
                                        {item.serviceSubTypeName ? (
                                          <td>{item.serviceSubTypeName}</td>
                                        ) : (
                                          <td>No Value</td>
                                        )}
                                        {item.serviceBrandName ? (
                                          <td>{item.serviceBrandName}</td>
                                        ) : (
                                          <td>No Value</td>
                                        )}

                                        <td>
                                          <div className="mb-12">
                                            <div className="">
                                              <Input
                                                name="salePrice"
                                                className="form-control form-control-lg"
                                                type="number"
                                                placeholder="Price"
                                                value={
                                                  item.serviceBrandTypePrice
                                                }
                                                onChange={e => {
                                                  const updatedPrices = [
                                                    ...serviceBrandType,
                                                  ]
                                                  updatedPrices[
                                                    index
                                                  ].serviceBrandTypePrice =
                                                    e.target.value
                                                  setServiceBrandType(
                                                    updatedPrices
                                                  )
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </td>

                                        <td>
                                          {" "}
                                          <i
                                            onClick={() => onDelete(index)}
                                            className="fas fa-trash"
                                          ></i>
                                        </td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            ) : (
                              <table className="table table-hover table-centered table-nowrap mb-0">
                                <thead className="table-light">
                                  <tr>
                                    <th scope="col"> Sub Type</th>
                                    <th scope="col">Brand</th>
                                    <th scope="col">Price</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <tr>
                                    <td></td>

                                    <td>No Value</td>

                                    <td></td>
                                  </tr>
                                </tbody>
                              </table>
                            )}

                            <div id="external-events" className="mt-12">
                              <h4 className="card-title mb-4 mt-3">
                                Add New Type
                              </h4>
                              <div className="table-responsive">
                                <table className="table table-hover table-centered table-nowrap mb-0">
                                  <tbody>
                                    <td>
                                      <div className="">
                                        <label className="col-sm-4 col-form-label">
                                          Sub Type
                                        </label>
                                        <div className="">
                                          <select
                                            className="form-control form-control-lg"
                                            value={serviceSubTypeId}
                                            onChange={e =>
                                              setServiceSubTypeId(
                                                e.target.value
                                              )
                                            }
                                            placeholder="Select Sub Type"
                                          >
                                            <option>Select Sub Type</option>
                                            {serviceTypeData?.map(item => {
                                              return (
                                                <option value={item._id}>
                                                  {item?.name?.en}
                                                </option>
                                              )
                                            })}
                                          </select>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="">
                                        <label className="col-sm-4 col-form-label">
                                          Brand
                                        </label>
                                        <div className="">
                                          <select
                                            className="form-control form-control-lg"
                                            value={serviceBrandId}
                                            onChange={e =>
                                              setServiceBrandId(e.target.value)
                                            }
                                          >
                                            <option>Select Brand</option>
                                            {serviceBrandData?.map(item => {
                                              return (
                                                <option value={item._id}>
                                                  {item?.name?.en}
                                                </option>
                                              )
                                            })}
                                          </select>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="mb-12">
                                        <label className="col-sm-4 col-form-label">
                                          Price
                                        </label>
                                        <div className="">
                                          <Input
                                            name="salePrice"
                                            className="form-control form-control-lg"
                                            type="number"
                                            placeholder="Price"
                                            value={serviceBrandTypePrice}
                                            onChange={e =>
                                              setServiceBrandTypePrice(
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="mb-12">
                                        <div className="mb-2 gap-2 mt-4">
                                          <Button
                                            color="secondary"
                                            className="btn btn-primary btn-lg w-100 waves-effect waves-light"
                                            onClick={() => onAdd()}
                                          >
                                            {refresh ? (
                                              "loading..."
                                            ) : (
                                              <i className="ti-plus"></i>
                                            )}
                                          </Button>
                                        </div>
                                      </div>
                                    </td>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>

                    {/* <div className="mb-2 gap-2">
              <div>
              <Row className="mb-2">
                      <Col xl={4}>
                        <div className="">
                          <label className="col-sm-4 col-form-label">
                            Sub Type
                          </label>
                          <div className="">
                            <select
                              className="form-control form-control-lg"
                              value={serviceSubTypeId}
                              onChange={e =>
                                setServiceSubTypeId(e.target.value)
                              }
                              placeholder="Select Sub Type"
                            >
                              {serviceTypeData?.map(item => {
                                return (
                                  <option value={item._id}>
                                    {item?.name?.en}
                                  </option>
                                )
                              })}
                            </select>
                          </div>
                        </div>
                      </Col>

                      <Col xl={4}>
                        <div className="">
                          <label className="col-sm-4 col-form-label">
                            Brand
                          </label>
                          <div className="">
                            <select
                              className="form-control form-control-lg"
                              value={serviceBrandId}
                              onChange={e => setServiceBrandId(e.target.value)}
                            >
                              {serviceBrandData?.map(item => {
                                return (
                                  <option value={item._id}>
                                    {item?.name?.en}
                                  </option>
                                )
                              })}
                            </select>
                          </div>
                        </div>
                      </Col>
                      <Col xl={4}>
                        <div className="mb-12">
                          <label className="col-sm-4 col-form-label">
                            Price
                          </label>
                          <div className="">
                            <Input
                              name="salePrice"
                              className="form-control form-control-lg"
                              type="number"
                              placeholder="Price"
                              value={serviceBrandTypePrice}
                              onChange={e =>
                                setServiceBrandTypePrice(e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
              </div>
                  
                    <div className="mb-2 gap-2">
                      <Button
                        color="secondary"
                        className="btn btn-primary btn-lg w-100 waves-effect waves-light"
                        onClick={() => onAdd()}
                      >
                        {refresh ? "loading..." : <i className="ti-plus"></i>}
                      </Button>
                    </div> */}

                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary">
                        Submit
                      </Button>{" "}
                      <Button
                        type="reset"
                        color="secondary"
                        onClick={() => navigate("/service")}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormElements
