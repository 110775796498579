import React, { useState,useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Button,
  Form,
  Input,
  Alert,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { createNewCMS } from "common/Services/dbServices"
import { Link, useNavigate } from "react-router-dom"
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { AiOutlineCloseCircle } from 'react-icons/ai';


const FormElements = () => {

  const navigate = useNavigate()
  // const [addNewCMS, setNewCms] = useState({
  //   name: "",
  //   slug: "",
  // })
  // const [addNewCMSAr, setNewCmsAr] = useState({
  //   name: "",
  //   slug: "",
  // })

  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty()
  )
  const [editorStateAr, setEditorStateAr] = useState(
    () => EditorState.createEmpty()
  )
  const [convertedContent, setConvertedContent] = useState(null);
  const [convertedContentAr, setConvertedContentAr] = useState(null);
  const [nameEn , setNameEn] = useState()
  const [nameAr , setNameAr] = useState()
  const [slugEn , setSlugEn] = useState()


  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(html);
    let htmlAr = convertToHTML(editorStateAr.getCurrentContent());
    setConvertedContentAr(htmlAr);
  }, [editorState,editorStateAr]);

  console.log(convertedContent);

  const onSubmit = async e => {
    e.preventDefault()
    try {
      const createCMSResult = await createNewCMS({   nameEn,nameAr,slugEn,convertedContent,convertedContentAr })
      console.log("submit::", createCMSResult)
      alert("Item Added Successfully")
      navigate("/CMS")
    } catch (error) {
      alert("Failed to Add")
      console.log('Error ==',error)
    //   navigate("/brand")
    }
  }


  document.title = "Add CMS | Sayartak"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="Sayartak"
            title="CMS"
            breadcrumbItem="Add CMS"
            url="/CMS"
          />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  {/* <CardTitle className="h4">Details</CardTitle> */}
                  <Form
                    style={{ marginTop: 10 }}
                    className="row g-3 needs-validation"

                  >
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                     Name En
                      </label>
                      <div className="col-md-6">
                        <Input
                          name="nameen"
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Name En"
                          value={nameEn}
                          onChange={e=>setNameEn(e.target.value)}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Slug En
                      </label>
                      <div className="col-md-6">
                        <Input
                          name="slugen"
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Slug En"
                          value={slugEn}
                          onChange={e=>setSlugEn(e.target.value)}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Description En
                      </label>
                      <div className="col-md-6">
                      <Editor
        editorState={editorState}
        onEditorStateChange={setEditorState}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
      />
                      </div>
                    </Row>

                    
                  </Form>
                </CardBody>
              </Card>
              <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary" onClick={onSubmit}>
                        Submit
                      </Button>{" "}
                      <Button type="reset" color="secondary" onClick={() => navigate("/CMS")}>
                        Cancel
                      </Button>
                    </div>
            </Col>
            <Col>
              <Card>
                <CardBody>
                  {/* <CardTitle className="h4">Details</CardTitle> */}
                  <Form
                    style={{ marginTop: 10 }}
                    className="row g-3 needs-validation"

                  >
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                     Name Ar
                      </label>
                      <div className="col-md-6">
                        <Input
                          name="namear"
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Name Ar"
                          value={nameAr}
                          onChange={e=>setNameAr(e.target.value)}
                        />
                      </div>
                    </Row>
                  
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Description Ar
                      </label>
                      <div className="col-md-6">
                      <Editor
        editorState={editorStateAr}
        onEditorStateChange={setEditorStateAr}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
      />
                      </div>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormElements
