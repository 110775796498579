import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { isEmpty } from "lodash"
import { Draggable } from "react-beautiful-dnd"
import { useFormik } from "formik"
import * as Yup from "yup"
import { IKUpload, IKContext } from "imagekitio-react"

import { IKImage } from "imagekitio-react"
import logosmImg from "../../assets/images/logo.png"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Input,
} from "reactstrap"
import {
  getServiceBrandData,
  getDeletedServiceBrand,
  editServiceBrand,
} from "common/Services/dbServices"
import { Link, useNavigate } from "react-router-dom"
import {
  urlEndpoint,
  authenticationEndpoint,
  publicKey,
} from "../../components/Common/imageKit"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  addNewEvent,
  deleteEvent,
  getCategories,
  getEvents,
  updateEvent,
} from "../../store/actions"
import { AiOutlineCloseCircle } from "react-icons/ai"
// import DeleteModal from "./DeleteModal";
//css
import "@fullcalendar/bootstrap/main.css"
import Loader from "components/Common/Loader"
import ReactPaginate from 'react-paginate';
const Calender = props => {
  const { events, categories } = props
  const [modal, setModal] = useState(false)
  const [popModal, setPopModal] = useState(false)
  const [modalcategory, setModalcategory] = useState(false)
  const [event, setEvent] = useState({})
  const [selectedDay, setSelectedDay] = useState(0)
  const [isEdit, setIsEdit] = useState(false)
  const navigate = useNavigate()
  const [dragActive, setDragActive] = React.useState(false)
  const inputRef = React.useRef(null)
  const [data, setData] = useState()
  const [id, setId] = useState()
  const [deletedItem, setDeletedItem] = useState()
  const [serviceBrandNameEn, setServiceBrandNameEn] = useState()
  const [serviceBrandNameAr, setServiceBrandNameAr] = useState()
  const [editedOrderValue, setEditedOrderValue] = useState(0)
  const [res, setRes] = useState()
  const [editId, setEditId] = useState()
  const [editedServiceCatg, setEditedServiceCatg] = useState()
  const [isHovered, setIsHovered] = useState(false)
  const [progress , setProgress] = useState(false)
  const [isLoading,setLoading] = useState(false)

  const [pageNumber, setPageNumber] = useState(0); // Current page number
  const servicesPerPage = 10; // Number of customers to display per page


  useEffect(() => {
    getServiceBrandList()
  }, [deletedItem, editedServiceCatg])

  const getServiceBrandList = async () => {
    try{
      setLoading(true)
      const response = await getServiceBrandData()
      if (response) {
        setData(response)
      }
    }catch (e){
      setLoading(false)
      alert('Sorry,Something went wrong')
    }
    setLoading(false)
  }

  const handleDrag = function (e) {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true)
    } else if (e.type === "dragleave") {
      setDragActive(false)
    }
  }


  const dispatch = useDispatch()

  // events validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (event && event.title) || "",
      category: (event && event.category) || "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Your Event Name"),
      category: Yup.string().required("Please Select Your Category"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updatedEvent = {
          id: event.id,
          title: values.title,
          classNames: values.category + " text-white",
          start: event.start,
        }
        // update event
        dispatch(updateEvent(updatedEvent))
        validation.resetForm()
      } else {
        const newEvent = {
          id: Math.floor(Math.random() * 100),
          title: values["title"],
          start: selectedDay ? selectedDay.date : new Date(),
          className: values.category + " text-white",
        }
        // save new event
        dispatch(addNewEvent(newEvent))
        validation.resetForm()
      }
      setSelectedDay(null)
      toggle()
      popToggle()
    },
  })

  // category validation
  const categoryValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (event && event.title) || "",
      category: (event && event.category) || "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Your Event Name"),
      category: Yup.string().required("Please Enter Your Billing Name"),
    }),
    onSubmit: values => {
      const newEvent = {
        id: Math.floor(Math.random() * 100),
        title: values["title"],
        start: selectedDay ? selectedDay.date : new Date(),
        className: values.event_category
          ? values.event_category + " text-white"
          : "bg-danger text-white",
      }
      // save new event

      dispatch(addNewEvent(newEvent))
      toggleCategory()
    },
  })

  useEffect(() => {
    const { onGetCategories, onGetEvents } = props
    onGetCategories()
    onGetEvents()
    new Draggable(document.getElementById("external-events"), {
      itemSelector: ".external-event",
    })
  }, [])

  useEffect(() => {
    if (!modal && !isEmpty(event) && !!isEdit) {
      setTimeout(() => {
        setEvent({})
        setIsEdit(false)
      }, 500)
    }
  }, [modal, event])

  /**
   * Handling the modal state
   */
  const toggle = result => {
    // console.log("resInfo::", result)
    setEditId(result?._id)
    setRes(result?.images)
    setServiceBrandNameEn(result?.name?.en)
    setServiceBrandNameAr(result?.name?.ar)
    setEditedOrderValue(result?.order)

    setModal(!modal)
  }
  const editServiceBrandData = async event => {
    event.preventDefault()
    try {
      const editBrandList = await editServiceBrand({
        editId,
        serviceBrandNameEn,
        serviceBrandNameAr,
        editedOrderValue,
        res,
      })
      setEditedServiceCatg(editBrandList)
      console.log("address::", editedServiceCatg)
      setModal(!modal)
    } catch (error) {
      alert("Failed to Update")
    }
  }
  const popToggle = res => {
    setId(res)
    setPopModal(!popModal)
  }
  const deleteItemData = async () => {
    const getDeleted = await getDeletedServiceBrand({ id })
    setDeletedItem(getDeleted)
    setPopModal(!popModal)
  }
  // const navi = () => {
  //   navigate("/service-details")
  // }
  const toggleCategory = () => {
    setModalcategory(!modalcategory)
  }

  const onError = err => {
    console.log("Error", err)
  }
  const onSuccess = result => {
    if(result){
      setProgress(false)
    }

    setRes(result)
    console.log("Success", result)
  }
  const onUploadProgress=progress=>{
    setProgress(progress)
  }

  const pageCount = Math.ceil(data?.length / servicesPerPage);

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  const displayedServices = data?.slice(
    pageNumber * servicesPerPage,
    (pageNumber + 1) * servicesPerPage
  );


  document.title = "Services | Sayartak "

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Row>
            <Col sm={6}>
              <Breadcrumbs title="Sayartak" breadcrumbItem="Service Brand" />
            </Col>
            <Col sm={6}>
              <div className="float-end d-none d-md-block">
                <Link
                  to="/add-new-service-brand"
                  className="btn btn-secondary"
                  style={{ marginTop: 10 }}
                >
                  Add New
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <div id="external-events" className="mt-12">
                        <h4 className="card-title mb-4">
                          List of Service Brand
                        </h4>
                        <div className="table-responsive">
                          <table className="table table-hover table-centered table-nowrap mb-0">
                            <thead className="table-light">
                              <tr>
                                {/* <th scope="col">(#) Id</th> */}

                                <th scope="col">Name En</th>
                                <th scope="col">Name Ar</th>
                                <th scope="col">Image</th>
                                <th scope="col">Order No</th>
                                <th scope="col" colSpan={3}>
                                  Action
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {isLoading ?
                              <tr>
                                <td colSpan='8'>
                                  <Loader />
                                </td>
                              </tr>
                              :
                              displayedServices?.map((item, index) => {
                                return (
                                  <tr>
                                    <td
                                      onClick={() =>
                                        navigate("/service-brand-details", {
                                          state: item,
                                        })
                                      }
                                    >
                                      {item.name.en}
                                    </td>
                                    <td
                                      onClick={() =>
                                        navigate("/service-brand-details", {
                                          state: item,
                                        })
                                      }
                                    >
                                      {item.name.ar}
                                    </td>
                                    <td
                                      onClick={() =>
                                        navigate("/service-brand-details", {
                                          state: item,
                                        })
                                      }
                                    >
                                      <div>
                                        {item.images && item.images.filePath ? (
                                          <IKImage
                                            urlEndpoint={urlEndpoint}
                                            path={item.images.filePath}
                                            width="35px"
                                            height="30px"
                                          />
                                        ) : (
                                          <img
                                            src={logosmImg}
                                            alt=""
                                            className="img-thumbnail-sm"
                                            width="35px"
                                            height="35px"
                                          />
                                        )}
                                      </div>
                                    </td>
                                    <td
                                      onClick={() =>
                                        navigate("/service-brand-details", {
                                          state: item,
                                        })
                                      }
                                    >
                                      {item.order}
                                    </td>
                                    <td>
                                      {" "}
                                      <i
                                        onClick={() =>
                                          navigate("/service-brand-details", {
                                            state: item,
                                          })
                                        }
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        className="fas fa-eye"
                                      ></i>
                                    </td>
                                    <td>
                                      {" "}
                                      <i
                                        onClick={() => toggle(item)}
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        className="fas fa-edit"
                                      ></i>
                                    </td>
                                    <td>
                                      {" "}
                                      <i
                                        onClick={() => popToggle(item._id)}
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        className="fas fa-trash"
                                      ></i>
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col>
            <div style={{display:"flex",justifyContent:"flex-end"}}>
            <ReactPaginate
                previousLabel="Previous"
                nextLabel="Next"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={pageCount}
                onPageChange={handlePageChange}
                containerClassName="pagination"
                activeClassName="active"
              />
            </div>
            
            </Col>     
          </Row>

          <Col>
            <Modal isOpen={modal} toggle={toggle} centered size="lg">
              <ModalHeader className="mt-0" toggle={() => setModal(!modal)}>
                Edit Brand
              </ModalHeader>
              <ModalBody>
                <Form
                  id="form-file-upload"
                  onDragEnter={handleDrag}
                  // style={{ marginTop: 10 }}
                  className="row mt-10 needs-validation"
                  // onSubmit={e => {
                  //   e.preventDefault()
                  //   validation.handleSubmit()
                  //   setModal(!modal), e.preventDefault()
                  //   return false
                  // }}
                >
                  <Row className="mb-2">
                    <label className="col-sm-3 col-form-label">
                      Brand Name En :{" "}
                    </label>
                    <div className="col-md-6">
                      <Input
                        name="nameen"
                        className="form-control form-control-lg"
                        type="text"
                        placeholder="Brand Name En"
                        value={serviceBrandNameEn}
                        onChange={e => setServiceBrandNameEn(e.target.value)}
                      />
                    </div>
                  </Row>
                  <Row className="mb-2">
                    <label className="col-sm-3 col-form-label">
                      Brand Name Ar :{" "}
                    </label>
                    <div className="col-md-6">
                      <Input
                        name="namear"
                        className="form-control form-control-lg"
                        type="text"
                        placeholder="Brand Name Ar"
                        value={serviceBrandNameAr}
                        onChange={e => setServiceBrandNameAr(e.target.value)}
                      />
                    </div>
                  </Row>
                  <Row className="mb-2">
                    <label className="col-sm-3 col-form-label">
                      Brand Icon :{" "}
                    </label>
                    <div
                      className="col-md-6"
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      {progress ? (
                        <div className="spinner-border" role="status">
                          <span className="sr-only"></span>
                        </div>
                      )
                       :res && res.filePath ? (
                        <div
                          style={{
                            width: 35,
                            height: 30,
                            display: "flex",
                            alignItems: "center",
                            marginTop: 10,
                          }}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          <div
                            style={{
                              position: "relative",
                            }}
                          >
                            <IKImage
                              urlEndpoint={urlEndpoint}
                              path={res.filePath}
                              width="35px"
                              height="30px"
                            />
                            {isHovered && res.filePath && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "5px",
                                  right: "5px",
                                  zIndex: "1",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  // setImage(null);
                                  setRes(null)
                                }}
                              >
                                <AiOutlineCloseCircle
                                  size={16}
                                  color="red"
                                  style={{
                                    position: "absolute",
                                    top: -10,
                                    right: -10,
                                    // color: 'red',
                                    cursor: "pointer",
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <IKContext
                          publicKey={publicKey}
                          urlEndpoint={urlEndpoint}
                          authenticationEndpoint={authenticationEndpoint}
                        >
                          <IKUpload
                            //    fileName={data.brandOrder}
                            onError={onError}
                            onSuccess={onSuccess}
                            onUploadProgress={onUploadProgress}
                            buttonProps={{ children: "+" }}
                          />
                        </IKContext>
                      )}
                    </div>
                  </Row>

                  <Row className="mb-2">
                    <label className="col-sm-3 col-form-label">Order : </label>
                    <div className="col-md-6">
                      <Input
                        name="order"
                        className="form-control form-control-lg mb-2"
                        type="text"
                        placeholder="Order No"
                        value={editedOrderValue}
                        onChange={e => setEditedOrderValue(e.target.value)}
                      />
                    </div>
                  </Row>
                  <div className="d-flex flex-wrap gap-2">
                    <Button
                      type="submit"
                      color="primary"
                      onClick={editServiceBrandData}
                    >
                      Submit
                    </Button>{" "}
                    <Button
                      type="reset"
                      color="secondary"
                      onClick={() => setModal(!modal)}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              </ModalBody>
            </Modal>
          </Col>

          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center"></div>
            <Modal isOpen={popModal} toggle={popToggle} size="sm">
              <ModalHeader className="mt-0" toggle={popToggle}>
                Delete
              </ModalHeader>
              <ModalBody>
                <p>Are you sure to delete this item</p>
                <div className="d-flex flex-wrap gap-2">
                  <Button type="submit" color="secondary">
                    Cancel
                  </Button>{" "}
                  <Button type="reset" color="danger" onClick={deleteItemData}>
                    Delete
                  </Button>
                </div>
              </ModalBody>
            </Modal>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}

Calender.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
}

const mapStateToProps = ({ calendar }) => ({
  events: calendar.events,
  categories: calendar.categories,
})

const mapDispatchToProps = dispatch => ({
  onGetEvents: () => dispatch(getEvents()),
  onGetCategories: () => dispatch(getCategories()),
  onAddNewEvent: event => dispatch(addNewEvent(event)),
  onUpdateEvent: event => dispatch(updateEvent(event)),
  onDeleteEvent: event => dispatch(deleteEvent(event)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Calender)
