import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { isEmpty } from "lodash"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import { Draggable } from "@fullcalendar/interaction"
import { Link } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  addNewEvent,
  deleteEvent,
  getCategories,
  getEvents,
  updateEvent,
} from "../../store/actions"
// import DeleteModal from "./DeleteModal";
//css
import "@fullcalendar/bootstrap/main.css"
import { getUsers, editUser, getDeletedUser } from "common/Services/dbServices"
import Select from "react-select"
import Loader from "components/Common/Loader"

const data = [
  { value: "Admin", label: "Admin" },
  { value: "Editor", label: "Editor" },
]
const Calender = props => {
  const { events, categories } = props
  const [modal, setModal] = useState(false)
  const [popModal, setPopModal] = useState(false)
  const [event, setEvent] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [editedUser, setEditedUser] = useState()
  const [deletedItem, setDeletedItem] = useState()
  const [editId, setEditId] = useState()
  const [userData, setUserData] = useState()
  const [technician, setTechnician] = useState()
  const [id, setId] = useState()
  const [email, setEmail] = useState()
  const [mobileNumber, setMobileNumber] = useState()
  const [name, setName] = useState()
  const [password, setPassword] = useState()
  const [selectRole, setSelectRole] = useState()
  const [isLoading,setLoading] = useState(false)


  useEffect(() => {
    usersList()
  }, [editedUser, deletedItem])

  const usersList = async () => {
    try {
      setLoading(true)
      const response = await getUsers()
      if (response) {
        setUserData(response)
      }
    } catch (e) {
      setLoading(false)
      alert("Sorry,Something went wrong")
    }
    setLoading(false)
  }

  const editUserData = async e => {
    e.preventDefault()
    try {
      const editUserList = await editUser({
        editId,
        // email,
        mobileNumber,
        name,
        selectRole,
      })
      setEditedUser(editUserList)
      setModal(!modal)
      //  console.log("address::", editUser)
    } catch (error) {
      console.log("address::", error.message)
      alert("Failed to Update")
      setModal(!modal)
    }
  }

  const toggle = result => {
    //  console.log("result=", result)

    setEditId(result._id)
    setEmail(result.email)
    setMobileNumber(result.mobileNumber)
    setName(result.name)
    setPassword(result.password)
    setSelectRole(result?.role)

    setTechnician(result)
    setModal(!modal)
  }

  const popToggle = itemId => {
    setId(itemId)
    setPopModal(!popModal)
  }

  const deleteItemData = async () => {
    const getDeleted = await getDeletedUser({ id })
    setDeletedItem(getDeleted)
    setPopModal(!popModal)
  }

  useEffect(() => {
    const { onGetCategories, onGetEvents } = props
    onGetCategories()
    onGetEvents()

    new Draggable(document.getElementById("external-events"), {
      itemSelector: ".external-event",
    })
  }, [])

  useEffect(() => {
    if (!modal && !isEmpty(event) && !!isEdit) {
      setTimeout(() => {
        setEvent({})
        setIsEdit(false)
      }, 500)
    }
  }, [modal, event])

  document.title = "User | Sayartak "
  return (
    <React.Fragment>
      {/* <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      /> */}
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Row>
            <Col sm={6}>
              <Breadcrumbs title="Sayartak" breadcrumbItem="User" />
            </Col>
            <Col sm={6}>
              <div className="float-end d-none d-md-block">
                <Link
                  to="/add-new-user"
                  className="btn btn-secondary"
                  style={{ marginTop: 10 }}
                >
                  Add New
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <div id="external-events" className="mt-12">
                        <h4 className="card-title mb-4">List of Users</h4>
                        <div className="table-responsive">
                          <table className="table table-hover table-centered table-nowrap mb-0">
                            <thead className="table-light">
                              <tr>
                                <th scope="col">Email</th>
                                <th scope="col">Name</th>
                                <th scope="col">User Role</th>
                                <th scope="col">Mobile Number</th>
                                <th scope="col" colSpan={2}>
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {isLoading ? 
                              <tr>
                                <td colSpan='8'>
                                  <Loader />
                                </td>
                              </tr>
                              :
                               userData?.map(item => {
                                return (
                                  <tr>
                                    <td>{item.email}</td>
                                    <td>{item.name}</td>
                                    <td>{item.role.value}</td>
                                    <td>{item.mobileNumber}</td>
                                    <td>
                                      {" "}
                                      <i
                                        onClick={() => toggle(item)}
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        className="fas fa-edit"
                                      ></i>
                                    </td>
                                    <td>
                                      {" "}
                                      <i
                                        onClick={() => popToggle(item._id)}
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        className="fas fa-trash"
                                      ></i>
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          <Col>
            <Modal isOpen={modal} toggle={toggle} centered size="lg">
              <ModalHeader className="mt-0" toggle={toggle}>
                Edit Technician
              </ModalHeader>
              <ModalBody>
                <Form
                  style={{ marginTop: 10 }}
                  className="row g-3 needs-validation"
                  // onSubmit={onSubmit}
                >
                  {/* <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Employee ID
                      </label>
                      <div className="col-md-6">
                        <input
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Employee ID"
                        />
                      </div>
                    </Row> */}
                  <Row className="mb-3">
                    <label className="col-sm-2 col-form-label">Email</label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="email"
                        placeholder="Email"
                        name="email"
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        readOnly
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-sm-2 col-form-label">
                      Mobile Number
                    </label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        placeholder="Mobile Number"
                        name="mobileNumber"
                        onChange={e => setMobileNumber(e.target.value)}
                        value={mobileNumber}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-sm-2 col-form-label">Name</label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        placeholder="Name"
                        name="name"
                        onChange={e => setName(e.target.value)}
                        value={name}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-sm-2 col-form-label">Role</label>
                    <div className="col-md-6">
                      <Select
                        // className="form-control form-control-lg"
                        placeholder="Role"
                        value={selectRole}
                        options={data}
                        onChange={e => setSelectRole(e)}
                      />
                    </div>
                  </Row>
                  {/* <Row className="mb-3">
                    <label
                      htmlFor="example-password-input"
                      className="col-md-2 col-form-label"
                    >
                      Password
                    </label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="password"
                        placeholder="Password"
                        name="password"
                        onChange={e => setPassword(e.target.value)}
                        value={password}
                      />
                    </div>
                  </Row> */}
                  <div className="d-flex flex-wrap gap-2">
                    <Button color="primary" onClick={editUserData}>
                      Submit
                    </Button>{" "}
                    <Button
                      type="reset"
                      color="secondary"
                      onClick={() => setModal(!modal)}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              </ModalBody>
            </Modal>
          </Col>

          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center"></div>
            <Modal isOpen={popModal} toggle={popToggle} size="sm">
              <ModalHeader className="mt-0" toggle={popToggle}>
                Delete
              </ModalHeader>
              <ModalBody>
                <p>Are you sure to delete this item</p>
                <div className="d-flex flex-wrap gap-2">
                  <Button
                    type="submit"
                    color="secondary"
                    onClick={() => {
                      popToggle()
                    }}
                  >
                    Cancel
                  </Button>{" "}
                  <Button type="reset" color="danger" onClick={deleteItemData}>
                    Delete
                  </Button>
                </div>
              </ModalBody>
            </Modal>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}

Calender.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
}

const mapStateToProps = ({ calendar }) => ({
  events: calendar.events,
  categories: calendar.categories,
})

const mapDispatchToProps = dispatch => ({
  onGetEvents: () => dispatch(getEvents()),
  onGetCategories: () => dispatch(getCategories()),
  onAddNewEvent: event => dispatch(addNewEvent(event)),
  onUpdateEvent: event => dispatch(updateEvent(event)),
  onDeleteEvent: event => dispatch(deleteEvent(event)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Calender)
