import React, { useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Button,
  Form,
  Input,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { IKUpload, IKContext } from "imagekitio-react"
import { Link, useNavigate } from "react-router-dom"
import {
  urlEndpoint,
  authenticationEndpoint,
  publicKey,
} from "../../components/Common/imageKit"
import { IKImage } from "imagekitio-react"
import { AiOutlineCloseCircle } from "react-icons/ai"
import { createServiceBrand } from "common/Services/dbServices"
import { useFormik } from 'formik';

const FormElements = () => {
  const [res, setRes] = useState()
  const [image, setImage] = useState(null)
  const [isHovered, setIsHovered] = useState(false)
  const [progress, setProgress] = useState(false)
  const navigate = useNavigate()


  const onError = err => {
    console.log("Error", err)
  }
  const onSuccess = result => {
    if (result) {
      setProgress(false)
    }

    setRes(result)
    setImage(result.filePath)
    console.log(result)
  }
  const onUploadProgress = progress => {
    setProgress(progress)
  }
  const initialValues = {
    serviceBrandEn: '',
    serviceBrandAr: '',
    serviceBrandOrder: 0,
  }

  const onSubmit = async (values) => {
    console.log('values=',values)
    try {
      const createSerBrand = await createServiceBrand({
        values,
        res
      })
      console.log(createSerBrand)
      navigate("/service-brands")
    } catch (error) {
      alert("Failed to Add")
      navigate("/service-brands")
    }
  }

  const validate = values => {
    let errors = {}

    if (!values.serviceBrandEn) {
      errors.serviceBrandEn = 'Service brand en required'
    }
    if (!values.serviceBrandAr) {
      errors.serviceBrandAr = 'Service brand ar required'
    }
    if (!values.serviceBrandOrder) {
      errors.serviceBrandOrder = 'Service brand order required'
    }
    return errors
  }


  const formik =
    useFormik({
      initialValues,
      onSubmit,
      validate
    })

  document.title = "Add New Service Brand | Sayartak"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="Sayartak"
            title="Services"
            breadcrumbItem="Add New Service Brand"
            url="/service-brands"
          />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  {/* <CardTitle className="h4">Details</CardTitle> */}
                  <Form
                    style={{ marginTop: 10 }}
                    onSubmit={formik.handleSubmit}
                    className="row g-3 needs-validation"
                  >
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Service Brand En
                      </label>
                      <div className="col-md-6">
                        <Input
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Service Name En"
                          name='serviceBrandEn'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.serviceBrandEn}
                        />
                         {formik.touched.serviceBrandEn && formik.errors.serviceBrandEn ? (
                          <div style={{ color: 'red' }}>
                            {formik.errors.serviceBrandEn}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Service Brand Ar
                      </label>
                      <div className="col-md-6">
                        <Input
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Service Name Ar"
                          name='serviceBrandAr'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.serviceBrandAr}
                        />
                        {formik.touched.serviceBrandAr && formik.errors.serviceBrandAr ? (
                          <div style={{ color: 'red' }}>
                            {formik.errors.serviceBrandAr}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Service Icon
                      </label>
                      <div
                        className="col-md-6"
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        {progress ? (
                        <div className="spinner-border" role="status">
                          <span className="sr-only"></span>
                        </div>
                      )
                       :image ? (
                          <div
                            style={{
                              width: 35,
                              height: 30,
                              display: "flex",
                              alignItems: "center",
                              marginTop: 10,
                            }}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                          >
                            <div
                              style={{
                                position: "relative",
                              }}
                            >
                              <IKImage
                                urlEndpoint={urlEndpoint}
                                path={image}
                                width="35px"
                                height="30px"
                              />
                              {isHovered && image && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "5px",
                                    right: "5px",
                                    zIndex: "1",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setImage(null)
                                    setRes(null)
                                  }}
                                >
                                  <AiOutlineCloseCircle
                                    size={16}
                                    color="red"
                                    style={{
                                      position: "absolute",
                                      top: -10,
                                      right: -10,
                                      // color: 'red',
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <IKContext
                            publicKey={publicKey}
                            urlEndpoint={urlEndpoint}
                            authenticationEndpoint={authenticationEndpoint}
                          >
                            <IKUpload
                              //    fileName={data.brandOrder}
                              onError={onError}
                              onSuccess={onSuccess}
                              onUploadProgress={onUploadProgress}
                              buttonProps={{ children: "+" }}
                            />
                          </IKContext>
                        )}
                      </div>
                    </Row>

                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Order No
                      </label>
                      <div className="col-md-6">
                        <Input
                          className="form-control form-control-lg"
                          type="number"
                          placeholder="Order No"
                          name='serviceBrandOrder'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.serviceBrandOrder}
                        />
                      </div>
                    </Row>

                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary" >
                        Submit
                      </Button>{" "}
                      <Button
                        type="reset"
                        color="secondary"
                        onClick={() => navigate("/service-brands")}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormElements
