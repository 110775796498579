import React, { useState } from "react"
import { useLocation,useParams } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap"
import DOMPurify from 'dompurify';
import { Link, useNavigate } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Button,
  Form,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const FormElements = () => {
  const [tabKey, initTabKey] = useState("one")
  const location = useLocation();
  const data = location.state
  const {editId} = useParams()

  document.title = "CMS Details | Sayartak"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
        <Row>
         <Col>
         <Breadcrumbs
            maintitle="Sayartak"
            title="CMS"
            breadcrumbItem="CMS Details"
            url="/CMS"
          /></Col>
  
      <Col sm={6}>
              <div className="float-end d-none d-md-block">
                <Link
                     to={`/edit-cms/${editId}`}
                     state={data}
                  className="btn btn-secondary"
                  style={{ marginTop: 10 }}
                >
                  Edit Page
                </Link>
              </div>
            </Col>
      </Row>
          <Tabs activeKey={tabKey} onSelect={e => initTabKey(e)}>
            <Tab eventKey="one" title="Details">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <Row>
                      <Col>
                      <Row className="mb-4">
                        <label className="col-sm-2 ">Name En:</label>
                        <div className="col-md-6">
                          {data.name.en ?(
                          <p>
                            <strong>{data.name.en}</strong>
                          </p>
                          ) : null}
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label className="col-sm-3">
                          Description En:
                        </label>
                        <div className="col-md-6">
                        {data.description ?(
                          <p>
                            <strong><td  dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.description.en)}} ></td></strong>
                          </p>
                        ) : null}
                        </div>
                      </Row>
                      <Row className="mb-4">
                        <label className="col-sm-2">
                          Slug En:
                        </label>
                        <div className="col-md-6">
                        {data.slug.en ?(
                          <p>
                            <strong>{data.slug.en}</strong>
                          </p>
                        ) : null}
                        </div>
                      </Row>
                      </Col>
                      <Col>
                      <Col>
                  
                      <Row className="mb-4">
                        <label className="col-sm-2 ">Name Ar:</label>
                        <div className="col-md-6">
                          {data.name.ar ?(
                          <p>
                            <strong>{data.name.ar}</strong>
                          </p>
                          ) : null}
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label className="col-sm-3">
                          Description Ar :
                        </label>
                        <div className="col-md-6">
                        {data.description ?(
                          <p>
                            <strong><td  dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.description.ar)}} ></td></strong>
                          </p>
                        ) : null}
                        </div>
                      </Row>
                    
                      {/* </Tab> */}

                      {/* </Tabs> */}
                
                </Col>
                      </Col>
                      {/* </Tab> */}

                      {/* </Tabs> */}
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormElements
