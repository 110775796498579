import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { isEmpty } from "lodash"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import { Draggable } from "@fullcalendar/interaction"
import { Link } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  addNewEvent,
  deleteEvent,
  getCategories,
  getEvents,
  updateEvent,
} from "../../store/actions"
import "@fullcalendar/bootstrap/main.css"
import Loader from "components/Common/Loader"
import {
  getTechnician,
  editTechnician,
  getDeletedTechnician,
} from "common/Services/dbServices"
const Calender = props => {
  const { events, categories } = props
  const [modal, setModal] = useState(false)
  const [popModal, setPopModal] = useState(false)
  const [event, setEvent] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [editedTechnician, setEditedTechnician] = useState()
  const [deletedItem, setDeletedItem] = useState()
  const [editId, setEditId] = useState()
  const [technicianData, setTechnicianData] = useState()
  const [technician, setTechnician] = useState()
  const [id, setId] = useState()
  const [email, setEmail] = useState()
  const [mobileNumber, setMobileNumber] = useState()
  const [name, setName] = useState()
  const [employee, setEmployee] = useState()
  const [password, setPassword] = useState()
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    techniciansList()
  }, [editedTechnician, deletedItem])

  const techniciansList = async () => {
    try {
      setLoading(true)
      const response = await getTechnician()
      if (response) {
        setTechnicianData(response)
      }
    } catch (e) {
      setLoading(false)
      alert("Sorry,Something went wrong")
    }
    setLoading(false)
  }

  const editTechnicianData = async e => {
    e.preventDefault()

    try {
      const editTechnicianList = await editTechnician({
        editId,
        mobileNumber,
        name,
        employee,
      })
      setEditedTechnician(editTechnicianList)
      setModal(!modal)
    } catch (error) {
      console.log("address::", error.message)
      alert("Failed to Update")
      setModal(!modal)
    }
  }

  const toggle = result => {

    setEditId(result._id)
    setEmail(result.email)
    setMobileNumber(result.mobileNumber)
    setName(result.name)
    setEmployee(result.employeeId)
    setPassword(result.password)

    setTechnician(result)
    setModal(!modal)
  }

  const popToggle = itemId => {
    setId(itemId)
    setPopModal(!popModal)
  }

  const deleteItemData = async () => {
    const getDeleted = await getDeletedTechnician({ id })
    setDeletedItem(getDeleted)
    setPopModal(!popModal)
  }

  useEffect(() => {
    const { onGetCategories, onGetEvents } = props
    onGetCategories()
    onGetEvents()

    new Draggable(document.getElementById("external-events"), {
      itemSelector: ".external-event",
    })
  }, [])

  useEffect(() => {
    if (!modal && !isEmpty(event) && !!isEdit) {
      setTimeout(() => {
        setEvent({})
        setIsEdit(false)
      }, 500)
    }
  }, [modal, event])

  document.title = "Technicians | Sayartak "
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Row>
            <Col sm={6}>
              <Breadcrumbs title="Sayartak" breadcrumbItem="Technicians" />
            </Col>
            <Col sm={6}>
              <div className="float-end d-none d-md-block">
                <Link
                  to="/add-new"
                  className="btn btn-secondary"
                  style={{ marginTop: 10 }}
                >
                  Add New
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <div id="external-events" className="mt-12">
                        <h4 className="card-title mb-4">List of Technicians</h4>
                        <div className="table-responsive">
                          <table className="table table-hover table-centered table-nowrap mb-0">
                            <thead className="table-light">
                              <tr>
                                <th scope="col">Email</th>
                                <th scope="col">User Name</th>
                                <th scope="col">Employee Id</th>
                                <th scope="col">Mobile Number</th>
                                <th scope="col" colSpan={2}>
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {isLoading ? (
                                <tr>
                                  <td colSpan="8">
                                    <Loader />
                                  </td>
                                </tr>
                              ) : (
                                technicianData?.map(item => {
                                  return (
                                    <tr>
                                      <td>{item.email}</td>
                                      <td>{item.name}</td>
                                      <td>{item.employeeId}</td>
                                      <td>{item.mobileNumber}</td>
                                      <td>
                                        {" "}
                                        <i
                                          onClick={() => toggle(item)}
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          className="fas fa-edit"
                                        ></i>
                                      </td>
                                      <td>
                                        {" "}
                                        <i
                                          onClick={() => popToggle(item._id)}
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          className="fas fa-trash"
                                        ></i>
                                      </td>
                                    </tr>
                                  )
                                })
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          <Col>
            <Modal isOpen={modal} toggle={toggle} centered size="lg">
              <ModalHeader className="mt-0" toggle={toggle}>
                Edit Technician
              </ModalHeader>
              <ModalBody>
                <Form
                  style={{ marginTop: 10 }}
                  className="row g-3 needs-validation"
                  // onSubmit={onSubmit}
                >
                  <Row className="mb-3">
                    <label className="col-sm-2 col-form-label">Email</label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="email"
                        placeholder="Email"
                        name="email"
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        readOnly
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-sm-2 col-form-label">
                      Mobile Number
                    </label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        placeholder="Mobile Number"
                        name="mobileNumber"
                        onChange={e => setMobileNumber(e.target.value)}
                        value={mobileNumber}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-sm-2 col-form-label">Name</label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        placeholder="Name"
                        name="name"
                        onChange={e => setName(e.target.value)}
                        value={name}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-sm-2 col-form-label">
                      EmployeeId
                    </label>
                    <div className="col-md-6">
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        placeholder="Empolyee"
                        name="employeeId"
                        onChange={e => setEmployee(e.target.value)}
                        value={employee}
                      />
                    </div>
                  </Row>
                  <div className="d-flex flex-wrap gap-2">
                    <Button color="primary" onClick={editTechnicianData}>
                      Submit
                    </Button>{" "}
                    <Button
                      type="reset"
                      color="secondary"
                      onClick={() => setModal(!modal)}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              </ModalBody>
            </Modal>
          </Col>

          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center"></div>
            <Modal isOpen={popModal} toggle={popToggle} size="sm">
              <ModalHeader className="mt-0" toggle={popToggle}>
                Delete
              </ModalHeader>
              <ModalBody>
                <p>Are you sure to delete this item</p>
                <div className="d-flex flex-wrap gap-2">
                  <Button
                    type="submit"
                    color="secondary"
                    onClick={() => {
                      popToggle()
                    }}
                  >
                    Cancel
                  </Button>{" "}
                  <Button type="reset" color="danger" onClick={deleteItemData}>
                    Delete
                  </Button>
                </div>
              </ModalBody>
            </Modal>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}

Calender.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
}

const mapStateToProps = ({ calendar }) => ({
  events: calendar.events,
  categories: calendar.categories,
})

const mapDispatchToProps = dispatch => ({
  onGetEvents: () => dispatch(getEvents()),
  onGetCategories: () => dispatch(getCategories()),
  onAddNewEvent: event => dispatch(addNewEvent(event)),
  onUpdateEvent: event => dispatch(updateEvent(event)),
  onDeleteEvent: event => dispatch(deleteEvent(event)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Calender)
