import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { Tab, Tabs } from "react-bootstrap"

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  Input,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { createBlockedDates,getOneTechnician } from "common/Services/dbServices"
import { useTechnician } from "common/Hooks/queryCalls"
import {
  useParams
} from "react-router-dom";
const FormElements = () => {

  let params = useParams();
  const [tabKey, initTabKey] = useState("one")
  const location = useLocation()
  // const data = location.state
  const [modal, setModal] = useState(false)
  const [blockedDate, setBlockedDate] = useState(false)
  const [id, setId] = useState(false)
  const [data, setData] = useState(false)
  const { data: technicians } = useTechnician()
  const [selectedTechnician, setSelectedTechnician] = useState( )

  useEffect(() => {
    getSlotData()
  }, [])

  const getSlotData = async () => {
    try {
      const response = await getOneTechnician(params.id);
      setData(response[0])
      setSelectedTechnician(
        technicians?.filter(items =>
          response[0]?.selectedTechnicians?.find(
            v => v.toString() === items.value.toString()
          )
        )
      )
     
    } catch (e) {
      alert("Sorry,Something went wrong")
    }
  }
  // console.log('selectedTechnician',selectedTechnician)
  document.title = "Slot Details | Sayartak"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="Sayartak"
            title="Slot"
            breadcrumbItem="Slot Details"
            url="/Slot"
          />

          <Tabs activeKey={tabKey} onSelect={e => initTabKey(e)}>
            <Tab eventKey="one" title="Details">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <div class="details-section">
                        <div class="col-md-6">
                          <div class="details-section-item">
                            <label class="details-section-item-label">
                              Slot
                            </label>
                            <div class="details-section-item-content">
                              <span>{data?.name || "-"}</span>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-12">
                            <hr style={{ width: "100%" }} />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="details-section-item">
                            <label class="details-section-item-label">
                              Start Time
                            </label>
                            <div class="details-section-item-content">
                              <span>{data?.startTime || "-"}</span>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-12">
                            <hr style={{ width: "100%" }} />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="details-section-item">
                            <label class="details-section-item-label">
                              End Time
                            </label>
                            <div class="details-section-item-content">
                              <span>{data?.endTime || "-"}</span>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-12">
                            <hr style={{ width: "100%" }} />
                          </div>
                        </div>
                        <div class="col-md-7">
                          <div class="details-section-item">
                            <label class="details-section-item-label">
                              Selected Technician
                            </label>
                            <div class="details-section-item-content">
                              <span>{selectedTechnician?.map(sc => sc.label+' , ')}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormElements
