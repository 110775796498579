import { useQuery } from "react-query"
import * as Realm from "realm-web"
import { app } from "Context/userContext" // Update this import
import envConfig from "../../env/env.json"
import {
  getAudience,
  getTechnicianAudience,
  getMessages,
  getDailySalesReport,
  getMonthlySalesReport,
  getAnnualSalesReport,
  getDateWiseSalesReport,
  getServices,
  getServiceCategories,
  getBundleServices,
  getOneBundleService,
  getBanner,
  getVehicleBrand,
} from "common/Services/dbServices"

const {
  BSON: { ObjectId },
} = Realm

const { MONGODB_CLIENT, MONGODB_APP_ID, MONGODB_DB } = envConfig

const realmConfig = {
  client: MONGODB_CLIENT,
  appId: MONGODB_APP_ID,
  db: MONGODB_DB,
}

const getDBInstance = appInstance => {
  if (!appInstance || !appInstance.currentUser) {
    throw new Error("User not authenticated.")
  }

  return appInstance.currentUser
    .mongoClient(realmConfig.client)
    .db(realmConfig.db)
}

export const useTechnician = () => {
  try {
    const getTechnicians = () => {
      return getDBInstance(app)
        .collection("Technician")
        .find({}, { projection: { _id: 1, name: 1 } })
    }

    return useQuery({
      queryKey: ["technicians"],
      queryFn: getTechnicians,
      select: data => {
        const technicianList = []
        data.map(technician => {
          let obj = {
            value: technician._id,
            label: technician.name,
          }
          technicianList.push(obj)
        })
        return technicianList
      },
    })
  } catch (error) {
    console.error("Error in useTechnician:", error)
    throw error
  }
}
const useAudienceQuery = (queryKey, queryFn) => {
  return useQuery({
    queryKey: [queryKey],
    queryFn,
    select: data => {
      return data.flatMap(item => (item ? item.player_id : null))
    },
  })
}

export const useAudience = () => {
  return useAudienceQuery("Audience", getAudience)
}

export const useTechnicianAudience = () => {
  return useAudienceQuery("TechnicianAudience", getTechnicianAudience)
}

export const useMessages = () => {
  return useQuery({
    queryKey: ["Messages"],
    queryFn: getMessages,
  })
}
export const useBanner = id => {

  try {
    return useQuery({
      queryKey: ["banner"],
      queryFn: () => getBanner(id),
    })
  } catch (error) {
    console.error("Error in useBanner:", error)
    throw error
  }
}
export const useDailySalesReport = ({ date }) => {
  return useQuery({
    queryKey: ["getDailySalesReport"],
    queryFn: () => getDailySalesReport({ date }),
  })
}

export const useMonthlySalesReport = ({ month }) => {
  return useQuery({
    queryKey: ["getMonthlySalesReport"],
    queryFn: () => getMonthlySalesReport({ month }),
  })
}

export const useAnnualSalesReport = ({ year }) => {
  return useQuery({
    queryKey: ["getAnnualSalesReport"],
    queryFn: () => getAnnualSalesReport({ year }),
  })
}

export const useDateWiseSalesReport = ({ startOfDay, endOfDay }) => {
  return useQuery({
    queryKey: ["getDateWiseSalesReport"],
    queryFn: () => getDateWiseSalesReport({ startOfDay, endOfDay }),
  })
}
export const useService = () => {
  const getServices = async () => {
    try {
      const response = await getDBInstance(app)
        .collection("Services")
        .find({}, { projection: { _id: 1, "name.en": 1 } })
      return response
    } catch (e) {
      console.error("Error = ", e)
    }
  }
  return useQuery({
    queryKey: ["Services"],
    queryFn: getServices,
    select: data => {
      const serviceData = []
      data?.map(v => {
        const obj = {
          value: v?._id,
          label: v?.name.en,
        }
        return serviceData.push(obj)
      })
      return serviceData
    },
  })
}
export const useServiceCategories = () => {
  return useQuery({
    queryKey: ["serviceCategories"],
    queryFn: getServiceCategories,
    select: data => {
      const serviceCategories = []
      data?.map(v => {
        const obj = {
          value: v?._id,
          label: v?.name.en,
        }
        return serviceCategories.push(obj)
      })
      return serviceCategories
    },
  })
}

export const useBundleServices = () => {
  return useQuery({
    queryKey: ["getBundleServices"],
    queryFn: getBundleServices,
  })
}

export const useOneBundleService = ({ id }) => {
  return useQuery({
    queryKey: ["getOneBundleService"],
    queryFn: () => getOneBundleService({ id }),
  })
}
