import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { IKImage } from "imagekitio-react"
import Loader from "components/Common/Loader"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  addNewEvent,
  deleteEvent,
  getCategories,
  getEvents,
  updateEvent,
} from "../../store/actions"
import DeleteModal from "components/Common/DeleteModal";
//css
import "@fullcalendar/bootstrap/main.css"
import {
  urlEndpoint
} from "../../components/Common/imageKit"
import {
  getDeletedVehicle,
  getVehicleCollection,
} from "common/Services/dbServices"
import logosmImg from "../../assets/images/logo.png"
import ReactPaginate from 'react-paginate';
const Calender = props => {
  const { events, categories } = props
  const [popModal, setPopModal] = useState(false)
  const navigate = useNavigate()
  const [data, setData] = useState()
  const [id, setId] = useState()
  const [deletedItem, setDeletedItem] = useState()
  const [isLoading, setLoading] = useState(false)
  const [pageNumber, setPageNumber] = useState(0); // Current page number
  const carPerPage = 10; // Number of customers to display per page

  useEffect(() => {
    CarList()
  }, [deletedItem])

  const CarList = async () => {
    try {
      setLoading(true)
      const response = await getVehicleCollection()
      if (response) {
        setData(response)
      }
    } catch (e) {
      setLoading(false)
      alert("Sorry,Something went wrong")
    }
    setLoading(false)
  }

  const popToggle = res => {
    setId(res)
    setPopModal(!popModal)
  }
  const deleteItem = async () => {
    const getDeleted = await getDeletedVehicle({ id })
    setDeletedItem(getDeleted)
    setPopModal(!popModal)
  }

  document.title = "Cars | Sayartak "


  const pageCount = Math.ceil(data?.length / carPerPage);

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  const displayedCars = data?.slice(
    pageNumber * carPerPage,
    (pageNumber + 1) * carPerPage
  );

  document.title = "Customer | Sayartak";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Row>
            <Col sm={6}>
              <Breadcrumbs title="Sayartak" breadcrumbItem="Car" />
            </Col>
            <Col sm={6}>
              <div className="float-end d-none d-md-block">
                <Link
                  to="/add-new-car"
                  className="btn btn-secondary"
                  style={{ marginTop: 10 }}
                >
                  Add New
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <div id="external-events" className="mt-12">
                        <h4 className="card-title mb-4">List of Cars</h4>
                        <div className="table-responsive">
                          <table className="table table-hover table-centered table-nowrap mb-0">
                            <thead className="table-light">
                              <tr>
                                {/* <th scope="col">(#) Id</th> */}
                                <th scope="col">Car Name</th>
                                <th scope="col">Car Icon</th>
                                <th scope="col">Transmission Type</th>
                                <th scope="col">Fuel Type</th>
                                <th scope="col">Order No</th>
                                <th scope="col" colSpan={3}>
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {isLoading ? (
                                <tr>
                                  <td colSpan="8">
                                    <Loader />
                                  </td>
                                </tr>
                              ) : (
                                displayedCars?.map((item, index) => {
                                  return (
                                    <tr>
                                      <td
                                        onClick={() =>
                                          navigate("/car-details", {
                                            state: item,
                                          })
                                        }
                                      >
                                        {item.name.en == null
                                          ? "Empty Value"
                                          : item.name.en}{" "}
                                      </td>
                                      <td
                                        onClick={() =>
                                          navigate("/car-details", {
                                            state: item,
                                          })
                                        }
                                      >
                                        <div>
                                          {item.images &&
                                            item.images.filePath ? (
                                            <IKImage
                                              urlEndpoint={urlEndpoint}
                                              path={item.images.filePath}
                                              width="35px"
                                              height="30px"
                                            />
                                          ) : (
                                            <img
                                              src={logosmImg}
                                              alt=""
                                              className="img-thumbnail-sm"
                                              width="35px"
                                              height="35px"
                                            />
                                          )}
                                        </div>
                                      </td>
                                      <td
                                        onClick={() =>
                                          navigate("/car-details", {
                                            state: item,
                                          })
                                        }
                                      >
                                        {item && item.availableTransmissionTypes
                                          ? item.availableTransmissionTypes.map(
                                            (types, index) => {
                                              return (
                                                <td>
                                                  {types.label == null
                                                    ? "Empty Value"
                                                    : types.label}{" "}
                                                  ,
                                                </td>
                                              )
                                            }
                                          )
                                          : null}
                                      </td>
                                      <td
                                        onClick={() =>
                                          navigate("/car-details", {
                                            state: item,
                                          })
                                        }
                                      >
                                        {item && item?.availableFuelTypes
                                          ? item?.availableFuelTypes?.map(
                                            types => {
                                              return (
                                                <td>
                                                  {types.label == null
                                                    ? "Empty Value"
                                                    : types.label}{" "}
                                                  ,
                                                </td>
                                              )
                                            }
                                          )
                                          : null}
                                      </td>
                                      <td
                                        onClick={() =>
                                          navigate("/car-details", {
                                            state: item,
                                          })
                                        }
                                      >
                                        {item.order == null
                                          ? "Empty Value"
                                          : item.order}{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        <i
                                          onClick={() =>
                                            navigate("/car-details", {
                                              state: item,
                                            })
                                          }
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          className="fas fa-eye"
                                        ></i>
                                      </td>
                                      <td>
                                        {" "}
                                        <i
                                          onClick={() =>
                                            navigate(`/add-new-car/${item?._id}`)
                                          }
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          className="fas fa-edit"
                                        ></i>
                                      </td>
                                      <td>
                                        {" "}
                                        <i
                                          onClick={() => popToggle(item._id)}
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          className="fas fa-trash"
                                        ></i>
                                      </td>
                                    </tr>
                                  )
                                })
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <ReactPaginate
                  previousLabel="Previous"
                  nextLabel="Next"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  pageCount={pageCount}
                  onPageChange={handlePageChange}
                  containerClassName="pagination"
                  activeClassName="active"
                />
              </div>
            </Col>
          </Row>
          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center"></div>
            <DeleteModal
              isOpen={popModal}
              toggle={() => setPopModal(!popModal)}
              onConfirmDelete={deleteItem}
            />
          </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}

Calender.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
}

const mapStateToProps = ({ calendar }) => ({
  events: calendar.events,
  categories: calendar.categories,
})

const mapDispatchToProps = dispatch => ({
  onGetEvents: () => dispatch(getEvents()),
  onGetCategories: () => dispatch(getCategories()),
  onAddNewEvent: event => dispatch(addNewEvent(event)),
  onUpdateEvent: event => dispatch(updateEvent(event)),
  onDeleteEvent: event => dispatch(deleteEvent(event)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Calender)
