import * as Realm from "realm-web"
import { app, gApp } from "Context/userContext"
import bcrypt from "bcryptjs"
import envConfig from "../../env/env.json"

export const {
  BSON: { ObjectId },
} = Realm
const Config = {
  realm: {
    client: envConfig.MONGODB_CLIENT,
    appId: envConfig.MONGODB_APP_ID,
    db: envConfig.MONGODB_DB,
  },
}

export const generalLogin = async () => {
  const gUser = await gApp.logIn(
    Realm.Credentials.apiKey(envConfig.MONGODB_GENERAL_APP_KEY)
  )
  return gUser
}

export const getDBInstance = appInstance => {
  return appInstance.currentUser
    .mongoClient(Config.realm.client)
    .db(Config.realm.db)
}

const getCollection = collectionName => {
  if (!app || !app.currentUser) {
    return null;
  }

  return getDBInstance(app).collection(collectionName);
};

export const performCRUDOperation = async (collectionName, operation, data) => {

  if (!app && !app.currentUser) {
    return;
  }

  const collection = getCollection(collectionName);

  try {

    switch (operation) {
      case 'create':
        return await collection.insertOne(data);

      case 'read':
        if (data?.id) {
          return await collection.findOne({ _id: ObjectId(data?.id) });
        } else {
          return await collection.find({})
        }

      case 'update':
        const { _id, updatedFields } = data;
        return await collection.updateOne({ _id: ObjectId(_id) }, { $set: updatedFields });


      case 'delete':
        return await collection.deleteOne({ _id: data?.id })

      default:
        throw new Error("Invalid operation");

    }
  } catch (e) {
    console.error('Error :', e);
    throw e;
  }

}

// Banner Section
export const getBanner = async (id) => {
  return await performCRUDOperation('Banner', 'read', { id })
};

export const getDeleteBanner = async ({ id }) => {
  return await performCRUDOperation("Banner", 'delete', { id })
}

// Vehicle Brand create Section
export const createNewBrand = async ({ values, res }) => {
  const result = await performCRUDOperation('VehicleBrand', 'create', {
    name: { en: values.name },
    images: res,
    order: Number(values.order),
    owner_id: app.currentUser.id,
  })
  return result;
}

// Vehicle Brand read Section
export const getVehicleBrand = async id => {

  try {
    return await performCRUDOperation('VehicleBrand', 'read', { id })
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

// Vehicle Brand delete Section
export const getDeleted = async ({ id }) => {
  return await performCRUDOperation('VehicleBrand', 'delete', { id })
}

// Vehicle Brand update Section
export const editVehicleBrand = async ({ id, res, values }) => {

  const result = await performCRUDOperation('VehicleBrand', 'update', {
    _id: id,
    updatedFields: {
      name: { en: values.name },
      images: res,
      order: Number(values.order),
    }
  })
  return result
}

// Vehicle read Section
export const getVehicleCollection = async (id) => {
  return await performCRUDOperation('Vehicle', 'read', { id })
}


// Vehicle Create Section
export const createVehicle = async ({ values, res }) => {

  const result = await performCRUDOperation('Vehicle', 'create', {
    name: { en: values.addCarName },
    images: res,
    order: Number(values.selectOrderNo),
    owner_id: app.currentUser.id,
    availableFuelTypes: values.selectFuelType,
    availableTransmissionTypes: values.selectTransmissionValue,
    brandID: ObjectId(values.selectCarBrand),
    carType: ObjectId(values.selectCarType),
  });

  return result;
}
// Vehicle Update Section
export const editVehicle = async ({ id, res, values }) => {
  const result = await performCRUDOperation('Vehicle', 'update', {
    _id: id,
    updatedFields: {
      name: { en: values.addCarName },
      images: res,
      order: Number(values.selectOrderNo),
      owner_id: app.currentUser.id,
      availableFuelTypes: values.selectFuelType,
      availableTransmissionTypes: values.selectTransmissionValue,
      brandID: values.selectCarBrand,
      carType: ObjectId(values.selectCarType),
    }
  })
  return result
}

// Vehicle Delete Section
export const getDeletedVehicle = async ({ id }) => {
  return await performCRUDOperation('Vehicle' , 'delete' , {id})
}

export const createServiceCategory = async ({
  serviceNameEn,
  serviceNameAr,
  serviceOrder,
  res,
}) => {
  if (!app || !app.currentUser) {
    return
  }

  const result = await getDBInstance(app)
    .collection("ServiceTypes")
    .insertOne({
      name: { en: serviceNameEn, ar: serviceNameAr },
      images: res,
      order: Number(serviceOrder),
    })
  // console.log(app.currentUser.id)
  // console.log("res::", result)
  return result
}

export const createServices = async ({
  serviceNameEn,
  serviceNameAr,
  technicalName,
  servicePrice,
  serviceDescriptionEn,
  serviceDescriptionAr,
  res,
  serviceSale,
  serviceCategoryId,
  serviceBrandType,
  serviceFuelTypes,
  selectRelatedServices,
  selectCarServices,
  isServiceActive,
  isMultiple,
}) => {
  // console.log("in db services", selectRelatedServices)
  if (!app || !app.currentUser) {
    return
  }
  const data = {
    serviceTypeId: `${serviceCategoryId}`,
    name: { en: serviceNameEn, ar: serviceNameAr },
    technicalName: technicalName,
    price: Number(servicePrice),
    description: { en: serviceDescriptionEn, ar: serviceDescriptionAr },
    images: res,
    salePrice: Number(serviceSale),
    serviceBrandType: serviceBrandType,
    serviceFuelTypes: serviceFuelTypes,
    relatedServices: selectRelatedServices,
    selectedVehicles: selectCarServices,
    active: isServiceActive,
    isMultiple: isMultiple,
  }

  const result = await getDBInstance(app).collection("Services").insertOne(data)
  // console.log(app.currentUser.id)
  return result
}
export const getServiceCategory = async () => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app).collection("ServiceTypes").find({})
}

export const createServiceBrand = async ({ values, res }) => {
  if (!app || !app.currentUser) {
    return
  }
  const result = await getDBInstance(app)
    .collection("ServicesBrand")
    .insertOne({
      name: { en: values.serviceBrandEn, ar: values.serviceBrandAr },
      images: res,
      order: Number(values.serviceBrandOrder),
    })
  // console.log(app.currentUser.id)
  return result
}

export const createServiceType = async ({ values }) => {
  if (!app || !app.currentUser) {
    return
  }
  const result = await getDBInstance(app)
    .collection("ServicesSubType")
    .insertOne({
      name: { en: values.serviceTypeEn, ar: values.serviceTypeAr },
      description: {
        en: values.serviceTypeDescriptionEn,
        ar: values.serviceTypeDescriptionAr,
      },
      order: Number(values.serviceTypeOrder),
    })
  // console.log(app.currentUser.id)
  return result
}

export const getServicesList = async () => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app)
    .collection("Services")
    .aggregate([
      {
        $lookup: {
          from: "Vehicle",
          localField: "selectedVehicles",
          foreignField: "_id",
          as: "getVehicles",
        },
      },
    ])
}

export const getService = async id => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app)
    .collection("Services")
    .aggregate([
      {
        $match: {
          _id: ObjectId(id),
        },
      },
      {
        $lookup: {
          from: "Vehicle",
          localField: "selectedVehicles",
          foreignField: "_id",
          as: "getVehicles",
        },
      },
    ])
}



export const getServiceBrandData = async () => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app).collection("ServicesBrand").find({})
}

export const getServiceTypeData = async () => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app).collection("ServicesSubType").find({})
}





export const getDeletedServideCateg = async ({ id }) => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app)
    .collection("ServiceTypes")
    .deleteOne({ _id: id })
}
export const getDeletedServices = async ({ id }) => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app).collection("Services").deleteOne({ _id: id })
}
export const getDeletedServiceBrand = async ({ id }) => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app)
    .collection("ServicesBrand")
    .deleteOne({ _id: id })
}
export const getDeletedServiceTypes = async ({ id }) => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app)
    .collection("ServicesSubType")
    .deleteOne({ _id: id })
}


export const editServiceCateg = async ({
  editId,
  serviceCatgNameEn,
  serviceCatgNameAr,
  editedOrderValue,
  res,
}) => {
  if (!app || !app.currentUser) {
    return
  }

  const result = await getDBInstance(app)
    .collection("ServiceTypes")
    .updateOne(
      { _id: editId },
      {
        $set: {
          name: { en: serviceCatgNameEn, ar: serviceCatgNameAr },
          images: res,
          order: Number(editedOrderValue),
        },
      }
    )
  return result
}

export const editServiceBrand = async ({
  editId,
  serviceBrandNameEn,
  serviceBrandNameAr,
  editedorder,
  res,
}) => {
  if (!app || !app.currentUser) {
    return
  }

  const result = await getDBInstance(app)
    .collection("ServicesBrand")
    .updateOne(
      { _id: editId },
      {
        $set: {
          name: { en: serviceBrandNameEn, ar: serviceBrandNameAr },
          images: res,
          order: Number(editedorder),
        },
      }
    )
  return result
}

export const editServiceTypes = async ({
  editId,
  serviceTypeNameEn,
  serviceTypeNameAr,
  editedOrderValue,
  serviceTypeDesEn,
  serviceTypeDesAr,
}) => {
  if (!app || !app.currentUser) {
    return
  }

  const result = await getDBInstance(app)
    .collection("ServicesSubType")
    .updateOne(
      { _id: editId },
      {
        $set: {
          name: { en: serviceTypeNameEn, ar: serviceTypeNameAr },
          description: { en: serviceTypeDesEn, ar: serviceTypeDesAr },
          order: Number(editedOrderValue),
        },
      }
    )
  return result
}


export const editServices = async ({
  editId,
  editServiceNameEn,
  editServiceNameAr,
  technicalName,
  editServicePrice,
  serviceDescriptionEn,
  serviceDescriptionAr,
  res,
  editServiceSale,
  editServiceCategoryId,
  serviceBrandType,
  serviceFuelTypes,
  selectRelatedServices,
  selectCarServices,
  isServiceActive,
  isMultiple,
}) => {
  if (!app || !app.currentUser) {
    return
  }
  const data = {
    name: { en: editServiceNameEn, ar: editServiceNameAr },
    technicalName: technicalName,
    price: Number(editServicePrice),
    description: { en: serviceDescriptionEn, ar: serviceDescriptionAr },
    images: res,
    salePrice: Number(editServiceSale),
    serviceTypeId: `${editServiceCategoryId}`,
    serviceBrandType: serviceBrandType,
    serviceFuelTypes,
    relatedServices: selectRelatedServices,
    selectedVehicles: selectCarServices,
    active: isServiceActive,
    isMultiple: isMultiple,
  }

  const result = await getDBInstance(app).collection("Services").updateOne(
    { _id: editId },
    {
      $set: data,
    }
  )
  return result
}


export const getSlots = async () => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app).collection("Slots").find({})
}
export const getSlot = async slotId => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app)
    .collection("Slots")
    .find({ _id: ObjectId(slotId) })
}

export const editSlot = async ({
  editId,
  slotName,
  startTime,
  endTime,
  selectedTechnician,
}) => {
  if (!app || !app.currentUser) {
    return
  }

  const result = await getDBInstance(app)
    .collection("Slots")
    .updateOne(
      { _id: editId },
      {
        $set: {
          name: slotName,
          startTime: startTime,
          endTime: endTime,
          selectedTechnicians: selectedTechnician,
        },
      }
    )
  return result
}

export const createNewSlot = async ({ values }) => {
  if (!app || !app.currentUser) {
    return
  }

  const result = await getDBInstance(app)
    .collection("Slots")
    .insertOne({
      name: values.slotName,
      startTime: values.startTime,
      endTime: values.endTime,
      selectedTechnicians: values.selectedTechnician.map(sc => sc.value),
    })

  return result
}

export const getSlotDeleted = async ({ deleteId }) => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app)
    .collection("Slots")
    .deleteOne({ _id: deleteId })
}

export const createBlockedDates = async ({ selectSlot, blockedDate }) => {
  if (!app || !app.currentUser) {
    return
  }

  const result = await getDBInstance(app).collection("BlockedSlots").insertOne({
    slotId: selectSlot,
    date: blockedDate,
  })

  return result
}

export const getBlockedSlots = async () => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app).collection("BlockedSlots").find({})
}

export const getDeleteBlockedSlot = async ({ id }) => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app)
    .collection("BlockedSlots")
    .deleteOne({ _id: id })
}

export const createNewCMS = async ({
  nameEn,
  nameAr,
  slugEn,
  convertedContent,
  convertedContentAr,
}) => {
  if (!app || !app.currentUser) {
    return
  }

  const result = await getDBInstance(app)
    .collection("Pages")
    .insertOne({
      name: { en: nameEn, ar: nameAr },
      slug: slugEn,
      description: { en: convertedContent, ar: convertedContentAr },
    })
  return result
}

export const getCMSList = async () => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app).collection("Pages").find({})
}

export const editCMS = async ({
  editId,
  nameEn,
  nameAr,
  slugEn,
  convertedContent,
  convertedContentAr,
}) => {
  if (!app || !app.currentUser) {
    return
  }

  const result = await getDBInstance(app)
    .collection("Pages")
    .updateOne(
      { _id: ObjectId(editId) },
      {
        $set: {
          name: { en: nameEn, ar: nameAr },
          slug: slugEn,
          description: { en: convertedContent, ar: convertedContentAr },
        },
      }
    )
  return result
}
export const getDeletedCMS = async ({ id }) => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app).collection("Pages").deleteOne({ _id: id })
}

export const getOrderList = async () => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app)
    .collection("Orders")
    // .find({},{limit: 15})
    .aggregate([
      {
        $lookup: {
          from: "Customers",
          localField: "customerid",
          foreignField: "_id",
          as: "getCustomer",
        },
      },
      // {
      //   $lookup: {
      //     from: "Technician",
      //     localField: "assignedTechnicianid",
      //     foreignField: "_id",
      //     as: "getTechnician",
      //   },
      // },

      {
        $unwind: "$getCustomer",
      },
      // {
      //   $unwind: "$getTechnician",
      // },
      {
        $sort: {
          _id: -1,
        },
      },
    ])
}
export const getOrderCount = async () => {
  if (!app || !app.currentUser) {
    return
  }

  const result = await getDBInstance(app).collection("Orders").count()

  return result
}

export const createOption = async ({ code, label, values }) => {
  if (!app || !app.currentUser) {
    return
  }

  const result = await getDBInstance(app).collection("Options").insertOne({
    code: code,
    label: label,
    value: values,
  })
  return result
}
export const getOptions = async () => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app).collection("Options").find()
}

export const editOptions = async ({ code, values }) => {
  if (!app || !app.currentUser) {
    return
  }

  const result = await getDBInstance(app)
    .collection("Options")
    .updateOne(
      { code: code },
      {
        $set: {
          value: values,
        },
      }
    )
  return result
}



export const createNewTechnicians = async ({ values }) => {
  if (!app || !app.currentUser) {
    return
  }
  let mobileNumber = await getDBInstance(app)
    .collection("Technician")
    .findOne({ mobileNumber: values.mobileNumber })
  // console.log("mobileNumber=", mobileNumber)
  if (!mobileNumber) {
    //console.log('values.password=',values.password)
    const salt = bcrypt.genSaltSync(10) // Generate a salt
    const hashedPassword = bcrypt.hashSync(values.password, salt)

    //console.log('hashedPassword=',hashedPassword)

    const result = await getDBInstance(app).collection("Technician").insertOne({
      email: values.email,
      mobileNumber: values.mobileNumber,
      name: values.name,
      employeeId: values.employeeId,
      password: hashedPassword,
    })

    return result
  }
}

export const getTechnician = async () => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app).collection("Technician").find({})
}

export const editTechnician = async ({
  editId,
  // email,
  mobileNumber,
  name,
  employee,
  // password
}) => {
  if (!app || !app.currentUser) {
    return
  }

  const result = await getDBInstance(app)
    .collection("Technician")
    .updateOne(
      { _id: editId },
      {
        $set: {
          // email: email,
          mobileNumber: mobileNumber,
          name: name,
          employeeId: employee,
          // password: password
        },
      }
    )
  return result
}

export const getDeletedTechnician = async ({ id }) => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app)
    .collection("Technician")
    .deleteOne({ _id: id })
}

export const getCustomer = async ({ id }) => {
  if (!app || !app.currentUser) {
    return
  }
  // console.log("OrderId getCustomer   =", id, typeof id)
  return await getDBInstance(app)
    .collection("Orders")
    .aggregate([
      {
        $match: {
          _id: ObjectId(id),
        },
      },
      {
        $lookup: {
          from: "Customers",
          localField: "customerid",
          foreignField: "_id",
          as: "getCustomer",
        },
      },
      {
        $unwind: "$getCustomer",
      },
    ])
}

export const getCus = async ({ id }) => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app)
    .collection("Customers")
    .find({ _id: ObjectId(id) })
}
export const editOrderDetails = async ({
  editId,
  selectOrderStatus,
  selectTechnician,
}) => {
  if (!app || !app.currentUser) {
    return
  }

  const params = {
    _id: editId,
    orderStatus: selectOrderStatus,
    technicianId: selectTechnician,
  }
  const gUser = await generalLogin()

  const result = await gUser?.callFunction("orders", {
    functionName: "orderTechnicianAndStatusChange",
    params: params,
  })

  return result
}

export const sendInvoiceDetails = async ({ editId }) => {
  if (!app || !app.currentUser) {
    return
  }
  // console.log("editId:", editId)
  let results = await app.currentUser.callFunction("invoiceFunction", {
    functionName: "sendOrderStatus",
    params: { orderId: ObjectId(editId) },
  })

  return results
}

// chat
// export const createNewChat = async ({ orderId, owner_id }) => {
//   if (!app || !app.currentUser) {
//     return
//   }
//   const chat = await getDBInstance(app)
//     .collection("Chat")
//     .find({ orderId: ObjectId(orderId) })
//   console.log("lenght ===",chat.length)
//   if (chat.length === 0) {
//     // console.log('if')
//     let obj = []
//     const saveChat = await getDBInstance(app)
//       .collection("Chat")
//       .insertOne({
//         orderId: ObjectId(orderId),
//         owner_id: owner_id,
//         messages: obj,
//         default: false,
//       })
//     return saveChat
//   }
// }

export const createNewChat = async ({ orderId, owner_id }) => {
  if (!app || !app.currentUser) {
    return
  }

  const params = {
    orderId,
    owner_id,
  }
  const gUser = await generalLogin()
  const result = await gUser?.callFunction("chat", {
    functionName: "createChat",
    params: params,
  })

  return result
}

export const createNewMessage = async ({ message, orderId, owner_id }) => {
  if (!app || !app.currentUser) {
    return
  }

  const params = {
    message,
    orderId,
    owner_id,
    senderEntityId: ObjectId(app.currentUser.id),
  }
  const gUser = await generalLogin()
  const result = await gUser?.callFunction("chat", {
    functionName: "createMessage",
    params: params,
  })

  return result
}

export const updateNewChat = async ({ chatId }) => {
  if (!app || !app.currentUser) {
    return
  }
  // console.log("updateNewChat chatId==", chatId)

  const result = await getDBInstance(app)
    .collection("Chat")
    .updateOne(
      { _id: chatId },
      {
        $set: {
          default: false,
        },
      }
    )
  return result
}

export const getChatList = async ({ id }) => {
  // console.log("OrderId getChatList   =", id, typeof id)
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app)
    .collection("Chat")
    .aggregate([
      {
        $match: {
          orderId: ObjectId(id),
        },
      },
      {
        $lookup: {
          from: "Message",
          localField: "messages",
          foreignField: "_id",
          pipeline: [
            {
              $sort: {
                sendOn: 1,
              },
            },
          ],
          as: "getMessage",
        },
      },
    ])
}
export const getDashbordOrderList = async () => {
  if (!app || !app.currentUser) {
    return
  }
  return await getDBInstance(app)
    .collection("Orders")
    .aggregate([
      {
        $match: {
          $or: [{ orderStatus: "placed" }, { orderStatus: "assigned" }],
        },
      },
      {
        $lookup: {
          from: "Customers",
          localField: "customerid",
          foreignField: "_id",
          as: "getCustomer",
        },
      },

      {
        $unwind: "$getCustomer",
      },

      {
        $sort: {
          _id: -1,
        },
      },
    ])
}

export const getChatDashbord = async () => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app)
    .collection("Chat")
    .aggregate([
      {
        $match: {
          default: true,
        },
      },
      {
        $lookup: {
          from: "Orders",
          localField: "orderId",
          foreignField: "_id",
          as: "getOrder",
        },
      },
      {
        $unwind: "$getOrder",
      },
      {
        $lookup: {
          from: "Customers",
          localField: "getOrder.customerid",
          foreignField: "_id",
          as: "getCustomer",
        },
      },
      {
        $unwind: "$getCustomer",
      },
    ])
}

export const getCurrentOrdersCount = async (startOfDay, endOfDay) => {
  if (!app || !app.currentUser) {
    return
  }
  // console.log("startOfDay=", startOfDay)
  // console.log("endOfDay=", endOfDay)
  return await getDBInstance(app)
    .collection("Orders")
    .count({
      $and: [
        { orderPlacedOn: { $gt: startOfDay } },
        { orderPlacedOn: { $lt: endOfDay } },
      ],
    })
}

export const getAcceptedtOrdersCount = async () => {
  if (!app || !app.currentUser) {
    return
  }

  try {
    return await getDBInstance(app)
      .collection("Orders")
      .count({ orderStatus: "placed" })
  } catch (e) {
    console.error('Error ::', e)
  }
}

export const getAssignedOrdersCount = async () => {
  if (!app || !app.currentUser) {
    return
  }
  try {
    return await getDBInstance(app)
      .collection("Orders")
      .count({ orderStatus: "assigned" })
  } catch (e) {
    console.error('Error ::', e)
  }

}

export const getCompletedOrdersCount = async () => {
  if (!app || !app.currentUser) {
    return
  }
  try {
    return await getDBInstance(app)
      .collection("Orders")
      .count({ orderStatus: "completed" })
  } catch (e) {
    console.error('Error ::', e)
  }


}
export const getOrderStatus = async () => {
  const user1 = await generalLogin()
  if (!app || !app.currentUser) {
    return
  }

  const result = user1?.callFunction("fetchConfig")

  return result
}
export const getDailySales = async (startOfDay, endOfDay) => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app)
    .collection("Orders")
    .aggregate([
      {
        $match: {
          $and: [
            { orderPlacedOn: { $gt: startOfDay } },
            { orderPlacedOn: { $lt: endOfDay } },
            { orderStatus: "completed" },
          ],
        },
      },
      {
        $lookup: {
          from: "Customers",
          localField: "customerid",
          foreignField: "_id",
          as: "getCustomer",
        },
      },
      {
        $unwind: "$getCustomer",
      },
      {
        $lookup: {
          from: "Technician",
          localField: "assignedTechnicianid",
          foreignField: "_id",
          as: "getTechnician",
        },
      },
      {
        $unwind: "$getTechnician",
      },
      {
        $sort: {
          _id: -1,
        },
      },
    ])
}

export const getMonthlySales = async (startOfMonth, endOfMonth) => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app)
    .collection("Orders")
    .aggregate([
      {
        $match: {
          $and: [
            { orderPlacedOn: { $gt: startOfMonth } },
            { orderPlacedOn: { $lt: endOfMonth } },
            { orderStatus: "completed" },
          ],
        },
      },
      {
        $lookup: {
          from: "Customers",
          localField: "customerid",
          foreignField: "_id",
          as: "getCustomer",
        },
      },
      {
        $unwind: "$getCustomer",
      },
      {
        $lookup: {
          from: "Technician",
          localField: "assignedTechnicianid",
          foreignField: "_id",
          as: "getTechnician",
        },
      },
      {
        $unwind: "$getTechnician",
      },
      {
        $sort: {
          _id: -1,
        },
      },
    ])
}

export const getAnnualSales = async (startOfYear, endOfYear) => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app)
    .collection("Orders")
    .aggregate([
      {
        $match: {
          $and: [
            { orderPlacedOn: { $gt: startOfYear } },
            { orderPlacedOn: { $lt: endOfYear } },
            { orderStatus: "completed" },
          ],
        },
      },
      {
        $lookup: {
          from: "Customers",
          localField: "customerid",
          foreignField: "_id",
          as: "getCustomer",
        },
      },
      {
        $unwind: "$getCustomer",
      },
      {
        $lookup: {
          from: "Technician",
          localField: "assignedTechnicianid",
          foreignField: "_id",
          as: "getTechnician",
        },
      },
      {
        $unwind: "$getTechnician",
      },
      {
        $sort: {
          _id: -1,
        },
      },
    ])
}

export const getSalesByModelReport = async () => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app)
    .collection("Orders")
    .aggregate([
      {
        $match: {
          orderStatus: "completed",
        },
      },
      // {
      //   $lookup: {
      //     from: "Vehicle",
      //     localField: "vehicleId",
      //     foreignField: "_id",
      //     as: "getVehicle",
      //   },
      // },
      // {
      //   $unwind: "$getCustomer",
      // },
      {
        $sort: {
          _id: -1,
        },
      },
    ])
}

export const getSalesByTechnicianReport = async () => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app)
    .collection("Orders")
    .aggregate([
      {
        $match: {
          orderStatus: "completed",
        },
      },
      {
        $lookup: {
          from: "Technician",
          localField: "assignedTechnicianid",
          foreignField: "_id",
          as: "getTechnician",
        },
      },
      {
        $group: {
          _id: "$service",
          count: { $sum: 1 },
        },
      },
      {
        $sort: {
          _id: -1,
        },
      },
    ])
}

export const getOrderDetails = async ({ editId }) => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app)
    .collection("Orders")
    .findOne({ _id: ObjectId(editId) })
}

export const getCustomers = async () => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app).collection("Customers").find({})
}

export const createCouponCode = async ({
  couponName,
  couponDescription,
  couponCode,
  includedServiceTypes,
  excludedServiceTypes,
  excludedCustomers,
  includedCustomers,
  type,
  amount,
  validTill,
  validFrom,
  toggleSwitch,
  isActive,
  isServiceChargeActive,
  isServiceChargeDiscount
}) => {
  if (!app || !app.currentUser) {
    return
  }

  try {
    const result = await getDBInstance(app)
      .collection("Coupons")
      .insertOne({
        name: couponName,
        description: couponDescription,
        couponCode: couponCode,
        includedServiceTypes: includedServiceTypes,
        excludedServiceTypes: excludedServiceTypes,
        includedCustomers: includedCustomers,
        excludedCustomers: excludedCustomers,
        type: type,
        amount: Number(amount),
        validFrom: new Date(validFrom),
        validTill: new Date(validTill),
        toggleSwitch: toggleSwitch,
        isActive: isActive,
        isServiceChargeActive: isServiceChargeActive,
        isServiceChargeDiscountActive: isServiceChargeDiscount
      })
    // console.log("appUser", app.currentUser.id)
    return result
  } catch (e) {
    console.log("Error :", e)
  }
}

export const getCoupons = async () => {
  if (!app || !app.currentUser) {
    return
  }

  const response = await getDBInstance(app).collection("Coupons").aggregate([])
  // console.log("response ==", response)
  return response
}
export const getCouponCode = async ({ id }) => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app)
    .collection("Coupons")
    .aggregate([
      {
        $match: {
          _id: ObjectId(id),
        },
      },
      {
        $lookup: {
          from: "ServiceTypes",
          localField: "includedServiceTypes",
          foreignField: "_id",
          as: "getIncludedServiceTypes",
        },
      },
      {
        $lookup: {
          from: "ServiceTypes",
          localField: "excludedServiceTypes",
          foreignField: "_id",
          as: "getExcludedServiceTypes",
        },
      },
      {
        $lookup: {
          from: "Customers",
          localField: "includedCustomers",
          foreignField: "_id",
          as: "getIncludedCustomers",
        },
      },
      {
        $lookup: {
          from: "Customers",
          localField: "excludedCustomers",
          foreignField: "_id",
          as: "getExcludedCustomers",
        },
      },
    ])
}
export const deleteCoupons = async ({ id }) => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app).collection("Coupons").deleteOne({ _id: id })
}
export const deleteMessageData = async ({ id }) => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app)
    .collection("PushNotification")
    .deleteOne({ _id: id })
}

export const updateCoupon = async ({
  editId,
  couponName,
  couponDescription,
  couponCode,
  includedServiceTypes,
  excludedServiceTypes,
  excludedCustomers,
  includedCustomers,
  type,
  amount,
  validFrom,
  validTill,
  toggleSwitch,
  isActive,
  isServiceChargeActive,
  isServiceChargeDiscount
}) => {
  if (!app || !app.currentUser) {
    return
  }
  const result = await getDBInstance(app)
    .collection("Coupons")
    .updateOne(
      { _id: editId },
      {
        $set: {
          name: couponName,
          description: couponDescription,
          couponCode: couponCode,
          includedServiceTypes: includedServiceTypes,
          excludedServiceTypes: excludedServiceTypes,
          includedCustomers: includedCustomers,
          excludedCustomers: excludedCustomers,
          type: type,
          amount: Number(amount),
          validFrom: new Date(validFrom),
          validTill: new Date(validTill),
          toggleSwitch: toggleSwitch,
          isActive: isActive,
          isServiceChargeActive: isServiceChargeActive,
          isServiceChargeDiscountActive: isServiceChargeDiscount
        },
      }
    )
  return result
}

export const getServiceTypesData = async () => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app)
    .collection("Coupons")
    .aggregate([
      {
        $lookup: {
          from: "ServiceTypes",
          localField: "includedServiceTypes",
          foreignField: "_id",
          as: "getServiceTypes",
        },
      },
    ])
}
// .aggregate([
//   {
//     $lookup: {
//       from: "Vehicle",
//       localField: "selectedVehicles",
//       foreignField: "_id",
//       as: "getVehicles",
//     },
//   },
// ])

export const createCity = async ({ values }) => {
  if (!app || !app.currentUser) {
    return
  }

  try {
    const result = await getDBInstance(app)
      .collection("City")
      .insertOne({
        name: {
          ar: values.addCityAr,
          en: values.addCityEn,
        },
      })
    return result
  } catch (e) {
    console.log("Error :", e)
  }
}

export const getCityCollection = async () => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app).collection("City").find({})
}

export const editCity = async ({ editId, addCityEn, addCityAr }) => {
  if (!app || !app.currentUser) {
    return
  }

  const result = await getDBInstance(app)
    .collection("City")
    .updateOne(
      { _id: editId },
      {
        $set: {
          name: {
            en: addCityEn,
            ar: addCityAr,
          },
        },
      }
    )
  return result
}

export const getDeletedCity = async ({ id }) => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app).collection("City").deleteOne({ _id: id })
}

export const createNewUser = async ({ values, selectRole }) => {
  if (!app || !app.currentUser) {
    return
  }

  let user = await getDBInstance(app)
    .collection("User")
    .findOne({ email: values.email })
  // console.log("email=", user)
  if (!user) {
    const salt = bcrypt.genSaltSync(10) // Generate a salt
    const hashedPassword = bcrypt.hashSync(values.password, salt)
    const result = await getDBInstance(app).collection("User").insertOne({
      email: values.email,
      mobileNumber: values.mobileNumber,
      name: values.name,
      role: selectRole,
      password: hashedPassword,
    })
    return result
  }
}

export const getUsers = async () => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app).collection("User").find({})
}

export const editUser = async ({
  editId,
  // email,
  mobileNumber,
  name,
  selectRole,
  // password
}) => {
  if (!app || !app.currentUser) {
    return
  }

  const result = await getDBInstance(app)
    .collection("User")
    .updateOne(
      { _id: editId },
      {
        $set: {
          // email: email,
          mobileNumber: mobileNumber,
          name: name,
          role: selectRole,
          // password: password
        },
      }
    )
  return result
}

export const getDeletedUser = async ({ id }) => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app).collection("User").deleteOne({ _id: id })
}

//login
export const getUser = async email => {
  // console.log("email", email)
  if (!app || !app.currentUser) {
    return
  }
  return await getDBInstance(app).collection("User").findOne({ email: email })
}

export const getUserRole = async id => {
  if (!app || !app.currentUser) {
    return
  }
  return await getDBInstance(app).collection("User").findOne({ _id: id })
}

export const resetPassword = async values => {
  if (!app || !app.currentUser) {
    return
  }
  // console.log("values=", values)
  const result = await app.currentUser.callFunction("forgotPassword", {
    functionName: "resetPassword",
    params: { email: values.email },
  })
  return result
}

export const updateNewPassword = async (values, token) => {
  if (!app || !app.currentUser) {
    return
  }
  const salt = bcrypt.genSaltSync(10) // Generate a salt
  const hashedPassword = bcrypt.hashSync(values.password, salt)
  let result = await getDBInstance(app)
    .collection("User")
    .updateOne(
      { verifytoken: token },
      {
        $set: {
          password: hashedPassword,
        },
      }
    )
  return result
}

export const getCarTypeData = async () => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app).collection("VehicleType").find({})
}

export const getOneTechnician = async id => {
  if (!app || !app.currentUser) {
    return
  }
  if (!id) {
    return
  }
  if (typeof id === "string") {
    id = new ObjectId(id)
  }

  return await getDBInstance(app).collection("Slots").find({ _id: id })
}

export const getAudience = async () => {
  try {
    const result = await getDBInstance(app)
      .collection("Customers")
      .find({}, { projection: { _id: 0, player_id: 1 } })

    return result
  } catch (e) {
    console.log("Error ==", e)
  }
}
export const getTechnicianAudience = async () => {
  try {
    const result = await getDBInstance(app)
      .collection("Technician")
      .find({}, { projection: { _id: 0, player_id: 1 } })
    return result
  } catch (e) {
    console.log("Error ==", e)
  }
}

export const createNewPush = async ({ values, res }) => {
  try {
    const gUser = await generalLogin()
    let result

    if (
      values.selectAudience.label === "All Customers" ||
      values.selectAudience.label === "Choose Everyone"
    ) {
      result = await gUser?.callFunction("oneSignal", {
        contents: { en: values.addDescription },
        include_player_ids: values.selectAudience.value,
        type: values.selectAudience.label,
        big_picture: res.url,
        ios_attachments: res.url,
      })
    }
    if (
      values.selectAudience.label === "All Technicians" ||
      values.selectAudience.label === "Choose Everyone"
    ) {
      result = await gUser?.callFunction("oneSignalTecnician", {
        contents: { en: values.addDescription },
        include_player_ids: values.selectAudience.value,
        type: values.selectAudience.label,
        big_picture: res.url,
        ios_attachments: res.url,
      })
    }

    const response = await getDBInstance(app)
      .collection("PushNotification")
      .insertOne({
        title: values?.addTitle,
        description: values?.addDescription,
        icon: res,
        audience: values?.selectAudience.value,
        metaData: {
          audience: values.selectAudience.label,
          date: new Date().toString(),
        },
      })

    return response
  } catch (error) {
    console.error("Error creating and sending push notification:", error)
    throw error
  }
}

export const getMessages = async () => {
  return await getDBInstance(app)
    .collection("PushNotification")
    .find(
      {},
      {
        projection: {
          title: 1,
          description: 1,
          "icon.filePath": 1,
          metaData: 1,
        },
      }
    )
}
export const rapidApiCall = async () => {
  try {
    const response = await app.currentUser.callFunction("RapidApi", {
      payload: {},
    })
    console.log("response ==", response)
    return response
  } catch (e) {
    console.error("Error =", e)
    throw e
  }
}

export const getDailySalesReport = async ({ date }) => {
  if (!app || !app.currentUser) {
    return
  }
  console.log("date:", date)
  let results = await app.currentUser.callFunction("Reports", {
    functionName: "dailySalesReport",
    params: { date },
  })

  return results
}

export const getMonthlySalesReport = async ({ month }) => {
  if (!app || !app.currentUser) {
    return
  }

  let results = await app.currentUser.callFunction("Reports", {
    functionName: "monthlySalesReport",
    params: { month },
  })

  return results
}

export const getAnnualSalesReport = async ({ year }) => {
  if (!app || !app.currentUser) {
    return
  }

  let results = await app.currentUser.callFunction("Reports", {
    functionName: "annualSalesReport",
    params: { year },
  })

  return results
}

export const getDateWiseSalesReport = async ({ startOfDay, endOfDay }) => {
  if (!app || !app.currentUser) {
    return
  }

  let results = await app.currentUser.callFunction("Reports", {
    functionName: "dateWiseSalesReport",
    params: { startOfDay, endOfDay },
  })

  return results
}

export const getServices = async () => {
  try {
    const response = await getDBInstance(app)
      .collection("Services")
      .find({}, { projection: { _id: 1, "name.en": 1 } })
    return response
  } catch (e) {
    console.error("Error = ", e)
  }
}

export const getServiceCategories = async () => {
  if (!app || !app.currentUser) {
    return
  }
  try {
    return await getDBInstance(app)
      .collection("ServiceTypes")
      .find({}, { projection: { _id: 1, "name.en": 1 } })
  } catch (e) {
    console.error("Error =", e)
  }
}

export const createBundle = async ({ values, res, isServiceActive }) => {
  if (!app || !app?.currentUser) {
    return
  }
  const data = {
    serviceCategoryId: values?.serviceCategoryId?.value,
    serviceId: values?.services?.map(v => v?.value),
    bundleCount: values?.bundleCount,
    name: { en: values?.serviceNameEn, ar: values?.serviceNameAr },
    technicalName: values?.technicalName,
    price: Number(values?.servicePrice),
    description: {
      en: values?.serviceDescriptionEn,
      ar: values?.serviceDescriptionAr,
    },
    images: res,
    salePrice: Number(values?.serviceSale),
    active: isServiceActive,
  }

  const response = await getDBInstance(app)
    .collection("BundleServices")
    .insertOne(data)
  return response
}

export const editBundle = async ({ editId, values, res, isServiceActive }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (typeof editId === "string") {
    editId = new ObjectId(editId)
  }

  const data = {
    serviceCategoryId: values?.serviceCategoryId?.value,
    serviceId: values?.services?.map(v => v?.value),
    bundleCount: values?.bundleCount,
    name: { en: values?.serviceNameEn, ar: values?.serviceNameAr },
    technicalName: values?.technicalName,
    price: Number(values?.servicePrice),
    description: {
      en: values?.serviceDescriptionEn,
      ar: values?.serviceDescriptionAr,
    },
    images: res,
    salePrice: Number(values?.serviceSale),
    active: isServiceActive,
  }

  const result = await getDBInstance(app)
    .collection("BundleServices")
    .updateOne(
      { _id: editId },
      {
        $set: data,
      }
    )
  return result
}

export const getBundleServices = async () => {
  if (!app || !app.currentUser) {
    return
  }

  return await getDBInstance(app)
    .collection("BundleServices")
    .aggregate([
      {
        $match: {},
      },
      {
        $lookup: {
          from: "ServiceTypes",
          localField: "serviceCategoryId",
          foreignField: "_id",
          as: "getServiceTypes",
        },
      },
      {
        $lookup: {
          from: "Services",
          localField: "serviceId",
          foreignField: "_id",
          as: "getServices",
        },
      },
    ])
}

export const getOneBundleService = async ({ id }) => {
  if (!app || !app.currentUser) {
    return
  }

  if (typeof id === "string") {
    id = new ObjectId(id)
  }

  const result = await getDBInstance(app)
    .collection("BundleServices")
    .aggregate([
      {
        $match: { _id: id },
      },
      {
        $lookup: {
          from: "ServiceTypes",
          localField: "serviceCategoryId",
          foreignField: "_id",
          as: "getServiceTypes",
        },
      },
      {
        $lookup: {
          from: "Services",
          localField: "serviceId",
          foreignField: "_id",
          as: "getServices",
        },
      },
    ])
  return result[0]
}

export const getDeletedBundleService = async ({ id }) => {
  if (!app || !app.currentUser) {
    return
  }
  return await getDBInstance(app)
    .collection("BundleServices")
    .deleteOne({ _id: id })
}