import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { isEmpty } from "lodash"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  addNewEvent,
  deleteEvent,
  getCategories,
  getEvents,
  updateEvent,
} from "../../store/actions"
// import DeleteModal from "./DeleteModal";
//css
import "@fullcalendar/bootstrap/main.css"
import {
  getSalesByTechnicianReport
} from "common/Services/dbServices"


const TechnicianReport = props => {
  const [technician, setTechnician] = useState()

  useEffect(() => {
    salesByTechnicianReport()
  }, [])

  const salesByTechnicianReport = async () => {

    const result = await getSalesByTechnicianReport()
    console.log("salesByTechnicianReport =", result)
    if (result) {
      setTechnician(result);
    }
  }

  document.title = " Sales By Technician | Sayartak "
  return (
    <React.Fragment>
      {/* <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      /> */}
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Sayartak" breadcrumbItem=" Sales By Technician" />
          <Row>
            <Col className="col-12">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <div id="external-events" className="mt-12">
                        <h4 className="card-title mb-4">
                        Sales By Technician 
                        </h4>
                        <div className="table-responsive">
                          <table className="table table-hover table-centered table-nowrap mb-0">
                            <thead className="table-light">
                              <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Name</th>
                                <th scope="col">Technician ID</th>
                                <th scope="col">No. Of Services</th>
                                <th scope="col">Total Revenue</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                {/* <th scope="row">#14256</th> */}
                                <td>15/1/2022</td>
                                <td>Peter Parker</td>
                                <td>ST1254</td>
                                <td>48</td>
                                <td>JOD 11250</td>
                              </tr>
                              <tr>
                                <td>15/1/2022</td>
                                <td>Carol Danvers</td>
                                <td>ST1368</td>
                                <td>49</td>
                                <td>JOD 12550</td>
                              </tr>
                              <tr>
                                <td>15/1/2022</td>
                                <td>Steve Rogers</td>
                                <td>ST1875</td>
                                <td>122</td>
                                <td>JOD 22150</td>
                              </tr>
                              <tr>
                                <td>15/1/2022</td>
                                <td>Tony Stark</td>
                                <td>ST1985</td>
                                <td>81</td>
                                <td>JOD 21250</td>
                              </tr>
                              <tr>
                                <td>15/1/2022</td>
                                <td>Bruce Banner</td>
                                <td>ST1352</td>
                                <td>31</td>
                                <td>JOD 10250</td>
                              </tr>
                              <tr>
                                <td>15/1/2022</td>
                                <td>Natasha</td>
                                <td>ST1823</td>
                                <td>114</td>
                                <td>JOD 20500</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

TechnicianReport.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
}

const mapStateToProps = ({ calendar }) => ({
  events: calendar.events,
  categories: calendar.categories,
})

const mapDispatchToProps = dispatch => ({
  onGetEvents: () => dispatch(getEvents()),
  onGetCategories: () => dispatch(getCategories()),
  onAddNewEvent: event => dispatch(addNewEvent(event)),
  onUpdateEvent: event => dispatch(updateEvent(event)),
  onDeleteEvent: event => dispatch(deleteEvent(event)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TechnicianReport)
