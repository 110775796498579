import React, { useEffect, useState } from "react"
import { Tab, Tabs } from "react-bootstrap"
import { useLocation } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Button,
  Form,
} from "reactstrap"
import logosmImg from "../../assets/images/logo.png"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const FormElements = () => {
  const [customchk, setcustomchk] = useState(true)
  const [customchkPrimary, setcustomchkPrimary] = useState(true)
  const [customchkSuccess, setcustomchkSuccess] = useState(true)
  const [customchkInfo, setcustomchkInfo] = useState(true)
  const [customchkWarning, setcustomchkWarning] = useState(true)
  const [customchkDanger, setcustomchkDanger] = useState(true)
  const [customOutlinePrimary, setcustomOutlinePrimary] = useState(true)
  const [customOutlineSuccess, setcustomOutlineSuccess] = useState(true)
  const [customOutlineInfo, setcustomOutlineInfo] = useState(true)
  const [customOutlineWarning, setcustomOutlineWarning] = useState(true)
  const [customOutlineDanger, setcustomOutlineDanger] = useState(true)
  const [toggleSwitch, settoggleSwitch] = useState(true)
  const [toggleSwitchSize, settoggleSwitchSize] = useState(true)
  const [tabKey, initTabKey] = useState("one")
  const location = useLocation()
  const data = location.state
  const [serviceBrandType, setServiceBrandType] = useState()

  // console.log('data', data);
  document.title = "Service Details | Sayartak"

  useEffect(() => {
    setServiceBrandType(data?.serviceBrandType)
    // getVehicleData()
    // console.log("serviceBrandType", serviceBrandType);
  }, [])

  // const getVehicleData = async () =>{
  //   let vehicleId = data.selectedVehicles
  //   console.log("id ==",vehicleId)
  //   const response =await getSelectedVehicleList({vehicleId})
  //   console.log("response ==",response)
  // }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="Sayartak"
            title="Services"
            breadcrumbItem="Service Details"
            url="/service"
          />

          <Tabs activeKey={tabKey} onSelect={e => initTabKey(e)}>
            <Tab eventKey="one" title="Details">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <div className="row">
                        <div class="col-md-6">
                          <div class="details-section-item">
                            <label class="details-section-item-label">
                              Service Name En
                            </label>
                            <div class="details-section-item-content">
                              <span>{data?.name?.en || "-"}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="details-section-item">
                            <label class="details-section-item-label">
                              Service Name Ar
                            </label>
                            <div class="details-section-item-content">
                              <span>{data?.name?.ar || "-"}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div class="col-12">
                          <hr style={{ width: "100%" }} />
                        </div>
                      </div>
                      <div className="row">
                        <div class="col-md-6">
                          <div class="details-section-item">
                            <label class="details-section-item-label">
                              Description En
                            </label>
                            <div class="details-section-item-content">
                              <span>
                                {data?.description?.en || "-"}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="details-section-item">
                            <label class="details-section-item-label">
                              Service Icon
                            </label>
                            <div class="details-section-item-content">
                              <span>
                                {data && data?.images && data?.images?.url ? (
                                  <img
                                    src={data?.images?.url}
                                    height="30px"
                                    width="35px"
                                  ></img>
                                ) : (
                                  <img
                                    src={logosmImg}
                                    alt=""
                                    className="img-thumbnail-sm"
                                    width="35px"
                                    height="35px"
                                  />
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div class="col-12">
                          <hr style={{ width: "100%" }} />
                        </div>
                      </div>
                      {/* <Row className="mb-3">
                        <label className="col-sm-2 col-form-label">
                         Category :
                        </label>
                        <div className="col-md-6">
                          <p>
                            <strong>{data.category}</strong>
                          </p>
                        </div>
                      </Row> */}
                      <div className="row">
                        <div class="col-md-6">
                          <div class="details-section-item">
                            <label class="details-section-item-label">
                              Price
                            </label>
                            <div class="details-section-item-content">
                              <span>{data?.price || "-"}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="details-section-item">
                            <label class="details-section-item-label">
                              Sale Price
                            </label>
                            <div class="details-section-item-content">
                              <span>{data?.salePrice || "-"}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div class="col-12">
                          <hr style={{ width: "100%" }} />
                        </div>
                      </div>
                      <div className="row">
                        <div class="col-md-6">
                          <div class="details-section-item">
                            <label class="details-section-item-label">
                              Related Services
                            </label>
                            <div class="details-section-item-content">
                              {data && data?.relatedServices
                                ? data?.relatedServices?.map((types, index) => {
                                    return <span>{types?.label || "-" }</span>
                                  })
                                : null}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="details-section-item">
                            <label class="details-section-item-label">
                              Select Vehicles
                            </label>
                            <div class="details-section-item-content">
                              {data && data?.getVehicles
                                ? data?.getVehicles.map((types, index) => {
                                    return (
                                      <span>{types?.name.en || "-"} ,</span>
                                    )
                                  })
                                : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div class="col-12">
                          <hr style={{ width: "100%" }} />
                        </div>
                      </div>
                      <div className="row">
                        <div class="col-md-6">
                          <div class="details-section-item">
                            <label class="details-section-item-label">
                              Fuel Types
                            </label>
                            <div class="details-section-item-content">
                              {data && data?.serviceFuelTypes
                                ? data?.serviceFuelTypes.map((types) => {
                                    return <span>{types || "-"}</span>
                                  })
                                : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      {serviceBrandType && serviceBrandType.length > 0 ? (
                        <Row className="mb-2">
                          <table className="table table-hover table-centered table-nowrap mb-0">
                            <thead className="table-light">
                              <tr>
                                <th scope="col"> Sub Type</th>
                                <th scope="col">Brand</th>
                                <th scope="col">Price</th>
                              </tr>
                            </thead>

                            <tbody>
                              {serviceBrandType.map((item, index) => {
                                console.log("item", item.serviceBrandId)
                                return (
                                  <tr>
                                    {item.serviceSubTypeName ? (
                                      <td>{item.serviceSubTypeName}</td>
                                    ) :  <td>No Value</td>}
                                    {item.serviceBrandName ? (
                                      <td>{item.serviceBrandName}</td>
                                    ) :  <td>No Value</td>}
                                    {item.serviceBrandTypePrice ? (
                                      <td>{item.serviceBrandTypePrice}</td>
                                    ) : null}
                                    {/* <td>
                                  {" "}
                                  <i
                                    onClick={() => onDelete(index)}
                                    className="fas fa-trash"
                                  ></i>
                                </td> */}
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </Row>
                      ) : null}
                      {/* </Tab> */}

                      {/* </Tabs> */}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Tab>
            {/* <Tab eventKey="two" title="Demo">
              <p>Hellooo</p>
            </Tab> */}
          </Tabs>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormElements
