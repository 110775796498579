import React, { useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { IKImage } from "imagekitio-react"
import Loader from "components/Common/Loader"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
} from "reactstrap"
import moment from "moment"
import { Link, useNavigate } from "react-router-dom"
import {deleteMessageData} from "common/Services/dbServices"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  addNewEvent,
  deleteEvent,
  getCategories,
  getEvents,
  updateEvent,
} from "../../store/actions"
import "@fullcalendar/bootstrap/main.css"
import {
  urlEndpoint,
} from "../../components/Common/imageKit"
import { useMessages } from "common/Hooks/queryCalls"
import logosmImg from "../../assets/images/logo.png"


const Calender = props => {
  const { events, categories } = props
  const navigate = useNavigate()
  const [isLoading, setLoading] = useState(false)
  const { data: messageData ,refetch} = useMessages();
  const [popModal, setPopModal] = useState(false)
  const [id, setId] = useState()
  const deleteItem = async () => {
    const response = await deleteMessageData({ id })
    setPopModal(!popModal)
    refetch();
  }
  const popToggle = result => {
    setId(result)
    setPopModal(!popModal)
  }

  document.title = "Customer | Sayartak";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col sm={6}>
              <Breadcrumbs title="Sayartak" breadcrumbItem="Messages" />
            </Col>
            <Col sm={6}>
              <div className="float-end d-none d-md-block">
                <Link
                  to="/new-pushNotification"
                  className="btn btn-secondary"
                  style={{ marginTop: 10 }}
                >
                  Add New
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <div id="external-events" className="mt-12">
                        <h4 className="card-title mb-4">List of Messages</h4>
                        <div className="table-responsive">
                          <table className="table table-hover table-centered table-nowrap mb-0">
                            <thead className="table-light">
                              <tr>
                                {/* <th scope="col">(#) Id</th> */}

                                <th scope="col">Name</th>
                                <th scope="col">Image</th>
                                <th scope="col">Title</th>
                                <th scope="col">Description</th>
                                <th scope="col">Sent At</th>
                                <th scope="col">Actions</th>
                              </tr>
                            </thead>

                            <tbody>
                              {isLoading ? (
                                <tr>
                                  <td colSpan="8">
                                    <Loader />
                                  </td>
                                </tr>
                              ) : (
                                messageData ? messageData.map((item, index) => {
                                  return (
                                    <tr>
                                      <td
                                      >
                                        {item.metaData.audience == null
                                          ? "Empty Value"
                                          : item.metaData.audience}{" "}
                                      </td>
                                      <td

                                      >
                                        <div>
                                          {item.icon &&
                                            item.icon.filePath ? (
                                            <IKImage
                                              urlEndpoint={urlEndpoint}
                                              path={item.icon.filePath}
                                              width="35px"
                                              height="30px"
                                            />
                                          ) : (
                                            <img
                                              src={logosmImg}
                                              alt=""
                                              className="img-thumbnail-sm"
                                              width="35px"
                                              height="35px"
                                            />
                                          )}
                                        </div>
                                      </td>
                                      <td

                                      >
                                        <td

                                        >
                                          {item.title == null
                                            ? "Empty Value"
                                            : item.title}{" "}
                                        </td>
                                      </td>
                                      <td

                                      >
                                        <td

                                        >
                                          {item.description == null
                                            ? "Empty Value"
                                            : item.description}{" "}
                                        </td>
                                      </td>
                                      <td

                                      >
                                        {item.metaData.date == null
                                          ? "Empty Value"
                                          :  moment(item.metaData.date).format("Do MMMM YYYY")}{" "}
                                      </td>

                                      <td>
                                      {" "}
                                      <i
                                        onClick={() => popToggle(item._id)}
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        className="fas fa-trash"
                                      ></i>
                                    </td>


                                    </tr>
                                  )
                                }

                                ) : null
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center"></div>
            <Modal isOpen={popModal} toggle={popToggle} size="sm">
              <ModalHeader className="mt-0" toggle={popToggle}>
                Delete
              </ModalHeader>
              <ModalBody>
                <p>Are you sure to delete this item</p>
                <div className="d-flex flex-wrap gap-2">
                  <Button
                    type="submit"
                    color="secondary"
                    onClick={() => setPopModal(!popModal)}
                  >
                    Cancel
                  </Button>{" "}
                  <Button type="reset" color="danger" onClick={deleteItem}>
                    Delete
                  </Button>
                </div>
              </ModalBody>
            </Modal>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}

Calender.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
}

const mapStateToProps = ({ calendar }) => ({
  events: calendar.events,
  categories: calendar.categories,
})

const mapDispatchToProps = dispatch => ({
  onGetEvents: () => dispatch(getEvents()),
  onGetCategories: () => dispatch(getCategories()),
  onAddNewEvent: event => dispatch(addNewEvent(event)),
  onUpdateEvent: event => dispatch(updateEvent(event)),
  onDeleteEvent: event => dispatch(deleteEvent(event)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Calender)
