import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import Loader from "components/Common/Loader"
import servicesIcon1 from "../../assets/images/services-icon/01.png"
import servicesIcon2 from "../../assets/images/services-icon/02.png"
import servicesIcon3 from "../../assets/images/services-icon/03.png"
import servicesIcon4 from "../../assets/images/services-icon/04.png"
import {
  getDashbordOrderList,
  getCurrentOrdersCount,
  getChatDashbord,
  getAcceptedtOrdersCount,
  getAssignedOrdersCount,
  getCompletedOrdersCount,
  getOrderStatus,
} from "common/Services/dbServices"
import moment from "moment"
import { useNavigate } from "react-router-dom"
import "chartist/dist/scss/chartist.scss"
import { withTranslation } from "react-i18next"
import "./Style.css"

const Dashboard = props => {
  const navigate = useNavigate()

  const [data, setData] = useState()
  const [chatList, setChatList] = useState()
  const [currentOrdersCount, setCurrentOrdersCount] = useState(0)
  const [aceeptedOrdersCount, setAcceptedOrdersCount] = useState(0)
  const [assignedOrdersCount, setAssignedOrdersCount] = useState(0)
  const [completedOrdersCount, setCompletedOrdersCount] = useState(0)
  const [response, setResponse] = useState()
  const [isLoading, setLoading] = useState(false)
  const [chatLoader, setChatLoader] = useState(false)

  document.title = "Dashboard | Sayartak"

  useEffect(() => {
    getOrderDetails()
    getChatList()
    getCurrentOrders()
    getAcceptedOrders()
    getAssignedOrders()
    getCompletedOrders()
    getOrderStatusData()
  }, [])

  const getOrderDetails = async () => {
    try {
      setLoading(true)
      const result = await getDashbordOrderList()
      if (result) {
        setData(result)
      }
    } catch (e) {
      alert("Sorry,Something Went Wrong")
    }
    setLoading(false)
  }

  const getChatList = async () => {
    try {
      setChatLoader(true)
      const result = await getChatDashbord()
      if (result) {
        setChatList(result)
      }

    } catch (e){
      alert('Sorry,Something Went Wrong')
    }
    setChatLoader(false)
  }

  const getCurrentOrders = async () => {
    const startOfDay = moment().startOf("day").toDate()
    const endOfDay = moment().endOf("day").toDate()
    const result = await getCurrentOrdersCount(startOfDay, endOfDay)
    // console.log("getCurrentOrders =", result)
    if (result) {
      setCurrentOrdersCount(result)
    }
  }

  const getAcceptedOrders = async () => {
    const result = await getAcceptedtOrdersCount()
    // console.log("getAcceptedOrders =", result)
    if (result) {
      setAcceptedOrdersCount(result)
    }
  }

  const getAssignedOrders = async () => {
    const result = await getAssignedOrdersCount()
    // console.log("getAssignedOrders =", result)
    if (result) {
      setAssignedOrdersCount(result)
    }
  }

  const getCompletedOrders = async () => {
    const result = await getCompletedOrdersCount()
    // console.log("getCompletedOrders =", result)
    if (result) {
      setCompletedOrdersCount(result)
    }
  }

  const getOrderStatusData = async () => {
    const res = await getOrderStatus()
    setResponse(res)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Dashboard</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Welcome back, Admin!
                  </li>
                </ol>
              </Col>
            </Row>
          </div>

          <Row>
            <Col xl={12}>
              <Row>
                <Col md={3}>
                  <Card className="mini-stat bg-white text-black">
                    <CardBody>
                      <div className="mb-4">
                        <div className="float-start mini-stat-img me-4">
                          <img src={servicesIcon1} alt="" />
                        </div>
                        <h5 className="font-size-12 text-uppercase mt-0 text-black-50">
                          Orders Today
                        </h5>
                        <h4 className="fw-medium font-size-24">
                          {currentOrdersCount}{" "}
                          <i className="mdi mdi-arrow-up text-success ms-2"></i>
                        </h4>
                      </div>
                      <div className="pt-2"></div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={3}>
                  <Card className="mini-stat bg-white text-black">
                    <CardBody>
                      <div className="mb-4">
                        <div className="float-start mini-stat-img me-4">
                          <img src={servicesIcon2} alt="" />
                        </div>
                        <h5 className="font-size-12 text-uppercase mt-0 text-black-50">
                          Recived Orders
                        </h5>
                        <h4 className="fw-medium font-size-24">
                          {aceeptedOrdersCount}{" "}
                          <i className="mdi mdi-arrow-down text-danger ms-2"></i>
                        </h4>
                        {/* <div className="mini-stat-label bg-danger">
                          <p className="mb-0">- 28%</p>
                        </div> */}
                      </div>
                      <div className="pt-2">
                        {/* <div className="float-end">
                          <Link to="#" className="text-black-50">
                            <i className="mdi mdi-arrow-right h5"></i>
                          </Link>
                        </div> */}

                        {/* <p className="text-black-50 mb-0 mt-1">
                          +2.65% Less sales than usual
                        </p> */}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={3}>
                  <Card className="mini-stat bg-white text-black">
                    <CardBody>
                      <div className="mb-4">
                        <div className="float-start mini-stat-img me-4">
                          <img src={servicesIcon3} alt="" />
                        </div>
                        <h5 className="font-size-12 text-uppercase mt-0 text-black-50">
                          Assingned Orders
                        </h5>
                        <h4 className="fw-medium font-size-24">
                          {assignedOrdersCount}{" "}
                          <i className="mdi mdi-arrow-up text-success ms-2"></i>
                        </h4>
                        {/* <div className="mini-stat-label bg-info">
                          <p className="mb-0"> 00%</p>
                        </div> */}
                      </div>
                      <div className="pt-2">
                        {/* <div className="float-end">
                          <Link to="#" className="text-black-50">
                            <i className="mdi mdi-arrow-right h5"></i>
                          </Link>
                        </div> */}
                        {/* 
                        <p className="text-black-50 mb-0 mt-1">
                          +2.65% More sales than usual
                        </p> */}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={3}>
                  <Card className="mini-stat bg-white text-black">
                    <CardBody>
                      <div className="mb-4">
                        <div className="float-start mini-stat-img me-4">
                          <img src={servicesIcon4} alt="" />
                        </div>
                        <h5 className="font-size-12 text-uppercase mt-0 text-black-50">
                          Completed Orders
                        </h5>
                        <h4 className="fw-medium font-size-24">
                          {completedOrdersCount}{" "}
                          <i className="mdi mdi-arrow-up text-success ms-2"></i>
                        </h4>
                        {/* <div className="mini-stat-label bg-warning">
                          <p className="mb-0">+ 84%</p>
                        </div> */}
                      </div>
                      <div className="pt-2">
                        {/* <div className="float-end">
                          <Link to="#" className="text-black-50">
                            <i className="mdi mdi-arrow-right h5"></i>
                          </Link>
                        </div> */}
                        {/* <p className="text-black-50 mb-0 mt-1">
                          +2.65% More sales than usual
                        </p> */}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xl={8}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">List of Orders</h4>
                  <div className="table-responsive">
                    <table className="table table-hover table-centered table-nowrap mb-0">
                      <thead className="table-light">
                        <tr>
                          {/* <th scope="col">(#) Id</th> */}
                          <th scope="col">Order Ref</th>
                          <th scope="col">Name</th>
                          <th scope="col">Payment Status</th>
                          <th scope="col">Order Status</th>
                          <th scope="col">Order PlacedOn</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <tr>
                            <td colSpan='8'>
                        <Loader />
                            </td>
                          </tr>
                        ) : (
                          data?.map((item, index) => {
                            if (index < 10) {
                              return (
                                <tr
                                  key={item?._id}
                                  onClick={() =>
                                    navigate(`/edit-order/${item._id}`, {
                                      state: item,
                                    })
                                  }
                                >
                                  <td>
                                    {item ? <td>{item.refNumber} </td> : null}
                                  </td>
                                  <td>
                                    {item && item.getCustomer ? (
                                      <td>{item.getCustomer.name} </td>
                                    ) : null}
                                  </td>
                                  <td>
                                    {item && item.paymentStatus ? (
                                      <td>{item.paymentStatus} </td>
                                    ) : null}
                                  </td>
                                  {response ? (
                                    <td>{response[item?.orderStatus]?.en} </td>
                                  ) : null}{" "}
                                  <td>
                                    {moment(item.orderPlacedOn).format(
                                      "Do MMMM YYYY"
                                    )}
                                  </td>
                                </tr>
                              )
                            }
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={4}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">List of Chats</h4>
                  <div className="table-responsive">
                    <table className="table table-hover table-centered table-nowrap mb-0">
                      <thead className="table-light">
                        <tr>
                          {/* <th scope="col">(#) Id</th> */}
                          <th scope="col">customer</th>
                          <th scope="col">Order Number</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {chatLoader ? 
                        <tr>
                          <td colSpan='8'>
                            <Loader />
                          </td>
                        </tr>
                        :
                        chatList?.map((item, index) => {
                          if (index < 10) {
                            return (
                              <tr
                                key={item?._id}
                                onClick={() =>
                                  navigate(`/chat/${item.orderId}`, {
                                    state: item,
                                  })
                                }
                              >
                                <td>
                                  {item && item.getCustomer ? (
                                    <td>{item.getCustomer.name} </td>
                                  ) : null}
                                </td>
                                <td>
                                  {item && item.getOrder ? (
                                    <td>{item.getOrder.refNumber} </td>
                                  ) : null}
                                </td>
                                <td>
                                  {" "}
                                  <i className="ion ion-ios-chatbubbles"></i>
                                </td>
                              </tr>
                            )
                          }
                        })}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Dashboard)
