import React, { useState, useEffect } from "react"
import Select from "react-select"
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button,
  Form,
  Input,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  createVehicle,
  getVehicleBrand,
  getCarTypeData,
  editVehicle,
  getVehicleCollection,
} from "common/Services/dbServices"
import ImageSection from "components/Common/ImageSection";
import { useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import { useParams } from "react-router-dom"
// import ImageKit from "imagekit";
const FormElements = () => {
  const [carData, setCarData] = useState()
  const [carType, setCarType] = useState()
  const [res, setRes] = useState({})
  const navigate = useNavigate()
  const [image, setImage] = useState(null)
  const [isHovered, setIsHovered] = useState(false)
  const [progress, setProgress] = useState(false)
  const urlParams = useParams();
  const [response , setResponse] = useState();

  useEffect(() => {
    brandList()
    fetchCarType()
    
  }, [])
  useEffect(() => {
    if(urlParams && urlParams.id){
      getVehicle();
    }
  },[])
  useEffect(() => {
    if (response) {
      setInputs()
    }
  }, [response])
  const getVehicle =  async () => {
    const response = await getVehicleCollection(urlParams.id);
    setResponse(response);
  }
  const onError = err => {
    console.log("Error", err)
  }
  const onSuccess = result => {
    if (result) {
      setProgress(false)
    }
    setRes(result)
    setImage(result.filePath)
    // console.log("result", result)
  }
  const onUploadProgress = progress => {
    setProgress(progress)
  }
  const onUploadStart = progress => {
    setProgress(progress)
  }
  const fetchCarType = async () => {
    try {
      const response = await getCarTypeData()
      if (response) {
        let CarType = []
        response.map((item, index) => {
          let obj = {
            value: item._id.toString(),
            label: item.name.en,
          }
          CarType.push(obj)
        })
        setCarType(CarType)
      }
    } catch (e) {
      // console.log("Error,", e)
    }
  }
  const brandList = async () => {
    const response = await getVehicleBrand()
    if (response) {
      let Cars = []
      response.map((item, index) => {
        let obj1 = {
          value: item._id.toString(),
          label: item.name.en,
        }
        Cars.push(obj1)
      })
      setCarData(Cars)
    }
  }

  const initialValues = {
    addCarName: "",
    selectCarBrand: null,
    selectCarType: null,
    selectTransmissionValue: null,
    selectFuelType: null,
    selectOrderNo: 0,
  }
  const setInputs = () => {
    if (response) {
      formik.setValues({
        addCarName:  response?.name?.en || null,
        selectCarBrand:  response?.brandID?.toString() || null,
        selectCarType: response?.carType?.toString() || null,
        selectTransmissionValue: response?.availableTransmissionTypes || null,
        selectFuelType: response?.availableFuelTypes || null,
        selectOrderNo: response?.order || null,
      })
    }
    setRes(response?.images)
    setImage(response?.images?.filePath)
  }
  const handleCreateVehicle = async (values) => {
    try {
      const response = await createVehicle({
        values,
        res,
      })

        alert("Item Added Successfully")
        navigate("/car")
    } catch (error) {
      console.error("error ==",error)
      alert("Failed to Add")
      navigate("/car")
    }
  }

  const handleUpdateVehicle = async (values) => {
    const id = urlParams?.id;
    try {
      const response = await editVehicle({id,values,res})

        alert("Item Updated Successfully")
        navigate("/car")
    } catch (error) {
      console.error("error ==",error)
      alert("Failed to Update")
      navigate("/car")
    }
  }

  const onSubmit = values => {

    if (urlParams?.id === undefined) {
      handleCreateVehicle(values);
    } else {
      handleUpdateVehicle(values);
    }
    
  }
  const validate = values => {
    // console.log('validate=',values)
    let errors = {}
    if (!values.addCarName) {
      errors.addCarName = "car name required"
    }
    if (!values.selectCarBrand) {
      errors.selectCarBrand = "car brand required"
    }
    if (!values.selectCarType) {
      errors.selectCarType = "car type required"
    }
    if (!values.selectTransmissionValue) {
      errors.selectTransmissionValue = "Transmission value required"
    }
    if (!values.selectFuelType) {
      errors.selectFuelType = "Fuel type required"
    }
    return errors
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  })

  const fuelData = [
    { value: 1, label: "Petrol" },
    { value: 2, label: "Diesel" },
    { value: 3, label: "Hybrid" },
    { value: 4, label: "Electric" },
  ]
  const transmissionData = [
    { value: 1, label: "Manual" },
    { value: 2, label: "Automatic" },
  ]

  document.title = "Add New Car | Sayartak"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="Sayartak"
            title="Car"
            breadcrumbItem= {response ? "Edit Car"  : "Add New Car" }
            url="/Car"
          />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  {/* <CardTitle className="h4">Details</CardTitle> */}
                  <Form
                    style={{ marginTop: 10 }}
                    className="row g-3 needs-validation"
                    onSubmit={formik.handleSubmit}
                  >
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Car Name
                      </label>
                      <div className="col-md-6">
                        <Input
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Car Name"
                          name="addCarName"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.addCarName}
                        />
                        {formik.touched.addCarName &&
                        formik.errors.addCarName ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.addCarName}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Car Image
                      </label>
                      <ImageSection
                          progress={progress}
                          image={image}
                          isHovered={isHovered}
                          onHoverEnter={() => setIsHovered(true)}
                          onHoverLeave={() => setIsHovered(false)}
                          onImageRemove={() => {
                            setImage(null);
                            setRes(null);
                          }}
                          onUploadError={onError}
                          onUploadSuccess={onSuccess}
                          onUploadProgress={onUploadProgress}
                          onUploadStart={onUploadStart}
                        />
                    </Row>
                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">
                        Car Brand
                      </label>
                      <div className="col-md-6">
                        <Select
                          placeholder="Select Car Brand"
                          options={carData}
                          name="selectCarBrand"
                          onBlur={formik.handleBlur}
                          onChange={selectedOption =>
                            formik.setFieldValue(
                              "selectCarBrand",
                              selectedOption.value
                            )
                          }
                          value={carData?.find(
                            option =>
                              option.value === formik.values.selectCarBrand
                          )}
                        />
                        {formik.touched.selectCarBrand &&
                        formik.errors.selectCarBrand ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.selectCarBrand}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">
                        Car Type
                      </label>
                      <div className="col-md-6">
                        <Select
                          placeholder="Select Car Type"
                          options={carType}
                          name="selectCarType"
                          onBlur={formik.handleBlur}
                          onChange={selectedOption =>
                            formik.setFieldValue(
                              "selectCarType",
                              selectedOption.value
                            )
                          }
                          value={carType?.find(
                            option =>
                              option.value === formik.values.selectCarType
                          )}
                        />
                        {formik.touched.selectCarType &&
                        formik.errors.selectCarType ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.selectCarType}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">
                        Transmission Type
                      </label>
                      <div className="col-md-6">
                        <Select
                          isMulti
                          placeholder="Select Transmission Type"
                          options={transmissionData}
                          styles={{
                            option: base => ({
                              ...base,
                              backgroundColor: "white",
                              height: "100%",
                            }),
                          }}
                          name="selectTransmissionValue"
                          onBlur={formik.handleBlur}
                          onChange={selectedOptions =>
                            formik.setFieldValue(
                              "selectTransmissionValue",
                              selectedOptions
                            )
                          }
                          value={formik.values.selectTransmissionValue}
                        />
                        {formik.touched.selectTransmissionValue &&
                        formik.errors.selectTransmissionValue ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.selectTransmissionValue}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">
                        Fuel Type
                      </label>
                      <div className="col-md-6">
                        <Select
                          isMulti
                          placeholder="Select FuelType"
                          options={fuelData}
                          styles={{
                            option: base => ({
                              ...base,
                              backgroundColor: "white",
                              height: "100%",
                            }),
                          }}
                          name="selectFuelType"
                          onBlur={formik.handleBlur}
                          onChange={selectedOptions =>
                            formik.setFieldValue(
                              "selectFuelType",
                              selectedOptions
                            )
                          }
                          value={formik.values.selectFuelType}
                        />
                        {formik.touched.selectFuelType &&
                        formik.errors.selectFuelType ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.selectFuelType}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Order No
                      </label>
                      <div className="col-md-6">
                        <Input
                          className="form-control form-control-lg"
                          type="number"
                          placeholder="Order No"
                          name="selectOrderNo"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.selectOrderNo}
                        />
                      </div>
                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary">
                        { response ? 'Update' :'Submit'}
                      </Button>{" "}
                      <Button
                        type="reset"
                        color="secondary"
                        onClick={() => navigate("/Car")}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormElements
