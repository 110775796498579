import React, { useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Button,
  Form,
  Input
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { createServiceType } from "common/Services/dbServices";
import { Link, useNavigate } from "react-router-dom"
import { useFormik } from 'formik';


const FormElements = () => {
  const navigate = useNavigate()


  const initialValues = {
    serviceTypeEn: '',
    serviceTypeAr: '',
    serviceTypeDescriptionEn: '',
    serviceTypeDescriptionAr: '',
    serviceTypeOrder:0,
  }
  const onSubmit = async(values)=>{
    //  console.log('values=',values)
    try{
    const createSerType =await createServiceType ({values})
    console.log('createser type',createSerType)
    alert("Added Successfully")
    navigate("/service-types")
    }
    catch(error){
      alert("Failed to Add")
    }
  }

  const validate = values => {
    let errors = {}
    if (!values.serviceTypeEn) {
      errors.serviceTypeEn = 'Service type en required'
    }
    if (!values.serviceTypeAr) {
      errors.serviceTypeAr = 'Service type ar required'
    }
    if (!values.serviceTypeDescriptionEn) {
      errors.serviceTypeDescriptionEn = 'Service type description required'
    }
    if (!values.serviceTypeDescriptionAr) {
      errors.serviceTypeDescriptionAr = 'Service type description required'
    }
    return errors

  }


  const formik =
    useFormik({
      initialValues,
      onSubmit,
      validate
    })

  document.title = "Add New Service | Sayartak"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="Sayartak"
            title="Services"
            breadcrumbItem="Add New Service Type"
            url="/service-types"
          />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  {/* <CardTitle className="h4">Details</CardTitle> */}
                  <Form
                    style={{ marginTop: 10 }}
                    className="row g-3 needs-validation"
                    onSubmit={formik.handleSubmit}
                  >
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                      Service Type En
                      </label>
                      <div className="col-md-6">
                        <Input
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Service Type En"
                          name='serviceTypeEn'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.serviceTypeEn}
                        />
                         {formik.touched.serviceTypeEn && formik.errors.serviceTypeEn ? (
                          <div style={{ color: 'red' }}>
                            {formik.errors.serviceTypeEn}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                      Service Type Ar
                      </label>
                      <div className="col-md-6">
                        <Input
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Service Type Ar"
                          name='serviceTypeAr'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.serviceTypeAr}
                        />
                          {formik.touched.serviceTypeAr && formik.errors.serviceTypeAr ? (
                          <div style={{ color: 'red' }}>
                            {formik.errors.serviceTypeAr}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Description
                      </label>
                      <div className="col-md-6">
                        <Input
                          name="serviceTypeDescriptionEn"
                          className="form-control form-control-lg"
                          type="textarea"
                          placeholder="Add Description En"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.serviceTypeDescriptionEn}
                        />
                          {formik.touched.serviceTypeDescriptionEn && formik.errors.serviceTypeDescriptionEn ? (
                          <div style={{ color: 'red' }}>
                            {formik.errors.serviceTypeDescriptionEn}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Description Ar
                      </label>
                      <div className="col-md-6">
                        <Input
                          name="serviceTypeDescriptionAr"
                          className="form-control form-control-lg"
                          type="textarea"
                          placeholder="Add Description Ar"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.serviceTypeDescriptionAr}
                        />
                          {formik.touched.serviceTypeDescriptionAr && formik.errors.serviceTypeDescriptionAr ? (
                          <div style={{ color: 'red' }}>
                            {formik.errors.serviceTypeDescriptionAr}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                   
                    <Row className="mb-3">
                      <label className="col-sm-2 col-form-label">
                        Order No
                      </label>
                      <div className="col-md-6">
                        <Input
                          className="form-control form-control-lg"
                          type="number"
                          placeholder="Order No"
                          name="serviceTypeOrder"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.serviceTypeOrder}
                        />
                      </div>
                    </Row>
                   
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary" >
                        Submit
                      </Button>{" "}
                      <Button type="reset" color="secondary" onClick={() => navigate("/service-types")}>
                        Cancel
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormElements
