import React, { useState, useEffect } from "react"
import Select from "react-select"
import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Button,
  Form,
  Input,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { createCity } from "common/Services/dbServices"
import { Link, useNavigate } from "react-router-dom"
import { useFormik } from 'formik';

// import ImageKit from "imagekit";
const FormElements = () => {
  const navigate = useNavigate()

  const initialValues = {
    addCityEn: '',
    addCityAr: '',
  }

  const onSubmit = async (values) => {
    console.log('values=',values)
    try {
      const createCarRes = await createCity({
        values
      })
      console.log("submit::", createCarRes)
      alert("Item Added Successfully")
      navigate("/city")
    } catch (error) {
      alert("Failed to Add")
      navigate("/car")
    }
  }

  const validate = values => {
    let errors = {}

    if (!values.addCityEn) {
      errors.addCityEn = 'City name en required'
    }
    if (!values.addCityAr) {
      errors.addCityAr = 'City name ar required'
    }
    
    return errors

  }


  const formik =
    useFormik({
      initialValues,
      onSubmit,
      validate
    })


  document.title = "Add New City | Sayartak"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="Sayartak"
            title="City"
            breadcrumbItem="Add New City"
            url="/City"
          />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  {/* <CardTitle className="h4">Details</CardTitle> */}
                  <Form
                    style={{ marginTop: 10 }}
                    className="row g-3 needs-validation"
                    onSubmit={formik.handleSubmit}
                  >
                    <Row className="mb-4">
                      <label className="col-sm-1 col-form-label">
                        City Name En
                      </label>
                      <div className="col-md-6">
                        <Input
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="City Name En"
                          name='addCityEn'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.addCityEn}
                        />
                         {formik.touched.addCityEn && formik.errors.addCityEn ? (
                          <div style={{ color: 'red' }}>
                            {formik.errors.addCityEn}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    <Row className="mb-4">
                      <label className="col-sm-1 col-form-label">
                        City Name Ar
                      </label>
                      <div className="col-md-6">
                        <Input
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="City Name Ar"
                          name='addCityAr'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.addCityAr}
                        />
                         {formik.touched.addCityAr && formik.errors.addCityAr ? (
                          <div style={{ color: 'red' }}>
                            {formik.errors.addCityAr}
                          </div>
                        ) : null}
                      </div>
                    </Row>
                    

                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary" >
                        Submit
                      </Button>{" "}
                      <Button
                        type="reset"
                        color="secondary"
                        onClick={() => navigate("/Car")}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormElements
